import { CustomLogger, EnumerationTypeService, ViewModelBase } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject, BindingEngine, computedFrom, Disposable, observable } from 'aurelia-framework';
import * as Constants from '../constants';
import { DataFormat } from 'select2';
import { Endpoints } from 'endpoints';

@autoinject
export class SendMessage extends ViewModelBase {
  public ressourceName: string = Constants.EntityTypeNames.Message;
  private modulaMessageTypeService: EnumerationTypeService;
  private myResponse: ResponseDto;
  @observable private messageType: DataFormat;

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private bindingEngine: BindingEngine) {
    super(router, logger);
    this.modulaMessageTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ModulaMessageType);
  }

  @computedFrom('messageType')
  private get isMoveTrayToBay(): boolean {
    return this.messageType.id == Constants.MessageType.MoveTrayToBay;
  }
  @computedFrom('messageType')
  private get isMoveTrayForInput(): boolean {
    return this.messageType.id == Constants.MessageType.MoveTrayForInput;
  }
  @computedFrom('messageType')
  private get isMoveTrayForPicking(): boolean {
    return this.messageType.id == Constants.MessageType.MoveTrayForPicking;
  }
  @computedFrom('messageType')
  private get isMoveTrayForInventory(): boolean {
    return this.messageType.id == Constants.MessageType.MoveTrayForInventory;
  }

  public async activate() {
    let defaultMsgType = await this.modulaMessageTypeService.getDefault();
    this.messageType = { id: defaultMsgType.id, text: defaultMsgType.denomination._translation };
    this.myResponse = new ResponseDto();
    this.myResponse.returnStatus = "Done";
    this.myResponse.bins = new Array();
  }

  @computedFrom("myResponse", '_langWatcher')
  get ribbonHeaderText(): string {
    return this.i18n.tr('menu.sendMessage');
  }

  private messageTypeChanged(newValue: DataFormat, oldValue: DataFormat) {
    if (newValue != null && newValue.id != null) {
      if (oldValue != null && newValue.id != oldValue.id) {
        this.myResponse.realInputQuantity = null;
        this.myResponse.realPickedQuantity = null;
        this.myResponse.countedQuantity = null;
        this.myResponse.bins.splice(0);
      }
    }
  }

  //To desactivate the warning when leaving the page and we leave it.
  public async canDeactivate() {
    return true;
  }

  public async postToApi() {
    if (this.messageType.id === null || (parseInt(this.messageType.id.toString())) <= 0) {
      this.logError(this.i18n.tr('message.errorSelectMessageType'), null);
    } else {
      switch (parseInt(this.messageType.id.toString())) {
        case Constants.MessageType.MoveTrayForInventory:
          this.myResponse.messageType = ModulaMessageResponseType[ModulaMessageResponseType.MoveTrayForInventory];
          break;
        case Constants.MessageType.MoveTrayForInput:
          this.myResponse.messageType = ModulaMessageResponseType[ModulaMessageResponseType.MoveTrayForInput];
          break;
        case Constants.MessageType.MoveTrayForPicking:
          this.myResponse.messageType = ModulaMessageResponseType[ModulaMessageResponseType.MoveTrayForPicking];
          break;
        default:
          this.myResponse.messageType = ModulaMessageResponseType[ModulaMessageResponseType.MoveTrayToBay];
          break;
      }
      let postResult = await this.httpClient.post(Endpoints.Message.MessageSimulateReceiveEndPoint, JSON.stringify(this.myResponse));
      if (postResult.ok) {
        this.log(this.i18n.tr('messagesent.messagesent'), null, true);
      } else {
        if (postResult.status == 400) {
          var postResultError = await postResult.json();
          this.logError(postResultError.errors.messageId.join(', '), null, true);
        } else {
          this.logError(await postResult.text(), null, true);
        }
      }
    }
  }

  private addBin() {
    let newBin: BinDto = { binNumber: (this.myResponse.bins.length + 1).toString(), quantity: 1 };
    newBin.observer = this.bindingEngine.propertyObserver(newBin, "quantity").subscribe((newValue, oldValue) => {
      if (newValue != null && newValue != oldValue) {
        this.recalculateRealPickedQty();
      }
    });
    this.myResponse.bins.push(newBin);
    this.recalculateRealPickedQty();
  }

  private removeBin(bin: BinDto) {
    bin.observer.dispose();
    this.myResponse.bins.remove(bin);
    this.recalculateRealPickedQty();
  }

  private recalculateRealPickedQty() {
    this.myResponse.realPickedQuantity = this.myResponse.bins.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.quantity
    }, 0);
  }
}

class ResponseDto {
  messageId: string;
  returnStatus: string;
  messageType: string;
  realInputQuantity: number;
  realPickedQuantity: number;
  countedQuantity: number;
  bins: BinDto[];
}

export interface BinDto {
  binNumber: string;
  quantity: number;
  observer?: Disposable;
}

enum ModulaMessageResponseType {
  MoveTrayToBay,
  MoveTrayForInput,
  MoveTrayForPicking,
  MoveTrayForInventory
}
