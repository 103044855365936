export class Endpoints {
  public static StaticRackLocation = {
    ValueIsInRange: "/api/MobileScreens/StaticRackLocation/ValueIsInRange",
    SequenceIsUnique: "/api/MobileScreens/StaticRackLocation/SequenceIsUnique",
    GetLocationLevelForStaticDto: "/api/MobileScreens/StaticRackLocation/GetLocationLevelForStaticDto",
  }
  public static Replenishment = {
    CreateWorkorders: "/api/MobileScreens/workOrder/CreateAndLaunchReplenishmentWorkOrders"
  }
}
