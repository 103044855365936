import { autoinject } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";
import { Box } from "digiwall-lib";
import { Zeus } from "generated";

@autoinject
export class LocationForActionService {

  constructor(protected i18n: I18N, protected box: Box) {

  }

  public async canDeleteOrCancelArticleToAction(articleToActions: Array<Zeus.Web.Model.ArticleToAction>): Promise<boolean> {
    return true;
  }

  public extractDeletableLocationForInputPickings(articleToAction: Zeus.Web.Model.ArticleToAction): Zeus.Web.Model.LocationForInputPicking[] {
    let locs: Zeus.Web.Model.LocationForInputPicking[] = [];
    if (articleToAction?.locationForInputPickings?.length) {
      let nmLocs = articleToAction.locationForInputPickings.filter(lfi =>
        lfi.manuallyBlocked == false
        && lfi.msgHasBeenSent == false
        && lfi.isBlockedForProcessing == 0
      );
      if (nmLocs.length) locs = locs.concat(nmLocs);
    }
    return locs;
  }

  public reserveLocationsDisabled(articleToAction: Zeus.Web.Model.ArticleToAction): boolean {
    return false;
  }

  public reserveLocationsRowClass(locationForInputPicking: Zeus.Web.Model.LocationForInputPicking): string[] {
    return [];
  }
}
