import { Router } from 'aurelia-router';
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, ServiceBase, Various } from 'digiwall-lib';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import { DialogController } from 'aurelia-dialog';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export class ArticleVolumeConfig extends EntityDetailViewModelBase<Zeus.Web.Model.ArticleVolumeConfig> {
  public ressourceName: string = Constants.EntityTypeNames.ArticleVolumeConfig;

  public unitOfMeasureService: EnumerationTypeService;
  public storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  public articleService: ServiceBase<Zeus.Web.Model.Article>;

  public articleDisabled: boolean = false;
  public uomDisabled: boolean = false;

  @computedFrom('isCreationMode', '_langWatcher')
  get documentTitle(): any {
    if (this.isCreationMode) {
      return this.i18n.tr('articlevolumeconfig.addArticlevolumeconfig')
    }
  }

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>(Constants.EntityTypeNames.ArticleVolumeConfig));

    this.unitOfMeasureService = new EnumerationTypeService(Constants.EnumerationTypes.UnitOfMeasure);
    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ['volumeType'];
    this.articleService = new ServiceBase<Zeus.Web.Model.Article>(Constants.EntityTypeNames.Article);
  }

  public async activate(params) {
    await super.activate(params);
    const id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.quantityInVolume = 0;
      if (params.callback) {
        params.callback(this.entity);
        this.articleDisabled = true;
        this.uomDisabled = this.entity.unitOfMeasure !== null;
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'article', 'unitOfMeasure', 'storageVolume.volumeType');
      this.controller.addObject(this.entity);
      this.unitOfMeasureService.gridDataSource.queryParameters = { articleId: this.entity.articleId, category: Constants.EnumerationTypes.UnitOfMeasure, onlyActifs: true };
    }

    this.storageVolumeService.gridDataSource.customSelect2Predicates = () => Predicate.and(this.entity.article.articleVolumeConfigs
      .filter(avc => avc.storageVolumeId != null && avc.unitOfMeasureId == this.entity.unitOfMeasureId)
      .map(avc => new Predicate('id', FilterQueryOp.NotEquals, avc.storageVolumeId)));
  }

  public canDeactivate(): any {
    return Promise.resolve(true);
  }

  public async cancel() {
    super.innerCancel(false, false, true);
    this.dialogController.cancel();
  }

  public async close() {
    if (this.entity.storageVolumeId == null) {
      return this.logError(this.i18n.tr('articlevolumeconfig.errorStorageVolume'), null, true);
    }

    if (this.entity.quantityInVolume <= 0) {
      return this.logError(this.i18n.tr('articlevolumeconfig.errorQuantityZero'), null, true);
    }

    this.entity.entityAspect.setDetached();
    this.dialogController.ok(this.entity);
  }
}
