import { Disposable } from 'aurelia-binding';
import { I18N } from 'aurelia-i18n';
import { Box, CustomLogger, ServiceBase, ActionDialogBoxInputParameters, DialogBoxViewModel } from 'digiwall-lib';
import { autoinject, bindable, BindingEngine, bindingMode, computedFrom, Container, customElement, TaskQueue } from "aurelia-framework";
import { Zeus } from "generated";
import * as Constants from '../../constants';
import { ApiService } from 'external-src/api.service';
import moment from 'moment';
import { FilterQueryOp, Predicate, EntityState, EntityStateSymbol } from 'breeze-client';
import { LocationUtils } from '../../utils/utils-locations';
import { LocationForActionService } from 'external-src/utils/location-for-action-service';

@autoinject
@customElement('input-pick-reserve-locations')
export class InputPickReserveLocations {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) private articleToAction: Zeus.Web.Model.ArticleToAction;
  @bindable private readOnlyMode: boolean = false;

  private articleVolumeConfigs: Zeus.Web.Model.ArticleVolumeConfig[];

  private isLoaded = false;
  private tempLocationsForInputPickings: LocationForInputPickingEntry[] = [];

  private hasFreeSpaceForInputMissingVolume: boolean = false;
  private isFindingLocations = false;
  private isSelectingOtherLocation = false;
  private isReleasingLocations = false;
  private triggerQtyChange = 0;
  private hasChangeRequestedQty: boolean = false;

  private articleToActionService: ServiceBase<Zeus.Web.Model.ArticleToAction>;
  private locationForInputPickingService: ServiceBase<Zeus.Web.Model.LocationForInputPicking>;
  private articleVolumeConfigService: ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>;
  private articleLocationService: ServiceBase<Zeus.Web.Model.ArticleLocation>;

  private disposables: Disposable[] = [];

  @computedFrom('articleToAction.id')
  private get articleToInput(): Zeus.Web.Model.ArticleToInput {
    return this.articleToAction as Zeus.Web.Model.ArticleToInput;
  }
  @computedFrom('articleToAction.id')
  private get articleToPick(): Zeus.Web.Model.ArticleToPick {
    return this.articleToAction as Zeus.Web.Model.ArticleToPick;
  }

  @computedFrom(...['isFindingLocations', 'isSelectingOtherLocation', 'isReleasingLocations'])
  private get isBusy() {
    return this.isFindingLocations || this.isSelectingOtherLocation || this.isReleasingLocations;
  }

  @computedFrom('articleToAction.actionWorkOrder.workOrderTypeId')
  private get isOfTypeInput(): boolean {
    return this.articleToAction?.actionWorkOrder?.workOrderTypeId == Constants.WorkOrderType.Input;
  }

  @computedFrom(...['articleToAction.articleId', 'articleToAction.locationForInputPickings', 'triggerQtyChange', 'articleToAction.reallyActionQuantity'])
  private get inputPickQtyLocation() {
    let qty = 0;
    this.articleToAction?.locationForInputPickings.filter(lfi => lfi.msgHasBeenSent == false).forEach(lfi => {
      qty += lfi.requestedActionQuantity;
    });
    return qty;
  };

  @computedFrom(...['articleToAction.locationForInputPickings', 'triggerQtyChange', 'requestedActionQuantity'])
  private get remainingInputPickQty() {
    let qty = this.requestedActionQuantity - this.articleToAction?.reallyActionQuantity;

    this.articleToAction?.locationForInputPickings
      .filter(lfi => !lfi.msgHasBeenSent || lfi.reallyActionQuantity == null)
      .forEach(lfi => {
        qty -= lfi.requestedActionQuantity;
      });

    return qty;
  };

  @computedFrom(...['articleToAction.requestedActionQuantity', 'isOfTypeInput'])
  private get requestedActionQuantity(): number {
    let qty = this.articleToAction?.requestedActionQuantity;
    if (false == this.isOfTypeInput) {
      // Transform quantity to stockCountUOM
      let articleUOM = this.articleToAction?.article?.unitOfMeasures.find(x => x.unitOfMeasureId == this.articleToAction.unitOfMeasureId);
      if (articleUOM != null) {
        qty *= articleUOM.quantityInStockCountUOM;
      }
    }
    return qty;
  }

  @computedFrom("articleToAction.articleId")
  protected get showLocationTypeColumn(): boolean {
    return this.articleToAction?.articleStorageGroupForParameter?.storageLocationType1Id != null ||
      this.articleToAction?.articleStorageGroupForParameter?.storageLocationType2Id != null ||
      this.articleToAction?.articleStorageGroupForParameter?.storageLocationType3Id != null
  }

  private get reserveLocationsDisabled(): boolean {
    return this.locationForActionHelperService.reserveLocationsDisabled(this.articleToAction);
  }

  constructor(private apiService: ApiService, private taskQueue: TaskQueue, private i18n: I18N, private box: Box,
    private bindingEngine: BindingEngine, private logger: CustomLogger, private locationForActionHelperService: LocationForActionService) {
    this.articleToActionService = new ServiceBase<Zeus.Web.Model.ArticleToAction>(Constants.EntityTypeNames.ArticleToAction);
    this.locationForInputPickingService = new ServiceBase<Zeus.Web.Model.LocationForInputPicking>(Constants.EntityTypeNames.LocationForInputPicking);
    this.articleVolumeConfigService = new ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>(Constants.EntityTypeNames.ArticleVolumeConfig);
    this.articleLocationService = new ServiceBase<Zeus.Web.Model.ArticleLocation>(Constants.EntityTypeNames.ArticleLocation);
  }

  private async attached() {
    await this.articleToActionService.getEntityById(this.articleToAction.id,
      'limitedToStorageGroup', 'actionWorkOrder.workOrderPriority', 'article.articleLocations'
    );

    await this.fetchLocationForInputPickings();

    if (this.articleToAction.locationForInputPickings.length > 0) {
      if (this.isOfTypeInput) {
        await this.apiService.computeUsedAndFreeSpaceForInput(this.articleToInput);
      } else {
        await this.apiService.computePickableQuantity(this.articleToPick);
      }
    }

    await this.initialize();

    this.disposables.push(...[
      this.bindingEngine.propertyObserver(this.articleToAction, 'articleId')
        .subscribe(async () => await this.resetOnArticleToActionChange('articleId')),
      this.bindingEngine.propertyObserver(this.articleToAction, 'unitOfMeasureId')
        .subscribe(async () => await this.resetOnArticleToActionChange('unitOfMeasureId')),
      this.bindingEngine.propertyObserver(this.articleToAction, 'lotNumber')
        .subscribe(async () => await this.resetOnArticleToActionChange('lotNumber')),
      this.bindingEngine.propertyObserver(this.articleToAction, 'expirationDate')
        .subscribe(async () => await this.resetOnArticleToActionChange('expirationDate')),
      this.bindingEngine.collectionObserver(this.articleToAction.article.articleVolumeConfigs)
        .subscribe(() => {
          this.articleVolumeConfigs.splice(0);
          this.articleVolumeConfigs.push(...this.articleToAction.article.articleVolumeConfigs);
        })
    ]);
  }

  private isResetting: boolean = false;
  private async resetOnArticleToActionChange(updatedField: string) {
    // Avoid multiple loading at same time
    if (this.isResetting) {
      return;
    }
    this.isResetting = true;
    this.isLoaded = false;
    await this.unblockAllLocations();
    await this.initialize(updatedField == 'articleId' || updatedField == 'unitOfMeasureId');
    this.isResetting = false;
  }

  private async unblockAllLocations() {
    let lfips = this.articleToAction?.locationForInputPickings?.filter(lfip => lfip.msgHasBeenSent == false && lfip.manuallyBlocked);
    lfips.forEach(x => x.entityAspect?.setDeleted());
    await this.locationForInputPickingService.saveEntities(lfips, true);
    await this.clearLocations(true);
  }

  private async initialize(reloadArticleConfigs: boolean = true) {
    this.isLoaded = false;

    if (reloadArticleConfigs) {
      this.articleVolumeConfigs = await this.articleVolumeConfigService.getEntities(
        Predicate.and(
          new Predicate('articleId', FilterQueryOp.Equals, this.articleToAction.articleId),
          new Predicate('unitOfMeasureId', FilterQueryOp.Equals, this.articleToAction.unitOfMeasureId)
        ), ['storageVolume.volumeType']);
    }

    this.initTempLocations();
    this.checkFreeSpaceForInputMissingVolume();

    this.isLoaded = true;
  }

  private detached() {
    this.clearTemporaryLocations();
    this.disposables?.forEach(x => x.dispose());
  }

  public async verifyAssignedLocations(): Promise<boolean> {
    this.articleToAction.qtyNotInlocation = this.remainingInputPickQty;

    // If no quantity remaining, OK
    if (this.remainingInputPickQty == 0) {
      this.articleToAction.locatedAll = true;
      return true;
    }

    let lfips = this.articleToAction.locationForInputPickings?.filter(lfi => lfi.msgHasBeenSent == false);
    // If no locations assigned, OK
    if (lfips.length == 0) {
      return true;
    }

    if (this.reserveLocationsDisabled) {
      return true;
    }

    if (this.remainingInputPickQty > 0) {
      // Not enough locations assigned
      let options: ActionDialogBoxInputParameters[] = [
        { label: this.i18n.tr('common:general.cancel'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(false) },
        { label: this.i18n.tr('workorderlinetoprocess.ignore'), theme: 'warning', type: 'tool', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(true) },
        { label: this.i18n.tr('workorderlinetoprocess.autoAssign'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok('auto') },
      ];
      return this.box.showQuestion(
        this.i18n.tr('workorderlinetoprocess.notEnoughLocations.message'),
        this.i18n.tr('workorderlinetoprocess.notEnoughLocations.title'),
        options
      ).whenClosed(async result => {
        if (result.output != 'auto') {
          // true -> ignore and close, false -> cancel and close
          return result.output;
        }
        // Auto assign, then continue
        type LFIP = Zeus.Web.Model.LocationForInputPicking & { possibleIncrement: number };
        // Check if there are manually blocked locations that could take a higher quantity
        let manualLocs = lfips.filter(lfip => lfip.manuallyBlocked)
          .map((lfip: LFIP): LFIP => {
            lfip.possibleIncrement = (this.isOfTypeInput ? lfip.location.freeSpaceForInput : lfip.location.pickableQuantity) - lfip.requestedActionQuantity;
            return lfip;
          })
          .filter((lfip: LFIP) => lfip.possibleIncrement > 0)
          .sort((a: LFIP, b: LFIP) => b.possibleIncrement - a.possibleIncrement);

        for (let i = 0; i < manualLocs.length; ++i) {
          // Increment requested quantity
          manualLocs[i].requestedActionQuantity += Math.min(this.remainingInputPickQty, manualLocs[i].possibleIncrement);
          await this.locationForInputPickingService.saveEntity(manualLocs[i]);
          // Done
          if (this.remainingInputPickQty == 0) {
            return this.verifyAssignedLocations();
          }
        };

        // Not done, add auto locations
        await this.doFindLocation(true);
        return this.verifyAssignedLocations();
      });
    } else {
      // Too many locations assigned
      let options = [
        { label: this.i18n.tr('common:general.cancel'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.cancel() },
        { label: this.i18n.tr('workorderlinetoprocess.autoAdapt'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok() },
      ];
      return this.box.showQuestion(
        this.i18n.tr('workorderlinetoprocess.tooManyLocations.message'),
        this.i18n.tr('workorderlinetoprocess.tooManyLocations.title'),
        options
      ).whenClosed(async result => {
        if (result.wasCancelled) {
          return false;
        }
        // Auto adapt, then continue
        // Remove and (if needed) re-assign auto locations
        await this.doFindLocation(true);
        if (this.remainingInputPickQty >= 0) {
          // Stop here (> 0 means not enough location available in database)
          return this.verifyAssignedLocations();
        }
        // In this case, all auto locations are removed
        // Start removing manually blocked locations
        let manualLocs = lfips.filter(lfip => lfip.manuallyBlocked);
        // Remove manually blocked, starting from back
        for (let i = manualLocs.length - 1; i >= 0; --i) {
          if (this.remainingInputPickQty + manualLocs[i].requestedActionQuantity <= 0) {
            // Unblock location
            manualLocs[i].entityAspect.setDeleted();
          } else {
            // Reduce requested quantity
            manualLocs[i].requestedActionQuantity += this.remainingInputPickQty;
          }
          await this.locationForInputPickingService.saveEntity(manualLocs[i]);
          // Done
          if (this.remainingInputPickQty >= 0) {
            break;
          }
        };
        return this.verifyAssignedLocations();
      });
    }
  }

  private async fetchLocationForInputPickings() {
    return this.locationForInputPickingService.getEntities(null,
      ['location.storage.dynamicStorageType', "location.dedicatedToRotationLevel", "location.alternateLocationVolumes.storageVolume.volumeType"
        , "location.defaultStorageVolume.volumeType", "location.storageLocationType"],
      { articleToActionId: this.articleToAction.id }
    );
  }

  private initTempLocations() {
    this.tempLocationsForInputPickings.splice(0);
    this.taskQueue.queueTask(() => {
      this.tempLocationsForInputPickings.push(
        ...this.articleToAction.locationForInputPickings.filter(lfi => lfi.msgHasBeenSent == false)
          .map(lfip => LocationForInputPickingEntry.MakeFromEntity(lfip))
      );
      this.checkFreeSpaceForInputMissingVolume();
    });
  }

  private async clearLocations(silentAlertBlocked: boolean = false) {
    this.clearTemporaryLocations();
    await this.apiService.deleteArticleToActionsLocationForInputPickings([this.articleToAction], silentAlertBlocked);
    this.initTempLocations();
  }

  private clearTemporaryLocations() {
    this.tempLocationsForInputPickings.filter(lfip => lfip.isEntity);
    this.checkFreeSpaceForInputMissingVolume();
  }

  private preFindLocations(): boolean {
    if (this.isBusy || this.reserveLocationsDisabled) {
      return false;
    } else if ((this.articleToAction.requestedActionQuantity ?? 0) <= 0) {
      this.logger.LogError(this.i18n.tr('articletoinput.noQuantity'), null, null, true);
      return false;
    } else if (this.articleVolumeConfigs.length == 0) {
      this.logger.LogError(this.i18n.tr('articletoinput.noVolumeConfigs'), null, null, true);
      return false;
    }
    if (this.isOfTypeInput) {
      if (this.articleToAction.article.hasLotNumber && (this.articleToAction.lotNumber || '').length == 0) {
        this.logger.LogError(this.i18n.tr('articletoinput.noLotNumber'), null, null, true);
        return false;
      } else if (this.articleToAction.article.hasExpirationDate && this.articleToInput.expirationDate == null) {
        this.logger.LogError(this.i18n.tr('articletoinput.noExpirationDate'), null, null, true);
        return false;
      }
    }
    return true;
  }

  private waitForArticleToActionSaved(callback: () => void) {
    if (this.articleToAction.id <= 0) {
      this.taskQueue.queueTask(() => this.waitForArticleToActionSaved(callback));
      return;
    }
    callback();
  }

  private async findLocation(silentAlertBlocked: boolean = false) {
    if (false == this.preFindLocations()) return;
    this.isFindingLocations = true;
    this.waitForArticleToActionSaved(() => this.doFindLocation(silentAlertBlocked));
  }

  private async doFindLocation(silentAlertBlocked: boolean = false) {
    await this.clearLocations(silentAlertBlocked);
    await this.apiService.getLocationForInputPickings(this.articleToAction);
    if (this.articleToAction.locationForInputPickings.length > 0) {
      if (this.isOfTypeInput) {
        await this.apiService.computeUsedAndFreeSpaceForInputWithParams(this.articleToInput);
        this.checkFreeSpaceForInputMissingVolume();
      } else {
        await this.apiService.computePickableQuantity(this.articleToPick);
      }
    }

    this.initTempLocations();
    this.isFindingLocations = false;
    this.triggerQtyChange++;
  }

  private async selectOtherLocation(): Promise<void> {
    if (false == this.preFindLocations()) return;
    this.isSelectingOtherLocation = true;
    this.waitForArticleToActionSaved(() => this.doSelectOtherLocation());
  }

  private async doSelectOtherLocation(): Promise<void> {
    await this.clearLocations();

    if (this.articleToAction.allreadySearchForLocations == true) {
      this.articleToAction.allreadySearchForLocations = false;
      await this.articleToActionService.saveEntity(this.articleToAction, true);
    }

    let locations = await this.apiService.getCompatibleLocationForArticleToAction(this.articleToAction);
    if (locations != null) {
      if (locations.length > 0) {
        if (this.isOfTypeInput) {
          await this.apiService.computeUsedAndFreeSpaceForInputWithParamsAndLocations(this.articleToInput, locations);
        } else {
          await this.apiService.computePickableQuantity(this.articleToPick, locations);
        }
        if (this.articleToAction.article.hasExpirationDate || this.articleToAction.article.hasLotNumber) {
          // Fetch article locations in order to have lotNumber & expirationdate
          // Split into batches
          let promises: Promise<any>[] = [];
          const batchSize = 50;
          for (let index = 0; index < locations.length; index += batchSize) {
            promises.push(
              this.articleLocationService.getEntities(
                Predicate.or(locations.slice(index, index + batchSize).map(loc => new Predicate('locationId', FilterQueryOp.Equals, loc.id))),
                ['article']
              )
            );
          }
          await Promise.all(promises);
        }
      }

      this.tempLocationsForInputPickings.push(...locations.map(location => LocationForInputPickingEntry.MakeFromLocation(location)));
      this.checkFreeSpaceForInputMissingVolume();
      this.triggerQtyChange++;
    }

    this.isSelectingOtherLocation = false;
    return Promise.resolve();
  }

  public async clear() {
    let toSave = [];
    this.tempLocationsForInputPickings.forEach((lfip, index) => {
      if (lfip.isEntity) {
        // Unblock also deletes entity, so this works for manually blocked and automatic entries
        toSave.push(lfip.entity);
        this.unblock(lfip, index);
      }
    });
    await this.locationForInputPickingService.saveEntities(toSave, true);
    this.hasChangeRequestedQty = false;
  }

  private locationArticlesReferences(location: Zeus.Web.Model.Location) {
    if (location == null) {
      return '';
    }
    if (location.articleLocations?.length > 0) {
      return location.articleLocations.map(al => al.article.reference).join('<br>');
    }
    return this.getLocationForInputPickingsFromLocation(location)
      .filter(lfip => !lfip.msgHasBeenSent && lfip.article != null)
      .map(lfip => lfip.article.reference)
      .join('<br>');
  }

  private async checkFreeSpaceForInputMissingVolume(shouldRecalculateOnChange = false) {
    if (false == this.isOfTypeInput) {
      this.hasFreeSpaceForInputMissingVolume = false;
      return;
    }
    let currentValue = this.hasFreeSpaceForInputMissingVolume;
    this.hasFreeSpaceForInputMissingVolume = null != this.tempLocationsForInputPickings?.find(
      locationForInputPicking => null == this.getFreeSpaceForInput(locationForInputPicking)
    );
    if (shouldRecalculateOnChange && currentValue !== this.hasFreeSpaceForInputMissingVolume) {
      await this.apiService.computeUsedAndFreeSpaceForInputWithParams(this.articleToAction as Zeus.Web.Model.ArticleToInput);
      this.initTempLocations();
    }
  }

  private getFreeSpaceForInput(locationForInput: LocationForInputPickingEntry): number {
    if (locationForInput == null) {
      return null;
    }
    return locationForInput.location?.freeSpaceForInput;
  }

  public locationArticlesLotNumber(location: Zeus.Web.Model.Location): string {
    if (location == null) return '';
    if (location.articleLocations?.length > 0) {
      return location.articleLocations.filter(al => al.lotNumber != null).map(al => al.lotNumber).join('<br>');
    }
    return this.getLocationForInputPickingsFromLocation(location)
      .filter(lfip => !lfip.msgHasBeenSent && lfip.articleToAction != null && lfip.articleToAction.lotNumber != null)
      .map(lfip => lfip.articleToAction.lotNumber)
      .join('<br>');
  }

  public locationArticlesExpirationDate(location: Zeus.Web.Model.Location) {
    if (location == null) return '';
    if (location.articleLocations?.length > 0) {
      return location.articleLocations.filter(al => al.expirationDate != null).map(al => moment(al.expirationDate).format('DD/MM/YYYY')).join('<br>');
    }
    return this.getLocationForInputPickingsFromLocation(location)
      .filter(lfip => !lfip.msgHasBeenSent && lfip.articleToAction != null && (lfip.articleToAction as Zeus.Web.Model.ArticleToInput).expirationDate != null)
      .map(lfip => moment((lfip.articleToAction as Zeus.Web.Model.ArticleToInput).expirationDate)
        .format('DD/MM/YYYY')).join('<br>');
  }

  private getLocationForInputPickingsFromLocation(location: Zeus.Web.Model.Location): Zeus.Web.Model.LocationForInputPicking[] {
    return (location.locationForActions || []).filter((i: Zeus.Web.Model.LocationForInputPicking) => i.msgHasBeenSent != null) as Zeus.Web.Model.LocationForInputPicking[];
  }

  private async changeManuallyBlocked(locationForInputPicking: LocationForInputPickingEntry, index: number) {
    // If setting to TRUE but this is a temporary line and no remaining quantity, ignore.
    // If not temporary line, this means this is an automatic line
    if (locationForInputPicking.manuallyBlocked == false && !locationForInputPicking.isEntity && this.remainingInputPickQty <= 0) return;

    if (locationForInputPicking.manuallyBlocked == false) {
      // Set to true
      locationForInputPicking = this.manuallyBlock(locationForInputPicking, index);
    } else {
      // Set to false, delete line
      locationForInputPicking = this.unblock(locationForInputPicking, index);
    }
    await this.locationForInputPickingService.saveEntity(locationForInputPicking.entity, true);
    this.taskQueue.queueTask(() => this.triggerQtyChange++);
  }

  private manuallyBlock(locationForInputPicking: LocationForInputPickingEntry, index: number): LocationForInputPickingEntry {
    if (!locationForInputPicking.isEntity) {
      // Temporary line, make into an entity
      let asEntity = this.locationForInputPickingService.manager.createEntity(
        'LocationFor' + (this.isOfTypeInput ? 'Input' : 'Picking'), locationForInputPicking
      ) as Zeus.Web.Model.LocationForInputPicking;
      const alId = locationForInputPicking.location.articleLocations.length > 0 ? locationForInputPicking.location.articleLocations[0].id : null;
      Object.assign(asEntity, {
        articleToActionId: this.articleToAction.id,
        articleId: this.articleToAction.articleId,
        unitOfMeasureId: this.articleToAction.unitOfMeasureId,
        articleLocationId: alId
      });

      locationForInputPicking = LocationForInputPickingEntry.MakeFromEntity(asEntity);
      // Set quantity
      if (this.isOfTypeInput) {
        locationForInputPicking.entity.requestedActionQuantity = Math.min(locationForInputPicking.location.freeSpaceForInput, this.remainingInputPickQty);
      } else {
        locationForInputPicking.entity.requestedActionQuantity = Math.min(locationForInputPicking.location.pickableQuantity, this.remainingInputPickQty);
      }
    }
    locationForInputPicking.entity.manuallyBlocked = true;
    //Replace temporary line with entity (force update of line in case it was already an entity)
    this.tempLocationsForInputPickings.splice(index, 1, locationForInputPicking);
    return locationForInputPicking;
  }

  private unblock(locationForInputPicking: LocationForInputPickingEntry, index: number): LocationForInputPickingEntry {
    locationForInputPicking.entity.manuallyBlocked = false;
    //Replace entity with temporary line
    this.tempLocationsForInputPickings.splice(index, 1, LocationForInputPickingEntry.MakeFromLocation(locationForInputPicking.location));
    locationForInputPicking.entity.entityAspect.setDeleted();
    return locationForInputPicking;
  }

  private async changeRequestedActionQuantity(locationForInputPicking: LocationForInputPickingEntry) {
    if (this.reserveLocationsDisabled) {
      return;
    }
    let index = this.tempLocationsForInputPickings.findIndex(x => x == locationForInputPicking);
    if (locationForInputPicking.manuallyBlocked == false) {
      if (this.remainingInputPickQty == 0) {
        await this.clear();
        await this.changeManuallyBlocked(this.tempLocationsForInputPickings[index], index);
      } else {
        await this.changeManuallyBlocked(locationForInputPicking, index);
        this.hasChangeRequestedQty = true;
      }
    } else {
      await this.changeManuallyBlocked(locationForInputPicking, index);
    }
  }

  private getRowClass(locationForInputPicking: LocationForInputPickingEntry) {
    let classes: string[] = ['ui-cursor-pointer'];
    if (locationForInputPicking?.manuallyBlocked) {
      classes.push('ui-bg--warning-tint')
    }
    if (locationForInputPicking?.isEntity) {
      classes.push(...Container.instance.get(LocationForActionService).reserveLocationsRowClass(locationForInputPicking.entity));
    }
    return classes.join(' ');
  }
}

class LocationForInputPickingEntry {
  private _entity: Zeus.Web.Model.LocationForInputPicking;
  private _location: Zeus.Web.Model.Location | null;
  //private _requestedActionQuantity: number | null;
  private _manuallyBlocked: boolean = false;

  public static MakeFromEntity(entity: Zeus.Web.Model.LocationForInputPicking): LocationForInputPickingEntry {
    let entry = new LocationForInputPickingEntry();
    entry._entity = entity;
    return entry;
  }

  public static MakeFromLocation(location: Zeus.Web.Model.Location): LocationForInputPickingEntry {
    let entry = new LocationForInputPickingEntry();
    entry._location = location;
    return entry;
  }

  public get isEntity(): boolean {
    return this._entity != null;
  }

  public get entity() {
    return this._entity;
  }

  public get location() {
    return this._entity ? this._entity.location : this._location;
  }

  public get requestedActionQuantity() {
    return this._entity ? this._entity.requestedActionQuantity : null;
  }

  public get manuallyBlocked() {
    return this._entity ? this._entity.manuallyBlocked : this._manuallyBlocked;
  }
}
