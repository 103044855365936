import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { BindingEngine, TaskQueue, autoinject, computedFrom } from "aurelia-framework";
import { bindable, customElement } from "aurelia-templating";
import { CustomLogger, DialogBoxViewModel, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, IMenuItems, ServiceBase, Various } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from '../constants';
import { LocationDetailFromMask } from 'locations/location-detail-from-mask';
import { LocationDetailFromTemplate } from 'locations/location-detail-from-template';
import { StorageTableRepresentation } from './storage-table-representation';
import environment from './../environment';
import { ValidationRules } from 'aurelia-validation';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { MoveTrayToBay } from 'commands/move-tray-to-bay/move-tray-to-bay';
import { TrayTemplateDetail } from 'tray-templates/tray-template-detail';
import { ListViewModelActionHistoryFilter } from 'action-histories/action-history-list';
import { TrayPreviewDialog } from 'locations/tray-preview-dialog';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import { Endpoints } from 'endpoints';
import * as SLMConstants from 'app-modules/static-like-a-modula/constants';
import * as MSConstants from 'app-modules/mobile-screens/constants';

@autoinject
@customElement('storage-detail-view')
export class StorageDetailView extends EntityDetailViewModelBase<Zeus.Web.Model.Storage> {

  public ressourceName: string = Constants.EntityTypeNames.Storage;

  @bindable
  private storageId: number;
  @bindable
  private onCancel = () => { };
  @bindable
  private onSaved: (args: { storage: Zeus.Web.Model.Storage }) => Promise<void>;

  private additionalActions: IMenuItems[];
  private traysSorted = false;
  private showOpenStorageTableRepresentation = false;
  private nbTraysOnCreation: number;

  private storageTypeService: EnumerationTypeService;
  private trayContainerService: ServiceBase<Zeus.Web.Model.TrayContainer>;
  private bayService: ServiceBase<Zeus.Web.Model.Bay>;
  private storageLocationTypeService: EnumerationTypeService;
  private locationFormatService: ServiceBase<Zeus.Web.Model.LocationFormat>;
  private bayMessageTypeService: ServiceBase<Zeus.Web.Model.BayMessageType>;
  private messageTypeService: ServiceBase<Zeus.Web.Model.WOMessageType>;

  private trayExpanded: boolean = false;
  private bayExpanded: boolean = true;

  private storageGroupPredicate: Predicate;

  @computedFrom('editingMode', 'entity.name', '_langWatcher')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr('storage.addStorage');
    } else if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.name;
    }
  }

  //if the location is used by a storage and this storage is dynamic some fields must be disabled.
  //US3690
  @computedFrom('entity.nbLocations')
  private get isStorageLinkedToLocation(): boolean {
    return this.entity?.nbLocations > 0;
  }

  @computedFrom('entity.storageTypeId')
  private get isDynamicStorage(): boolean {
    return this.entity?.storageTypeId == Constants.StorageTypes.Dynamic || this.entity?.storageTypeId == SLMConstants.StorageTypes.StaticRack;
  }

  @computedFrom('entity.storageTypeId')
  private get isStaticRack(): boolean {
    return this.entity?.storageTypeId == SLMConstants.StorageTypes.StaticRack;
  }

  @computedFrom('entity.storageTypeId')
  private get isStatic(): boolean {
    return this.entity?.storageTypeId == MSConstants.StorageTypes.Static || this.entity?.storageTypeId == Constants.StorageTypes.Static;
  }

  @computedFrom('entity.id')
  private get isMultipleBayActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.MultipleBay);
  }

  @computedFrom('entity.id')
  private get isRestrictedTraysActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.RestrictedTrays);
  }

  @computedFrom('entity.id')
  private get isMirroringActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.Mirroring);
  }

  @computedFrom('entity.id')
  private get isStaticLikeAModulaActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.StaticLikeAModula);
  }

  @computedFrom('entity.id')
  private get isMobileScreensActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.MobileScreens);
  }

  @computedFrom('authService.currentUser.id')
  private get canCallTray(): boolean {
    return this.authService.checkAccess("tray", Constants.Permissions.Consult);
  }

  @computedFrom('entity.id')
  public get freePickingModuleIsActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.FreePicking);
  }

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private taskQueue: TaskQueue, private appModuleService: AppModuleService, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage));

    this.storageGroupPredicate = new Predicate('storageGroupTypeId', FilterQueryOp.Equals, Constants.StorageGroupTypes.Zone);

    this.storageTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageType);
    this.trayContainerService = new ServiceBase<Zeus.Web.Model.TrayContainer>(Constants.EntityTypeNames.TrayContainer);
    this.bayService = new ServiceBase<Zeus.Web.Model.Bay>(Constants.EntityTypeNames.Bay);
    this.storageLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageLocationType);
    this.locationFormatService = new ServiceBase<Zeus.Web.Model.LocationFormat>(Constants.EntityTypeNames.LocationFormat);
    this.locationFormatService.gridDataSource.queryParameters = {
      locationNotCreatedAutomatically: true
    };
    this.bayMessageTypeService = new ServiceBase<Zeus.Web.Model.BayMessageType>(Constants.EntityTypeNames.BayMessageType);
    this.messageTypeService = new ServiceBase<Zeus.Web.Model.WOMessageType>(Constants.EntityTypeNames.WOMessageType);
    this.messageTypeService.gridDataSource.expands = ['workOrderType', 'orderSubtype'];
    this.messageTypeService.gridDataSource.customSelect2Predicates = () => {
      let bayMsgTypeIds = new Array();
      this.entity.bays.forEach(bay => bayMsgTypeIds.push(...bay.bayMessageTypes.map(msgType => msgType.messageTypeId)));
      if (bayMsgTypeIds.length == 0) {
        return null;
      }
      return Predicate.and(bayMsgTypeIds.map(msgTypeId => new Predicate('id', FilterQueryOp.NotEquals, msgTypeId)));
    }

    if (environment.debug == true) {// local, dev, test, not prod
      this.showOpenStorageTableRepresentation = true;
    }

    this.additionalActions = [
      /* #9495 hide menu items (probably re-enabled later) 
      {
        label: this.i18n.tr('location.createLocationFromMask'),
        icon: 'digi-plus',
        handler: () => this.openLocationCreationFromMask()
      },*/
      {
        label: this.i18n.tr('location.createLocationFromTemplate'),
        icon: 'digi-plus',
        handler: () => this.openLocationCreationFromTemplate(),
        disabled: () => this.isStatic
      },
      /* #9495 hide menu items (probably re-enabled later) 
      {
        label: this.i18n.tr('location.removeLocationFromMask'),
        icon: 'digi-trash',
        handler: () => this.openLocationDeletionFromMask()
      },*/
      {
        label: this.i18n.tr('location.listLocation'),
        icon: 'ze-article-sorting-type',
        handler: () => this.listLocation()
      },
      {
        label: 'Locations table representation (beta)',
        icon: 'ze-location-coordinates',
        handler: () => this.openStorageTableRepresentation(),
        hidden: () => false == this.showOpenStorageTableRepresentation
      }
    ];
  }

  public async attached() {
    this.trayExpanded = false;
    this.bayExpanded = true;
    if (this.storageId == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      // #8892 Set StorageType Dynamic by default
      this.entity.storageType = await this.storageTypeService.getEntityById(Constants.StorageTypes.Dynamic);
      this.entity.nbBays = 0;
      await this.addBay();
    } else if (this.storageId > 0) {
      this.editingMode = EditingModeEnum.Update;
      await this.loadDetails();
    } else {
      throw `Incorrect id ${this.storageId}`;
    }
    this.controller.addObject(this.entity);
    this.activateValidation();
  }

  private async loadDetails(id?: number | null) {
    const finalId = id || this.storageId;
    let expands = []
    this.entity = await this.service.getEntityById(
      finalId,
      'storageType',
      'storageGroup.parentStorageGroup',
      'locationFormat',
      'trayContainers' + (this.isRestrictedTraysActive ? '.trayRestrictions.user' : ''),
      'dynamicStorageTechnicalSetting',
      'bays.bayMessageTypes.messageType.workOrderType',
      'bays.bayMessageTypes.messageType.orderSubtype',
      'storageLocationType'
    );

    //Sorting 'TrayContainer' (accordeon) order by TrayId
    if (!this.traysSorted) {
      this.entity.trayContainers.sort((a, b) => (a.trayId < b.trayId ? -1 : 1));
      this.traysSorted = true;
    }

    //Sorting 'Bay' (accordeon) order by TrayId
    this.entity.bays.sort((a, b) => (a.bayNumber < b.bayNumber ? -1 : 1));

    if (this.entity.nbBays > 1 && !this.isMultipleBayActive) {
      this.entity.nbBays = 1;
      let bay = this.entity.bays.find(x => x.bayNumber == 1) || this.entity.bays[0];
      this.entity.bays.splice(0);
      this.entity.bays.push(bay);
    }

    this.setTrayRestrictionTooltips();
    this.entity.bays.forEach(b => this.attachedBay(b));
    this.setCanHaveExtraHighTray();
  }

  private async initCustomRules() {
    ValidationRules.customRule(
      "trayWidthCmRequired",
      async (value, object) => {
        if (this.isDynamicStorage) {
          return (object as Zeus.Web.Model.Storage).trayWidthCm != null;
        }
        return true;
      },
      this.i18n.tr("storage.validationTrayWidth")
    );

    ValidationRules.customRule(
      "trayDepthCmRequired",
      async (value, object) => {
        if (this.isDynamicStorage) {
          return (object as Zeus.Web.Model.Storage).trayDepthCm != null;
        }
        return true;
      },
      this.i18n.tr("storage.validationTrayDepth")
    );

    ValidationRules.customRule(
      "heightTrayRequired",
      async (value, object) => {
        if (this.isDynamicStorage && !this.isCreationMode) {
          return (object as Zeus.Web.Model.Storage).heightTray != null;
        }
        return true;
      },
      this.i18n.tr("storage.validationTrayHeight")
    );

    ValidationRules.customRule(
      "validationLocationFormatRequired",
      async (value, object) => {
        const storage = object as Zeus.Web.Model.Storage;
        if (storage.storageTypeId != null && this.isStatic) {
          return storage.locationFormatId != null;
        }
        return true;
      },
      this.i18n.tr("storage.validationLocationFormat")
    );

    ValidationRules.customRule(
      "dynamicStorageIdRequired",
      async (value, object) => {
        if (this.isDynamicStorage) {
          return (object as Zeus.Web.Model.Storage).dynamicStorageId != null;
        }
        return true;
      },
      this.i18n.tr("storage.validationDynamicStorageId")
    );

    ValidationRules.customRule(
      "errorIdentifierExist",
      async (value, object) => {
        if (this.isDynamicStorage) {
          let predicate = new Predicate("dynamicStorageId", FilterQueryOp.Equals, value);
          if ((object as Zeus.Web.Model.Storage).id > 0) {
            predicate = predicate.and("id", FilterQueryOp.NotEquals, (object as Zeus.Web.Model.Storage).id);
          }
          return (await this.service.getCount(predicate)) == 0;
        }
        return true;
      },
      this.i18n.tr("storage.errorIdentifierExist")
    );
    ValidationRules.customRule(
      "uniqueNameInZone",
      async (value: string, object: Zeus.Web.Model.Storage) => {
        if (!(object.name && object.storageGroupId)) {
          return true;
        }
        let predicate = new Predicate("name", FilterQueryOp.Equals, value).and(
          "storageGroupId",
          FilterQueryOp.Equals,
          object.storageGroupId
        );
        if (object.id > 0) {
          predicate = predicate.and("id", FilterQueryOp.NotEquals, object.id);
        }
        return (await this.service.getCount(predicate)) == 0;
      },
      this.i18n.tr("storage.validationNameUniqueInZone")
    );
    ValidationRules.customRule(
      "validTrayContainers",
      (value: Array<Zeus.Web.Model.TrayContainer>) => {
        let trayContainersIds = value.map(tray => tray.trayId);
        return !trayContainersIds.some(
          trayId => trayContainersIds.indexOf(trayId) != trayContainersIds.lastIndexOf(trayId)
        );
      },
      this.i18n.tr("storage.multipleTrayId")
    );
    ValidationRules.customRule(
      "validTrayHeight",
      (value: Array<Zeus.Web.Model.TrayContainer>) => {
        return value.every(tray => tray.height >= 0);
      },
      this.i18n.tr("storage.validationTrayHeight")
    );
    ValidationRules.customRule(
      "validBayExitPosition",
      (value: Array<Zeus.Web.Model.Bay>) => {
        return value.every(bay => bay.nbExitPosition == 1 || bay.nbExitPosition == 2);
      },
      this.i18n.tr("storage.validationBayExitPosition")
    );
    ValidationRules.customRule(
      "bayMessageTypeMandatory",
      (value: Array<Zeus.Web.Model.Bay>) => {
        if (!this.isMultipleBayActive) {
          return true;
        }
        return value.every(bay => bay.bayMessageTypes?.length > 0);
      },
      this.i18n.tr("storage.bayMessageTypeMandatory")
    );
    ValidationRules.customRule(
      "minNbBays",
      (value: number) => {
        return value >= 1;
      },
      this.i18n.tr("storage.validationNbBays")
    );

    //add translation
    ValidationRules.customRule(
      "staticStorageIdRequired",
      async (value, object) => {
        if (this.isStatic) {
          return (object as Zeus.Web.Model.Storage).dynamicStorageId != null;
        }
        return true;
      },
      this.i18n.tr("storage.staticStorageIdRequired")
    );
    ValidationRules.customRule(
      "nameMaxLength",
      async (value, object) => {
        if (this.isStatic && (object as Zeus.Web.Model.Storage).name != null) {
          return (object as Zeus.Web.Model.Storage).name.length <= 3;
        }
        return true;
      },
      this.i18n.tr("storage.maxCharactersForStatic")
    );
  }

  private activateValidation() {
    if (this.entity) {
      this.initCustomRules();
    }

    ValidationRules
      .ensure("nbTrays")
      .required()
      .withMessage(this.i18n.tr("storage.validationNbTrays"))
      .min(0)

      .ensure("trayContainers")
      .satisfiesRule("validTrayContainers")
      .ensure("trayWidthCm")
      .satisfiesRule("trayWidthCmRequired")
      .ensure("trayDepthCm")
      .satisfiesRule("trayDepthCmRequired")
      .ensure("heightTray")
      .satisfiesRule("heightTrayRequired")
      .ensure("locationFormatId")
      .satisfiesRule("validationLocationFormatRequired")
      .ensure("dynamicStorageId")
      .satisfiesRule("dynamicStorageIdRequired")
      .ensure("dynamicStorageId")
      .satisfiesRule("staticStorageIdRequired")
      .ensure("dynamicStorageId")
      .satisfiesRule("errorIdentifierExist")
      .ensure("nbBays")
      .satisfiesRule("minNbBays")
      .ensure("name")
      .satisfiesRule("uniqueNameInZone")
      .ensure("trayContainers")
      .satisfiesRule("validTrayHeight")
      .ensure("bays")
      .satisfiesRule("validBayExitPosition")
      .ensure("bays")
      .satisfiesRule("bayMessageTypeMandatory")
      .ensure("name")
      .satisfiesRule("nameMaxLength")
      .on(this.entity);
  }

  //#region Deactivation

  public detached(): void {
    this.controller.removeObject(this.entity);
    this.entity = null;
  }

  protected doNavigateBack(): void {
    // Unselect tree
    this.onCancel();
  }

  //#endregion Deactivation

  //#region Saving

  protected async beforeSave(): Promise<boolean | void> {
    this.initCustomRules();
    this.nbTraysOnCreation = this.entity.nbTrays;

    // Force validation on bays
    let validationResult = await this.controller.validate();
    if (false == validationResult.valid) {
      validationResult.results.forEach(r => {
        this.logError(r.message, r, (r.message != null));
      });
      return false;
    } else {
      return await super.beforeSave();
    }
  }

  protected async afterSave(): Promise<void> {
    if (this.isCreationMode) {
      await this.httpClient.get(Endpoints.Storage.GenerateTrayContainers + `?nbTrays=${this.nbTraysOnCreation}
        &storageId=${this.entity.id}&heightTray=${this.entity.heightTray}`);
      await this.httpClient.get(Endpoints.Storage.GenerateLocationLevels + `?storageId=${this.entity.id}`);
    }

    this.setTrayRestrictionTooltips();
  }

  public async save(silentSave?: boolean, byPassLocking?: boolean, navigateBack?: boolean): Promise<any> {
    await super.save(silentSave, byPassLocking, navigateBack);

    if (this.onSaved) {
      await this.onSaved({ storage: this.entity });
    }
  }

  protected changeRouteAfterCreation(): void {
    // Do nothing
  }

  //#endregion Saving

  private openActionHistory(): void {
    let filter = new ListViewModelActionHistoryFilter('storage', [this.entity.id]);
    this.navigateTo('action-history/all/' + filter.encode());
  }

  //#region "Locations"
  private listLocation() {
    this.router.navigate('locations/' + this.entity.id);
  }

  // private async openLocationCreation() {
  //   await this.box.showEditDialog(LocationDetail, -100, this.i18n.tr("location.locationFor") + this.entity.name, 
  //     (createdEntity: Zeus.Web.Model.Location) => {
  //         createdEntity.storageId = this.entity.id;
  //     }).whenClosed( async (result) => { 
  //     if(!result.wasCancelled) {
  //        await this.loadDetails(this.entity.id);
  //       }  
  //     });    
  // }

  private async openLocationCreationFromMask() {
    await this.box.showEditDialog(LocationDetailFromMask, this.entity.id, this.i18n.tr("location.locationFor") + this.entity.name,
      (vm: LocationDetailFromMask) => {
        vm.storage = this.entity;
      }, { canSave: false }
    ).whenClosed(async (result) => {
      await this.loadDetails(this.entity.id);
    });
  }

  private async openLocationCreationFromTemplate() {
    await this.dialogService.open({ viewModel: LocationDetailFromTemplate, model: { storage: this.entity } }).whenClosed(async (result) => {
      if (!result.wasCancelled && result.output as boolean) {
        await this.loadDetails(this.entity.id);
      }
    });
  }

  private async openLocationDeletionFromMask() {
    await this.box.showEditDialog(LocationDetailFromMask, this.entity.id, " ",
      (vm: LocationDetailFromMask) => {
        vm.isDeletion = true;
        vm.storage = this.entity;
      }, { canSave: false }).whenClosed(async (result) => {
        await this.loadDetails(this.entity.id);
      });
  }

  private async openStorageTableRepresentation() {
    await this.box.showEditDialog(StorageTableRepresentation, this.entity.id, this.i18n.tr("location.locationFor") + this.entity.name,
      (vm: StorageTableRepresentation) => {
        vm.storage = this.entity;
      });
  }

  private async createNowLocationFormat() {
    await this.box.showEditDialog(TrayTemplateDetail, -100, this.i18n.tr('locationformat.addLocationFormat'), (createdEntity: any) => {
      (createdEntity).type = Constants.EntityTypeNames.LocationFormat;
    }).whenClosed(async (result) => {
      if (!result.wasCancelled)
        this.entity.locationFormat = result.output;
    });
  }

  //#endregion "Locations"

  //#region Trays and bays
  private canHaveExtraHighTray: boolean = false;

  private async selectTrayToBay() {
    await this.dialogService.open({ viewModel: MoveTrayToBay, model: { storage: this.entity }, lock: false });
  }

  private async addTray() {
    let newTrayContainer: Zeus.Web.Model.TrayContainer = await this.trayContainerService.createEntity({
      storageId: this.entity.id,
      trayId: this.entity.trayContainers != null && this.entity.trayContainers.length > 0 ? this.entity.trayContainers[this.entity.trayContainers.length - 1].trayId + 1 : 1
    });
    this.entity.trayContainers.push(newTrayContainer);
    this.entity.nbTrays++;
  }

  private async removeTray(tray: Zeus.Web.Model.TrayContainer) {
    let deleted = true;
    if (tray.entityAspect.entityState.isAdded()) {
      tray.entityAspect.setDetached();
    }
    else {
      await this.trayContainerService.getEntities(new Predicate("id", FilterQueryOp.Equals, tray.id), ['locations.articleLocations']);

      if (tray.locations.some(loc => loc.articleLocations?.length > 0)) {
        // Can't delete a tray that has linked article locations
        await this.box.showError(this.i18n.tr("traycontainer.cantDeleteArticlesTray"), this.i18n.tr("menu.deletion_impossible"))
        return;
      }

      let avoidConfirmation = false;
      if (tray.locations?.length > 0) {
        avoidConfirmation = true;
        // Ask user if he's sure to delete locations linked to the tray
        let cancelDelete = await this.box.showQuestion(this.i18n.tr("traycontainer.checkDeleteLocations"), this.i18n.tr("menu.del-filter-set-title"),
          [
            { label: this.i18n.tr('general.no'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.cancel() },
            { label: this.i18n.tr('general.yes'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok() }
          ]).whenClosed(result => result.wasCancelled);

        if (cancelDelete) {
          return;
        }
      }

      deleted = await this.trayContainerService.deleteEntities([tray], true, null, avoidConfirmation);
    }

    if (deleted) {
      this.entity.nbTrays--;
    }
  }

  private setTrayRestrictionTooltips() {
    if (this.isRestrictedTraysActive) {
      this.entity?.trayContainers?.forEach(tray => {
        let tooltip: string;
        if (tray.trayRestrictions?.length > 0) {
          tooltip = [this.i18n.tr('traycontainer.restrictedTo'), ...tray.trayRestrictions.map(x => x.user.fullName)].join('<br />');
        } else if (tray.isRestricted) {
          tooltip = this.i18n.tr('traycontainer.restrictedNoUsers')
        } else {
          tooltip = this.i18n.tr('traycontainer.unrestricted')
        }
        (tray as any)._tooltip = tooltip;
      })
    }
  }

  private async checkTrayRestrictions(tray: Zeus.Web.Model.TrayContainer) {
    if (!tray.isRestricted && tray.trayRestrictions.length > 0) {
      await this.box.showError(this.i18n.tr("traycontainer.trayWithRestrictions"), this.i18n.tr("general.errorTitle"))
      tray.isRestricted = true;
    }
  }

  private async openTrayPreviewDialog(tray: Zeus.Web.Model.TrayContainer) {
    if (tray) {
      await this.dialogService.open({
        viewModel: TrayPreviewDialog, model: { trayId: tray.id }
      });
    }
  }

  private navigateToDevice(bayId: number) {
    let url = "/bay-devices/" + this.entity.id + "/" + bayId;
    this.navigateTo(url);
  }

  private async addBay() {
    let newBay: Zeus.Web.Model.Bay = await this.generateBay();
    this.entity.bays.push(newBay);
    this.entity.nbBays++;
    if (this.entity.nbBays == 1) {
      await this.setBayMessageTypesForOneBay(newBay);
    }
    else if (this.entity.nbBays == 2) {
      let bayMessageTypes = [...this.entity.bays[0].bayMessageTypes];
      for (const bayMessageType of bayMessageTypes) {
        if (bayMessageType.entityAspect.entityState.isAdded()) {
          bayMessageType.entityAspect.setDetached();
        } else {
          bayMessageType.entityAspect.setDeleted();
        }
      }
      this.entity.bays[0].bayMessageTypes.splice(0);
    }
    this.attachedBay(newBay);
  }

  private attachedBay(bay: Zeus.Web.Model.Bay) {
    this.disposables.push(
      this.bindingEngine.propertyObserver(bay, "nbExitPosition").subscribe((newVal, oldVal) => {
        this.setCanHaveExtraHighTray();
      })
    );
  }

  private async removeBay(bay: Zeus.Web.Model.Bay) {
    if (this.entity.bays?.length <= 1) {
      return;
    }

    if (bay.entityAspect.entityState.isAdded()) {
      bay.entityAspect.setDetached();
    } else {
      await this.bayService.deleteEntities([bay], false);
    }

    if (this.entity.bays.length == 1) {
      await this.setBayMessageTypesForOneBay(this.entity.bays[0]);
    }
    this.entity.nbBays = this.entity.bays.length;
  }

  private async setBayMessageTypesForOneBay(bay: Zeus.Web.Model.Bay) {
    let messageTypes = await this.messageTypeService.getAllEntities(...this.messageTypeService.gridDataSource.expands);
    for (const msgType of messageTypes.filter(x => !bay.bayMessageTypes.some(y => y.messageTypeId == x.id))) {
      bay.bayMessageTypes.push(await this.bayMessageTypeService.createEntity({
        bay: bay,
        messageType: msgType
      }));
    }
  }

  private async generateBay(): Promise<Zeus.Web.Model.Bay> {
    return await this.bayService.createEntity({
      storage: this.entity,
      bayNumber: this.entity.bays?.length > 0 ? this.entity.bays[this.entity.bays.length - 1].bayNumber + 1 : 1,
      nbDevices: 0,
      nbExitPosition: 1
    });
  }

  private setCanHaveExtraHighTray() {
    this.canHaveExtraHighTray = this.entity.bays.some(x => x.nbExitPosition == 2);
  }
  //#endregion Trays and bays
}
