export class Endpoints {
  public static AppModule = {
    AppModulesActiveModules: "/api/AppModule/ActiveModules",
    AppModulesIsDevelopment: "/api/AppModule/IsDevelopment",
    AppModulesSetDevelopmentActiveModules: "/api/AppModule/SetDevelopmentActiveModules",
    AppModulesClearDevelopmentActiveModules: "/api/AppModule/ClearDevelopmentActiveModules",
  }

  public static Message = {
    MessageSimulateReceiveEndPoint: "/message/simulateReceive",
  }

  public static Utils = {
    ResetAppData: "/api/Utils/ResetAppData",
    GetUsersNotification: "/api/Utils/GetUsersNotification",
    GetOnlyExistingZone: "/api/Utils/GetOnlyExistingZone",
  }

  public static ImportXlsx = {
    ImportArticleXLS: "/api/ImportXlsx/ImportArticleXLS",
    UpdateArticleXLS: "/api/ImportXlsx/UpdateArticleXLS",
    ImportWorkOrderInputXLS: "/api/ImportXlsx/ImportWorkOrderInputXLS",
    ImportWorkOrderInventoryXLS: "/api/ImportXlsx/ImportWorkOrderInventoryXLS",
    ImportWorkOrderPickingXLS: "/api/ImportXlsx/ImportWorkOrderPickingXLS",
    ImportCostCenterXLS: "/api/ImportXlsx/ImportCostCenterXLS",
    ImportProjectXLS: "/api/ImportXlsx/ImportProjectXLS",
    ImportSupplierXLS: "/api/ImportXlsx/ImportSupplierXLS",
    ImportStaticRackLocationXLS: "/api/ImportXlsx/ImportStaticRackLocationXLS",

    GetArticleXLSPattern: "/api/ImportXlsx/GetArticleXLSPattern",
    GetWorkOrderInputXlsPattern: "/api/ImportXlsx/GetWorkOrderInputXlsPattern",
    GetWorkOrderInventoryXlsPattern: "/api/ImportXlsx/GetWorkOrderInventoryXlsPattern",
    GetWorkOrderPickingXLSPattern: "/api/ImportXlsx/GetWorkOrderPickingXLSPattern",
    GetCostCenterXLSPattern: "/api/ImportXlsx/GetCostCenterXlsPattern",
    GetProjectXLSPattern: "/api/ImportXlsx/GetProjectXLSPattern",
    GetSupplierXLSPattern: "/api/ImportXlsx/GetSupplierXLSPattern",
    GetStaticRackLocationXLSPattern: "/api/ImportXlsx/GetStaticRackLocationXLSPattern",
  }

  public static WorkOrder = {
    GetSharedHierarchy: "/api/WorkOrder/GetSharedHierarchy",
    UpdateLocationForInventory: "/api/WorkOrder/UpdateLocationForInventory",
    GetArticleLocationsForInventory: "/api/WorkOrder/GetArticleLocationsForInventory",
    ComputeUsedAndFreeSpaceForInput: "/api/WorkOrder/ComputeUsedAndFreeSpaceForInput",
    GetLocationForInputPicking: "/api/WorkOrder/GetLocationForInputPicking",
    GetLocationForInputPickingFromList: "/api/WorkOrder/GetLocationForInputPickingFromList",
    ComputePickableQuantity: "/api/WorkOrder/ComputePickableQuantity",
    CloseWorkOrder: "/api/WorkOrder/CloseWorkOrder",
    UnloadWorkOrders: "/api/WorkOrder/UnloadWorkOrders",
    GetPickableStockForArticle: "/api/WorkOrder/GetPickableStockForArticle",
    GetPickableStockForArticles: "/api/WorkOrder/GetPickableStockForArticles",
    GetShortedActionQuantityForArticleToActions: "/api/WorkOrder/GetShortedActionQuantityForArticleToActions",
    BlockLocationsForProcessing: "/api/WorkOrder/BlockLocationsForProcessing",
  }

  public static BatchProcess = {
    VerifyInventoryWOArticleLocations: "/api/BatchProcess/VerifyInventoryWOArticleLocations",
    LaunchBatchProcess: "/api/BatchProcess/LaunchBatchProcess",
    cancelBatchProcess: "/api/BatchProcess/cancelBatchProcess",
    CancelSentMessage: "/api/BatchProcess/CancelSentMessage",
    LaunchWorkOrder: "/api/BatchProcess/LaunchWorkOrder",
    SendArticleToActions: "/api/BatchProcess/SendArticleToActions",
    SendInventoryLines: "/api/BatchProcess/SendInventoryLines",
    MoveTrayToBay: "/api/BatchProcess/MoveTrayToBay"
  }

  public static Location = {
    BulkUpdateLocations: "/api/location/bulkUpdate",
    GetLocationsToUpdate: "/api/location/getLocationsToUpdate",
    CreateParcOfLocations: "/api/location/CreateParcOfLocations",
    UpdateLocationDimensions: "/api/location/UpdateLocationDimensions",

  }

  public static Article = {
    GetArticleStorageGroups: "/api/Article/GetArticleStorageGroups",
  }

  public static StorageGroup = {
    IsStorageGroupAllowed: "/api/StorageGroup/IsStorageGroupAllowed",
    GetWarehousesFromSG: "/api/StorageGroup/GetWarehousesFromSG",
    IsOnUserSite: "/api/StorageGroup/IsOnUserSite",
    GetStorageGroupSite: "/api/StorageGroup/GetStorageGroupSite",
  }

  public static Storage = {
    GenerateTrayContainers: "/api/Storage/GenerateTrayContainers",
    GenerateLocationLevels: "/api/Storage/GenerateLocationLevels",
  }

  public static Log = {
    LogFrontendError: "/api/Log/LogFrontendError",
  }

  public static Reception = {
    GetBarcodeByLocationToInputPerArticleToInput: "/api/Reception/GetBarcodeByLocationToInputPerArticleToInput"
  }

}
