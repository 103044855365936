import { Predicate, FilterQueryOp } from 'breeze-client';
import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import { BayDeviceModal } from './bay-device-modal';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';

@autoinject
export class BayDeviceDetail extends EntityDetailViewModelBase<Zeus.Web.Model.Bay> {
  public ressourceName: string = Constants.EntityTypeNames.BayDevice;
  private bayDeviceService: ServiceBase<Zeus.Web.Model.BayDevice>;

  private storage: Zeus.Web.Model.Storage;

  constructor(
    router: Router, logger: CustomLogger, private appModuleService: AppModuleService) {
    super(router, logger);
    this.bayDeviceService = new ServiceBase<Zeus.Web.Model.BayDevice>(Constants.EntityTypeNames.BayDevice);

    super.initialize(new ServiceBase<Zeus.Web.Model.Bay>(Constants.EntityTypeNames.Bay));
  }

  public async activate(params) {
    const bayNumber = parseInt(params.param1);
    const storageId = parseInt(params.param2);

    this.entity = await this.service.firstEntity(
      new Predicate('bayNumber', FilterQueryOp.Equals, bayNumber).and(new Predicate('storageId', FilterQueryOp.Equals, storageId)),
      ['bayDevices', 'bayDevices.deviceType', 'storage']
    );
  }

  @computedFrom('entity.id')
  private get isMultipleBayAndMirroringActive() {
    return this.appModuleService.isActive(AppModuleEnum.MultipleBay)
      && this.appModuleService.isActive(AppModuleEnum.Mirroring);
  }

  public async addDevice() {
    await this.box.showEditDialog(BayDeviceModal, -100, this.i18n.tr("baydevice.device"),
      (createdEntity: Zeus.Web.Model.BayDevice) => {
        createdEntity.bayId = this.entity.id;
      }, { icon: 'ze-broadcast-tower', size: 'md' }).whenClosed((result) => {
        if (!result.wasCancelled) {
          this.entity.bayDevices.push(result.output);
        }
      });
  }

  public async editBayDevice(articleStorageGroup: Zeus.Web.Model.ArticleStorageGroup) {
    await this.box.showEditDialog(BayDeviceModal, articleStorageGroup.id, this.i18n.tr("baydevice.device"),
      { icon: 'ze-broadcast-tower', size: 'md' });
  }

  public async removeBayDevice(bayDevice: Zeus.Web.Model.BayDevice) {
    await this.bayDeviceService.deleteEntities([bayDevice], true);
  }

  @computedFrom('editingMode', '_langWatcher')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("baydevice.locationformat");
    }
    if (this.editingMode === EditingModeEnum.Update) {
      return "TOTO";
    }
  }

} 
