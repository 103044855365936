import { I18N } from "aurelia-i18n";
import { Box } from "digiwall-lib";
import { LocationForActionService as BaseLocationForActionService } from "../../utils/location-for-action-service";
import { Zeus } from "generated";
import { autoinject } from "aurelia-dependency-injection";

@autoinject
export class LocationForActionService extends BaseLocationForActionService {

  constructor(i18n: I18N, box: Box) {
    super(i18n, box);
  }

  public async canDeleteOrCancelArticleToAction(articleToActions: Array<Zeus.Web.Model.ArticleToAction>): Promise<boolean> {
    if (false == await super.canDeleteOrCancelArticleToAction(articleToActions)) {
      return false;
    }
    if (articleToActions.some(ata => ata.locationForInputPickings.some(lfp => (lfp as Zeus.Web.Model.LocationForInput).isBlockedForProcessing))) {
      return await this.box.showError(
        this.i18n.tr("articletoinput.cartReceptionInProgress.message"), this.i18n.tr("articletoinput.cartReceptionInProgress.title")
      ).whenClosed(() => false);
    }
    return true;
  }

  public extractDeletableLocationForInputPickings(articleToAction: Zeus.Web.Model.ArticleToAction): Zeus.Web.Model.LocationForInputPicking[] {
    let locs = super.extractDeletableLocationForInputPickings(articleToAction);
    return locs.filter(x => (x as Zeus.Web.Model.LocationForInput).isBlockedForProcessing == 0);
  }

  public reserveLocationsDisabled(articleToAction: Zeus.Web.Model.ArticleToAction): boolean {
    return articleToAction.locationForInputPickings?.some(x => (x as Zeus.Web.Model.LocationForInput).isBlockedForProcessing);
  }

  public reserveLocationsRowClass(locationForInputPicking: Zeus.Web.Model.LocationForInputPicking): string[] {
    let classes = super.reserveLocationsRowClass(locationForInputPicking);
    if ((locationForInputPicking as Zeus.Web.Model.LocationForInput).isBlockedForProcessing) {
      classes.push('ui-bg--success-tint');
    }
    return classes;
  }
}
