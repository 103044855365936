import { Router } from 'aurelia-router';
import { Zeus } from "../generated";
import { EntityDetailViewModelBase, CustomLogger, ServiceBase } from 'digiwall-lib';
import { autoinject, computedFrom } from "aurelia-framework";
import * as Constants from '../constants';
import { LocationUtils } from '../utils/utils-locations';

@autoinject
export class StorageTableRepresentation extends EntityDetailViewModelBase<Zeus.Web.Model.Storage> {
  public ressourceName: string;
  public storage: Zeus.Web.Model.Storage;
  public storageTableRepresentation: StorageTableRepresentationTable;

  public articleLocationService: ServiceBase<Zeus.Web.Model.ArticleLocation>;
  public locationService: ServiceBase<Zeus.Web.Model.Location>;
  public locationForInputPickingService: ServiceBase<Zeus.Web.Model.LocationForInputPicking>;

  private isLoadingLocations = false;
  private isLoadingInputs = false;
  private isLoading = false;
  private autoRefresh = false;
  public detailedView = false;

  constructor(
    router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage));

    this.articleLocationService = new ServiceBase<Zeus.Web.Model.ArticleLocation>(Constants.EntityTypeNames.ArticleLocation);
    this.locationService = new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location);
    this.locationForInputPickingService = new ServiceBase<Zeus.Web.Model.LocationForInputPicking>(Constants.EntityTypeNames.LocationForInputPicking);
  }

  public async activate(params) {
    await super.activate(params);
    if (params.callback != null)
      params.callback(this);

    if (this.storage != null) {
      this.initStorageTableRepresentation();
      // this.restoreLocationTrayId();
      this.entity = this.storage;
      this.controller.addObject(this.entity);
    }
  }

  detached(): void {
    clearTimeout(this.timeoutReg);
    super.detached();
  }

  public toggleView() {
    this.detailedView = !this.detailedView;
  }

  private async restoreLocationTrayId() {
    let locations = await this.locationService.getAllEntities();
    let locToSave = Array<Zeus.Web.Model.Location>();
    locations.forEach(loc => {
      let splits = loc.locationIdentifier.split('.');
      if (splits.length == 4) {
        let trayId = splits[1];
        if (loc.trayContainerId == null) {
          let tray = this.storage.trayContainers.find(t => t.trayId.toString() == trayId);
          if (tray != null) {
            loc.trayContainerId = tray.id;
            locToSave.push(loc);
          }
        }
      }
    });

    if (locToSave.length)
      this.locationService.saveEntity(locToSave[0]);
  }

  private timeoutReg;
  public toggleAutoRefresh(): void {
    this.autoRefresh = !this.autoRefresh;
    if (this.autoRefresh) {
      this.loopRefresh();
    } else {
      clearTimeout(this.timeoutReg);
    }
  }

  private loopRefresh() {
    if (!this.autoRefresh) return;
    this.timeoutReg = setTimeout(() => {
      this.refreshStorageTableRepresentation().then(() => this.loopRefresh());
    }, 60000);
  };

  public storageTableRepresentationInitialized = false;
  public initStorageTableRepresentation() {
    if (this.storageTableRepresentationInitialized == false) {
      this.storageTableRepresentationInitialized = true;
      this.refreshStorageTableRepresentation();
    }
  }


  public async refreshStorageTableRepresentation(): Promise<void> {
    if (this.isLoading || this.storage == null) return;

    this.isLoading = true;

    this.locationService.manager.clear(); // Clear cache
    let locations = await this.locationService.getEntities(
      null, //new Predicate('storageId', FilterQueryOp.Equals , this.storage.id), 
      [
        'articleLocations.article.unitOfMeasures.unitOfMeasure',
        'defaultStorageVolume.volumeType',
        'alternateLocationVolumes.storageVolume.volumeType',
        'locationForActions',
        /*'locationForActions.articleToInput.article',
        'locationForActions.articleToInput.unitOfMeasure',
        'locationForActions.articleToInput.inputWorkOrder'*/
      ],
      { storageId: this.storage.id }
    )

    // TODO
    /* await this.locationForInputPickingService.getEntities(
      new Predicate('id', FilterQueryOp., locations.map(loc => loc.id)),
      [
        'articleToInput.article',
        'articleToInput.unitOfMeasure',
        'articleToInput.inputWorkOrder'
      ]
    ) */

    if (locations == null || locations.length == 0) {
      this.isLoading = false;
      return Promise.resolve();
    }

    locations = locations.sort((a, b) => a.locationIdentifier.localeCompare(b.locationIdentifier, undefined, { numeric: true, sensitivity: 'base' }));

    if (this.storageTableRepresentation == null)
      this.storageTableRepresentation = new StorageTableRepresentationTable();

    locations.forEach(loc => {
      let parts = loc.locationIdentifier.split('.');
      if (parts.length == 4) {
        let tray = parts[1];
        let range = parts[2];
        let locIdentifier = parts[3];

        // Set tray
        let foundTray: TrayContainerTableRepresentation;
        if ((foundTray = this.storageTableRepresentation.trays.find(t => t.label == tray)) == null) {
          foundTray = new TrayContainerTableRepresentation();
          foundTray.label = tray;
          foundTray.isUnavailable = this.storage.trayContainers.find(tc => tc.trayId == parseInt(tray))?.isUnavailable || false;
          this.storageTableRepresentation.trays.push(foundTray);
        }

        // Set range
        let foundRange: RangeTableRepresentation;
        if ((foundRange = foundTray.ranges.find(r => r.label == locIdentifier)) == null) {
          foundRange = new RangeTableRepresentation();
          foundRange.label = locIdentifier;
          foundTray.ranges.push(foundRange);
        }

        // Set location
        let foundLocation: LocationTableRepresentation;
        if ((foundLocation = foundRange.locations.find(l => l.label == range)) == null) {
          foundLocation = new LocationTableRepresentation();
          foundLocation.label = range;
          foundLocation.volumeType = LocationUtils.getLocationAllVolumes(loc).map(v => v.storageVolume.volumeType.denomination._translation).join(', ');
          foundLocation.identifier = loc.locationIdentifier;
          foundLocation.location = loc;
          foundRange.locations.push(foundLocation);
        }

        // Article locations
        foundLocation.articleLocations = loc.articleLocations;
        if (foundLocation.articleLocations.length) {
          foundLocation.uom = foundLocation.articleLocations[0]?.article?.unitOfMeasures?.find(uom => uom.usedForStockCount == true)?.unitOfMeasure?.denomination._translation;
        } else {
          foundLocation.uom = null;
        }

        // Location for inputs
        foundLocation.locationForInputs = loc.locationForActions.filter((i: Zeus.Web.Model.LocationForInput) =>
          i.msgHasBeenSent != null && i.msgHasBeenSent == false
        ) as Zeus.Web.Model.LocationForInput[];
      }
    });

    this.isLoading = false;
    return Promise.resolve();


  }


  @computedFrom('entity.name')
  public get documentTitle() {
    return this.entity.name;
  }

  @computedFrom('entity.name', '_langWatcher')
  public get ribbonHeaderText() {
    if (this.entity != null) {
      return this.i18n.tr("location.locationFor") + this.entity.name;
    }

    return this.i18n.tr("location.location");
  }

}



class StorageTableRepresentationTable {
  constructor() { }
  label: string;
  trays: Array<TrayContainerTableRepresentation> = [];
}

class TrayContainerTableRepresentation {
  label: string;
  ranges: Array<RangeTableRepresentation> = [];
  isUnavailable: boolean;
}

class RangeTableRepresentation {
  label: string;
  locations: Array<LocationTableRepresentation> = [];
}

class LocationTableRepresentation {
  label: string;
  volumeType: string;
  identifier: string;
  uom: string;
  location: Zeus.Web.Model.Location;
  articleLocations: Array<Zeus.Web.Model.ArticleLocation>;
  locationForInputs: Array<Zeus.Web.Model.LocationForInput>;
}
