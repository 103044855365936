import { autoinject } from 'aurelia-framework';
import { HttpClient, json } from 'aurelia-fetch-client';
import ExifReader from 'exifreader';
import * as Constants from '../constants';
import { PictureOrientation } from 'digiwall-lib';

@autoinject
export class PictureHelper {
  constructor(private httpClient: HttpClient) {

  }
  public detectionOrientation(reader: FileReader): boolean {
    let isPortrait = false;
    try {
      const tags = ExifReader.load(reader.result as ArrayBuffer);

      let orientationTag = tags["Orientation"];
      let orientationVal = (orientationTag != null) ? orientationTag.value : null;

      if (orientationTag == null || (orientationVal != null && orientationVal == 1)) {
        let height = tags["Image Height"].value;
        let width = tags["Image Width"].value;
        if (height >= width) {
          isPortrait = true;
        }
      }
      else {
        if (orientationVal == 6 || orientationVal == 8 || orientationVal == 5 || orientationVal == 7) {
          isPortrait = true;
        }
      }
    }
    catch (error) {
      console.warn("It seems that this image do not contains any exif metadata");
    }
    return isPortrait;
  }
  public async rotatePicture(clockwise: boolean, pictureEntity: { url: string, orientation: number | PictureOrientation }) {
    let saveUri = pictureEntity.url;
    pictureEntity.url = "";

    let response = await this.httpClient.post(Constants.Application.RemoteFileServiceRotate, json({
      "uri": saveUri,
      "clockwise": clockwise
    }));
    if (response.ok) {
      let newUri = await response.json();
      pictureEntity.url = newUri;
      pictureEntity.orientation = pictureEntity.orientation == "Landscape" ? "Portrait" : "Landscape";
    }
    else {
      pictureEntity.url = saveUri;
    }

  }
}
