import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum, Various, Country } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';

@autoinject
export class SupplierDetail extends EntityDetailViewModelBase<Zeus.Web.Model.Supplier> {

  public ressourceName: string = Constants.EntityTypeNames.Supplier;

  public countryService: ServiceBase<Country>;

  constructor(
    router: Router, logger: CustomLogger, private httpClient: HttpClient, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.Supplier>(Constants.EntityTypeNames.Supplier));

    this.countryService = new ServiceBase<Country>(Constants.EntityTypeNames.Country);
  }

  public async activate(params) {
    const id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.active = true;
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'country');
      this.controller.addObject(this.entity);
    }
  }

  @computedFrom('editingMode', 'entity.supplierName', '_langWatcher')
  get documentTitle(): string {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("supplier.supplier");
    }
    if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.supplierName;
    }
  }
}
