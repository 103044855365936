import { HttpClient } from 'aurelia-fetch-client';
import { Container } from 'aurelia-framework';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { CustomLogger, ServiceBase, ViewModelBase, UIInternal, DialogBoxViewModel, ActionDialogBoxInputParameters, GlobalLoaderService, ListViewModelBase } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, bindable, BindingEngine, computedFrom } from 'aurelia-framework';
import { Zeus } from "../../../generated";
import * as Constants from '../../../constants';
import * as FPConstants from '../constants';
import { FreePicking } from '../generated';

@autoinject
export class traypicksToProcessList extends ViewModelBase {
  private Constants = Constants;
  @bindable
  public workOrderSelected: Array<Zeus.Web.Model.WorkOrder>;

  public traypickElements: FreePicking.Model.TrayPickWorkOrder[] = [];

  public paginationChange: boolean = false;

  public workOrderService: ServiceBase<Zeus.Web.Model.WorkOrder>;
  public isLaunchingMessage = false;

  private trayPickService: ServiceBase<FreePicking.Model.TrayPickWorkOrder>;
  private elements: Array<(FreePicking.Model.TrayPickWorkOrder) & { isSelected?: boolean }> = [];

  @computedFrom('_langWatcher')
  get ribbonHeaderText(): string {
    return this.i18n.tr("freePicking.trayPick");
  }
  get ressourceName(): string {
    return null;
  }

  get disableLaunchMessage(): boolean {
    return this.elements.find((x: any) => x.isSelected) == null;
  }

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private globalLoaderService: GlobalLoaderService) {
    super(router, logger);
    this.workOrderService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.trayPickService = new ServiceBase<FreePicking.Model.TrayPickWorkOrder>(FPConstants.EntityTypeNames.TrayPickWorkOrder);
  }

  private updateLock = false;
  public attached() {
    this.disposables.push(
      this.bindingEngine.collectionObserver(this.workOrderSelected).subscribe(() => this.updateList())
    );

    UIInternal.subscribe(Constants.WorkOrderProcessEvents.ReloadArticleToActions, () => {
      this.updateList();
    });

    this.updateList();
  }

  private async updateList() {
    if (this.updateLock) {
      setTimeout(() => this.updateList(), 100);
      return;
    }
    this.updateLock = true;
    this.globalLoaderService.show();
    let workOrdersById = Object.assign({}, ...this.workOrderSelected.map(x => ({ [x.id]: { shouldFetch: true, workOrder: x } })));

    // Reverse loop for removing entries no longer in the selected workOrders and tagging those we still have
    for (let i = this.traypickElements.length - 1; i >= 0; --i) {
      if (null == workOrdersById[this.traypickElements[i].workOrderId]) {
        this.traypickElements.splice(i, 1);
      } else {
        workOrdersById[this.traypickElements[i].workOrderId].shouldFetch = false;
      }
    }

    let newElements = [];

    let predicate = this.loadNewTraypicks(workOrdersById);
    if (predicate !== null) {
      this.pushNewElements(newElements, (await this.trayPickService.getEntities(predicate, ['trayContainer.storage'])));
    }

    this.traypickElements.push(...newElements);
    this.elements.splice(0);

    this.elements.push(...this.traypickElements);
    this.elements.forEach(entry => entry.isSelected = true);
    this.globalLoaderService.hide();
    this.updateLock = false;
  }

  private getRowClass(record) {
    return record?.hasError ? 'wo-traypick-error' : '';
  }

  private loadNewTraypicks(workOrdersById: any): Predicate {
    let byWorkOrder = Object.keys(workOrdersById)
      .filter((woId: string) => workOrdersById[woId].shouldFetch)
      .map((woId: string) => new Predicate('workOrderId', FilterQueryOp.Equals, parseInt(woId)));

    return byWorkOrder.length > 0 ? new Predicate('hasBeenCancelled', FilterQueryOp.Equals, false).and(Predicate.or(byWorkOrder)) : null;
  }

  private pushNewElements(newElements: FreePicking.Model.TrayPickWorkOrder[], toPush: FreePicking.Model.TrayPickWorkOrder[]) {
    newElements.push(...toPush);
  }

  private toggleSelection(entry: any) {
    entry.isSelected = !entry.isSelected;
  }

  public async launchMessage() {
    if (this.isLaunchingMessage || this.disableLaunchMessage) return;

    let traypickSelected = this.elements.filter((traypick: any) => traypick.isSelected);
    let result = FPConstants.ActionTraypickWOError.Launch;
    if (traypickSelected.some((traypick: any) => traypick.hasError)) {
      result = await this.continueLaunchWithError(traypickSelected);
    }
    if (result == FPConstants.ActionTraypickWOError.Launch) {
      await this.sendMessage(traypickSelected);
    } else if (result == FPConstants.ActionTraypickWOError.Cancel) {
      const workOrdersToCancelId: Array<number> = traypickSelected
        .filter((x: any) => x.hasError)
        .map(x => x.workOrderId)
        .filter((value, index, array) => array.indexOf(value) === index);
      const woLinesToCancel = traypickSelected.filter(x => workOrdersToCancelId.includes(x.workOrderId));
      await this.cancel(woLinesToCancel);
      const woLinesToSend = traypickSelected.filter(x => false == workOrdersToCancelId.includes(x.workOrderId));
      if (woLinesToSend.length > 0) {
        await this.sendMessage(woLinesToSend);
      }
    } else if (result == FPConstants.ActionTraypickWOError.Edit) {
      this.router.navigate("/work-orders/" + this.traypickElements[0].workOrderId);
    }
  }

  private async continueLaunchWithError(traypickSelected: FreePicking.Model.TrayPickWorkOrder[]): Promise<FPConstants.ActionTraypickWOError> {
    const hasManyWO = traypickSelected.groupBy("workOrderId").size > 1;
    let buttons: ActionDialogBoxInputParameters[] = [
      {
        label: "workordertoprocess.errorInvArticleLocLine." + (hasManyWO ? "cancelAll" : "cancel"),
        theme: "danger",
        type: "tool",
        fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(FPConstants.ActionTraypickWOError.Cancel),
      },
      {
        label: "workordertoprocess.errorInvArticleLocLine.launch",
        theme: "primary",
        type: "solid",
        fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(FPConstants.ActionTraypickWOError.Launch),
      },
    ];
    if (!hasManyWO) {
      buttons.splice(1, 0,
        {
          label: "workordertoprocess.errorInvArticleLocLine.edit",
          theme: "primary",
          type: "tool",
          fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(FPConstants.ActionTraypickWOError.Edit),
        },
      );
    }
    let result = await (await this.box.showQuestion(
      this.i18n.tr("workordertoprocess.errorInvArticleLocLine.questionToAsk"),
      this.i18n.tr("freePicking.traypick"),
      buttons
    )).closeResult;
    if (!result.wasCancelled) {
      return result.output;
    }
    return null;
  }

  private async sendMessage(traypickSelected: FreePicking.Model.TrayPickWorkOrder[]) {
    this.isLaunchingMessage = true;
    try {
      if (traypickSelected.length > 0) {
        var http = Container.instance.get(HttpClient);
        let response = await http.post(FPConstants.Application.SendTrayPicks, JSON.stringify(traypickSelected.map(x => x.id)));
        if (response.ok) {
          this.workOrderSelected?.forEach(wo => (<any>wo).isSelected = false);
          this.workOrderSelected.splice(0);
          this.traypickElements.splice(0);
          this.elements.splice(0);
          UIInternal.broadcast(Constants.WorkOrderProcessEvents.ReloadWorkOrder);
          UIInternal.broadcast(Constants.WorkOrderProcessEvents.ReloadTrayPickWorkOrders);
          this.logger.Log(this.i18n.tr('workordertoprocess.' + (await response.text())), null, null, true);
        }
      }
    } catch (e) {
      this.logger.LogError(this.i18n.tr((e as Error).message), null, null, true);
    }
    this.isLaunchingMessage = false;
  }

  public async cancelTraypick() {
    if (!(await this.cancelTraypickConfirmed())) {
      return;
    }

    await this.cancel(this.elements.filter(traypick => traypick.isSelected));
  }

  public async cancel(traypickSelected: FreePicking.Model.TrayPickWorkOrder[]) {
    traypickSelected.forEach(traypick => traypick.hasBeenCancelled = true);
    await this.trayPickService.saveEntities(traypickSelected);
    const WorkOrderIdsToKeep = Array.from(new Set(this.elements.filter(element => element.isSelected === false).map(element => element.workOrderId)));
    UIInternal.broadcast(Constants.WorkOrderProcessEvents.BatchProcessSent, { workOrderIdsToKeep: WorkOrderIdsToKeep });
  }

  public async cancelTraypickConfirmed(): Promise<boolean> {
    return await this.box.showQuestion(
      this.i18n.tr("workorder.confirmCancellationTraypicks"), this.i18n.tr("menu.del-filter-set-title"),
      [
        { label: this.i18n.tr('general.no'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.cancel() },
        { label: this.i18n.tr('general.yes'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok() }
      ]
    ).whenClosed(result => !result.wasCancelled);
  }
}

