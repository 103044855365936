import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';

@autoinject
export class MessageAnswerList extends ListViewModelBase<Zeus.Web.Model.ReceivedMessage> {
  public ressourceName: string = Constants.EntityTypeNames.ReceivedMessage;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.ReceivedMessage>(Constants.EntityTypeNames.ReceivedMessage));
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.ReceivedMessage) {
    if (entity && entity.id) {
      let url = '/receive-messages/' + entity.id;
      return url;
    }
    return null;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("message.status"),
        field: "status",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("message.responseTreated"),
        field: "responseTreated",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("message.messageId"),
        field: "sentMessageId",
        type: FieldType.Base
      },
      {
        headerName: this.i18n.tr("message.businessProcessingComment"),
        field: "businessProcessingComment",
        type: FieldType.String,
        hide: true,
      },
      {
        headerName: this.i18n.tr("message.content"),
        field: "contentRequest",
        type: FieldType.String,
        hide: true
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser, {
        createdTimeColDef: {
          hide: false,
          sort: 'desc'
        }
      })
    ];
    return defs;
  }
} 
