import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import * as LPConstants from '../constants';
import { LabelPrinting } from '../generated';

@autoinject
export class MessageSentDetail extends EntityDetailViewModelBase<LabelPrinting.Model.PrinterSentMessage> {
  public ressourceName: string = LPConstants.EntityTypeNames.PrinterSentMessage;
  public documentTitle = this.i18n.tr("lpsentmessage.lpsentmessage");

  constructor(
    router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<LabelPrinting.Model.PrinterSentMessage>(LPConstants.EntityTypeNames.PrinterSentMessage));
  }

  @computedFrom("entity.labelType", '_langWatcher')
  public get ribbonHeaderText() {
    return this.i18n.tr("lpsentmessage.lpsentmessage") + " - " + this.entity?.labelType?.denomination?._translation;
  }

  public async activate(params) {
    const id = params.param1;
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id, "labelType");
    this.controller.addObject(this.entity);
  }

  public stringifyObj(obj) {
    let json = JSON.parse(obj);
    return JSON.stringify(json, null, 4);
  }

} 
