import { TrayPreview } from '../locations/tray-preview';
import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum, Various, ShowEditDialogOptions, IMenuItems, UIInternal } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import * as toastr from 'toastr';
import { PositionDetail } from './position-detail';
import { GeneratePositions } from './generate-positions';
import { Utils } from 'utils/utils';
import { DataFormat } from 'select2';
import { LocationUtils } from '../utils/utils-locations';

@autoinject
export class TrayTemplateDetail extends EntityDetailViewModelBase<Zeus.Web.Model.TrayTemplate> {

  public ressourceName: string = Constants.EntityTypeNames.TrayTemplate;

  public storageService: ServiceBase<Zeus.Web.Model.Storage>;

  public selectedStorage: DataFormat;

  private trayPreview: TrayPreview;
  private locationsExpanded: boolean = false;
  public isEditDialog: boolean = false;
  private positionsSorted = false;

  private addPositionActions: IMenuItems[];

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.TrayTemplate>(Constants.EntityTypeNames.TrayTemplate));
    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);

    this.setAddPositionActions();
    UIInternal.subscribe(UIInternal.EVT_CHANGE_LANGUAGE, () => { this.setAddPositionActions() })
  }

  private setAddPositionActions() {
    this.addPositionActions = [
      {
        label: this.i18n.tr('traytemplate.single'),
        handler: () => this.openPositionModal()
      },
      {
        label: this.i18n.tr('traytemplate.multiple'),
        handler: () => this.generatePositions()
      }
    ];
  }

  public async activate(params) {
    await super.activate(params);
    const id = params.param1;

    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ trayWidthCm: 0, trayDepthCm: 0 });
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'locations.defaultStorageVolume.volumeType');
      this.controller.addObject(this.entity);
    }

    this.disposables.push(
      this.bindingEngine.propertyObserver(this, "selectedStorage").subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          let storage = await this.storageService.getEntityByIdFromLocalCache(Utils.extractIdFromDataFormat(this.selectedStorage));
          this.entity.trayWidthCm = storage.trayWidthCm;
          this.entity.trayDepthCm = storage.trayDepthCm;
        }
      })
    );

    // Do this after observer in order to react
    if (id == Various.NewId && params.callback) {
      params.callback(this);
    }

    if (!this.positionsSorted) {
      this.sortPositions();
      this.positionsSorted = true;
    }
  }

  @computedFrom('editingMode', 'entity.name', '_langWatcher')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("traytemplate.addTrayTemplate");
    }
    if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.name;
    }
  }

  @computedFrom('entity.trayWidthCm', 'entity.trayDepthCm')
  private get enablePositions(): boolean {
    return this.entity.trayWidthCm != null && this.entity.trayWidthCm > 0 && this.entity.trayDepthCm != null && this.entity.trayDepthCm > 0;
  }

  @computedFrom('entity.name', 'enablePositions')
  private get saveEnabled(): boolean {
    return this.entity.name != null && this.entity.name.trim() != "" && this.enablePositions;
  }

  private sortPositions() {
    this.entity.locations.sort((a, b) => (a.locationIdentifier < b.locationIdentifier ? -1 : 1));
  }

  public async openPositionModal(position: Zeus.Web.Model.TrayTemplatePosition = null) {
    if (this.canSave) {
      await this.save();
    }

    let originalValues: Partial<Zeus.Web.Model.TrayTemplatePosition> = null;
    if (position != null) {
      originalValues = {
        locationIdentifier: position.locationIdentifier,
        storageUnitWidth: position.storageUnitWidth,
        storageUnitLength: position.storageUnitLength,
        defaultStorageVolumeId: position.defaultStorageVolumeId
      };
    }
    await this.box.showEditDialog(PositionDetail, position?.id || Various.NewId, this.i18n.tr('traytemplate.' + (position ? 'editPosition' : 'addPosition')),
      new ShowEditDialogOptions({
        callback: (vm: PositionDetail) => {
          vm.fromDialogBox = true;
          if (position) {
            vm.entity = position;
          } else {
            position = vm.entity;
            vm.entity.trayTemplate = this.entity;
          }
        },
        canSave: false,
        icon: 'ze-location-coordinates',
        actions: []
      })).whenClosed((result) => {
        if (!result.wasCancelled) {
          position.trayTemplate = this.entity; // needed for creation only
          this.trayPreview?.redraw();
        } else if (originalValues == null) {
          // Cancelled a new position
          position.entityAspect.setDeleted();
        } else {
          Object.keys(originalValues).forEach(key => position[key] = originalValues[key]);
        }
      });

    this.sortPositions();
  }

  private async removePosition(position: Zeus.Web.Model.TrayTemplatePosition) {
    position.entityAspect.setDeleted();
    this.trayPreview?.redraw();
  }

  public async generatePositions() {
    if (this.canSave) {
      await this.save();
    }
    await this.dialogService.open({
      viewModel: GeneratePositions, model: { trayTemplate: this.entity }
    }).whenClosed(async result => {
      if (!result.wasCancelled) {
        result.output?.forEach((x: Zeus.Web.Model.TrayTemplatePosition) => {
          x.trayTemplate = this.entity;
        });
        this.trayPreview?.redraw();
      }
    });

    this.sortPositions();
  }

  private isPositionIncompatible(position: Zeus.Web.Model.TrayTemplatePosition) {
    let [x, y] = LocationUtils.getIntCoordFromIdentifier(position.locationIdentifier);
    return x + (position.storageUnitWidth / 2) > this.entity.trayWidthCm || y + (position.storageUnitLength / 2) > this.entity.trayDepthCm;
  }

  public async beforeSave(): Promise<boolean | void> {
    if (this.entity.locations.some(loc => this.isPositionIncompatible(loc))) {
      toastr.error(this.i18n.tr("traytemplate.storageNotCompatible"));
      return false;
    }
    return true;
  }

  public async saveCurrentEntity(silentSave?: boolean): Promise<Zeus.Web.Model.TrayTemplate> {
    if (this.isEditDialog) {
      // Set edition mode only if save is completed
      let savePromise = await this.save(false, false, false);
      this.editingMode = EditingModeEnum.Update;
      return savePromise;
    }
    return await super.saveCurrentEntity(silentSave);
  }
} 
