import { ArticleDetailLocations } from './article-detail-helpers/article-detail-locations';
import { ApiService } from '../external-src/api.service';
import { Predicate, FilterQueryOp, EntityState } from 'breeze-client';
import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum, EnumerationTypeService, FileFlow, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import ZeusBreezeModel from 'model';
import { ArticleStorageGroupModal } from './article-storage-group-modal';
import { ArticleFamilyTreeView } from './article-families-tree-view/article-family-tree-view';
import { ListViewModelActionHistoryFilter } from 'action-histories/action-history-list';
import { PrintLabel } from 'app-modules/label-printing/print-label/print-label';
import { AppModuleService } from 'app-modules/app-module-service';
import * as AMConstants from 'app-modules/constants';
import * as LPConstants from 'app-modules/label-printing/constants';
import { IncompleteMlField } from 'digiwall-lib/dist/typings/utils/incomplete-multilingual-field/incomplete-multilingual-field';
import { ZeusUtilsMlFieldHelper } from 'utils/utils-ml-field-helper';

@autoinject
export class ArticleDetail extends EntityDetailViewModelBase<Zeus.Web.Model.Article> {
  public ressourceName: string = Constants.EntityTypeNames.Article;

  public selectedLanguageCode = 'en';
  public locationsOnSameSite = {};

  public articleUnitOfMeasureSizeService: ServiceBase<Zeus.Web.Model.ArticleUnitOfMeasureSize>;
  public articleVolumeConfigService: ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>;
  public articleStorageGroupService: ServiceBase<Zeus.Web.Model.ArticleStorageGroup>;
  public storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;
  public storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  public articleFamilyService: ServiceBase<Zeus.Web.Model.ArticleFamily>;
  public articleReferenceAliasTagService: ServiceBase<Zeus.Web.Model.ArticleReferenceAliasTag>;
  public articleBarcodeEanService: ServiceBase<Zeus.Web.Model.ArticleBarcodeEan>;
  private inputAlgorithmService: ServiceBase<Zeus.Web.Model.InputAlgorithm>;
  public applicationParameterService: ServiceBase<Zeus.Web.Model.ApplicationParameters>;
  public supplierArticleService: ServiceBase<Zeus.Web.Model.SupplierArticle>;
  public supplierService: ServiceBase<Zeus.Web.Model.Supplier>;
  public languagePriorityService: ServiceBase<Zeus.Web.Model.LanguagePriority>;

  public unitOfMeasuresService: EnumerationTypeService;
  public unitOfMeasuresVolumeConfigurationService: EnumerationTypeService;
  private articleRotationLevelTypeService: EnumerationTypeService;
  private storageLocationTypeService: EnumerationTypeService;
  private sortingLocationTypeService: EnumerationTypeService;
  public storageStrategyService: EnumerationTypeService;

  public articleStorageGroup: Zeus.Web.Model.ArticleStorageGroup;
  public fetchedUnitOfMeasureSizes: Zeus.Web.Model.ArticleUnitOfMeasureSize[];
  private defaultInputAlgo: Zeus.Web.Model.InputAlgorithm;

  private articleLocationsView: ArticleDetailLocations;

  private severalZonesExist: boolean = false;

  private EntityState: EntityState = EntityState;
  private EnumerationTypes = Constants.EnumerationTypes;

  private labelPrintingActive: boolean = false;

  private canAddInventory = this.canUpdate && this.authService.checkAccess(Constants.EntityTypeNames.Article, Constants.Permissions.AddInventory);

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private apiService: ApiService, private appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.Article>(Constants.EntityTypeNames.Article));

    this.articleUnitOfMeasureSizeService = new ServiceBase<Zeus.Web.Model.ArticleUnitOfMeasureSize>(Constants.EntityTypeNames.ArticleUnitOfMeasureSize);
    this.articleUnitOfMeasureSizeService.gridDataSource.expands = ['unitOfMeasure'];
    this.articleStorageGroupService = new ServiceBase<Zeus.Web.Model.ArticleStorageGroup>(Constants.EntityTypeNames.ArticleStorageGroup);
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.articleVolumeConfigService = new ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>(Constants.EntityTypeNames.ArticleVolumeConfig);
    this.articleVolumeConfigService.gridDataSource.expands = ['storageVolume.volumeType', 'unitOfMeasure'];
    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ['volumeType'];
    this.articleFamilyService = new ServiceBase<Zeus.Web.Model.ArticleFamily>(Constants.EntityTypeNames.ArticleFamily);

    this.articleReferenceAliasTagService = new ServiceBase<Zeus.Web.Model.ArticleReferenceAliasTag>(Constants.EntityTypeNames.ArticleReferenceAliasTag);
    this.articleBarcodeEanService = new ServiceBase<Zeus.Web.Model.ArticleBarcodeEan>(Constants.EntityTypeNames.ArticleBarcodeEan);

    this.unitOfMeasuresService = new EnumerationTypeService(Constants.EnumerationTypes.UnitOfMeasure);
    this.unitOfMeasuresService.gridDataSource.customSelect2Predicates = () => {
      return this.entity.unitOfMeasures.reduce((predicate: Predicate, uom) => {
        // Ignore empty selects
        if (uom.unitOfMeasureId == null) {
          return predicate;
        }
        let uomPredicate = new Predicate('id', FilterQueryOp.NotEquals, uom.unitOfMeasureId);
        return predicate ? predicate.and(uomPredicate) : uomPredicate;
      }, null);
    };

    this.unitOfMeasuresVolumeConfigurationService = new EnumerationTypeService(Constants.EnumerationTypes.UnitOfMeasure);

    this.articleRotationLevelTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ArticleRotationLevel);

    this.storageLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageLocationType);

    this.applicationParameterService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters);
    this.inputAlgorithmService = new ServiceBase<Zeus.Web.Model.InputAlgorithm>(Constants.EntityTypeNames.InputAlgorithm);
    this.sortingLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.SortingLocationType);

    this.storageStrategyService = new EnumerationTypeService(Constants.EnumerationTypes.StorageStrategy);

    this.supplierArticleService = new ServiceBase<Zeus.Web.Model.SupplierArticle>(Constants.EntityTypeNames.SupplierArticle);
    this.supplierService = new ServiceBase<Zeus.Web.Model.Supplier>(Constants.EntityTypeNames.Supplier);
    this.languagePriorityService = new ServiceBase<Zeus.Web.Model.LanguagePriority>(Constants.EntityTypeNames.LanguagePriority);

    const supplierPredicateFn = this.supplierService.gridDataSource.getPredicatesFunction;
    this.supplierService.gridDataSource.getPredicatesFunction = (filterText) => {
      let predicate = supplierPredicateFn(null);
      let uniquePredicate = this.entity?.supplierArticles.reduce((predicate: Predicate, supplierArticle: Zeus.Web.Model.SupplierArticle) => {
        let newPredicate = new Predicate('id', FilterQueryOp.NotEquals, supplierArticle.supplierId);
        return predicate ? predicate.and(newPredicate) : newPredicate;
      }, null);
      return predicate && uniquePredicate ? predicate.and(uniquePredicate) : (predicate || uniquePredicate);
    };

    new ServiceBase<Zeus.Web.Model.InputAlgorithm>(Constants.EntityTypeNames.InputAlgorithm)
      .firstEntity(new Predicate('isDefault', FilterQueryOp.Equals, true))
      .then(defaultAlgo => this.defaultInputAlgo = defaultAlgo);

    this.labelPrintingActive = this.appModuleService.isActive(AMConstants.AppModuleEnum.LabelPrinting);
  }

  public async activate(params) {
    const id = params.param1;
    const applicationParameter: Zeus.Web.Model.ApplicationParameters = await this.applicationParameterService.firstEntity(null, ['defaultArticleHandling']);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ active: true });
      this.createArticleStorageGroupEntity(applicationParameter);
    } else {
      this.service.manager.clear();
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'articleBlock', 'articleReferenceAliasTags', 'articleBarcodeEans',
        'unitOfMeasures.unitOfMeasure', 'articleVolumeConfigs.storageVolume.volumeType', 'articleVolumeConfigs.unitOfMeasure',
        'articleFamilies.articleFamily', 'supplierArticles.supplier', 'articleStorageGroups.storageStrategy');
      await this.articleStorageGroupService.getEntities(null, ['storageLocationType1', 'storageLocationType2', 'storageLocationType3',
        'storageGroup.storageGroupType', 'articleRotationLevel', 'sortingLocation', 'inputAlgorithm', 'storageStrategy'], { articleId: this.entity.id })

      this.entity.articleVolumeConfigs.sort((a, b) => { return a.unitOfMeasure.denomination._translation > b.unitOfMeasure.denomination._translation ? 1 : -1 });
      this.articleUnitOfMeasureSizeService.gridDataSource.queryParameters = { articleId: this.entity.id, withoutUsedForStockCount: true };
      this.unitOfMeasuresVolumeConfigurationService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.UnitOfMeasure, articleId: this.entity.id };

      this.articleStorageGroup = this.entity.articleStorageGroups.find(x => x.default);
      this.controller.addObject(this.entity);
    }

    this.selectedLanguageCode = this.i18n.getLocale();
  }

  @computedFrom('defaultInputAlgo.name._translation')
  private get inputAlgorithmPlaceholder(): string {
    return `${this.i18n.tr('algorithm.usingDefault')}: ${this.defaultInputAlgo?.name._translation}`;
  }

  @computedFrom('entity.articleLocations.length')
  private get cantChangeBaseUom(): boolean {
    return this.entity.articleLocations?.length > 0 && this.entity.articleLocations.some(l => [l.currentQuantityInLocation, l.lastInventoryQuantity, l.reservedInputQuantity, l.reservedPickingQuantity].some(qty => qty > 0));
  }

  @computedFrom('entity.id')
  private get isBorrowingActive() {
    return this.appModuleService.isActive(AMConstants.AppModuleEnum.Borrowing);
  }

  @computedFrom('entity.id')
  private get isMobileScreensModuleActive() {
    return this.appModuleService.isActive(AMConstants.AppModuleEnum.MobileScreens);
  }

  @computedFrom('isCreationMode', 'canEdit', 'canAdd')
  public get canEditFields() {
    return (this.isCreationMode && this.canAdd) || this.canEdit;
  }

  private fetchStorageGroupOnUserSite(storageGroupId: number) {
    this.apiService.storageGroupOnUserSite(storageGroupId).then(onSite => this.locationsOnSameSite[storageGroupId] = onSite)
  }

  @computedFrom('editingMode', 'entity.reference', '_langWatcher')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("article.article");
    }
    if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.reference;
    }
  }

  public async beforeSave() {
    let mlFields: IncompleteMlField[] = [
      { prop: this.entity.description, label: "article.description", isRequired: false },
      { prop: this.entity.info, label: "article.info", isRequired: false, isTextarea: true },
      { prop: this.articleStorageGroup.articleStorageConstraintCommentMultiline, label: "articlestoragegroup.articleStorageConstraintCommentMultiline", isRequired: false, isTextarea: true }
    ];

    if (this.entity.supplierArticles?.length > 0) {
      mlFields.push(...this.entity.supplierArticles.map<IncompleteMlField>(x => {
        return {
          prop: x.supplierArticleDescription,
          label: this.i18n.tr("article.supplierDescription", { supplierName: x.supplier?.supplierName }),
          isRequired: false
        };
      }));
    }

    let isValid: boolean = await ZeusUtilsMlFieldHelper.BeforeSave(this.entity, mlFields);

    if (!this.isCreationMode) {
      let kvp = this.entity.articleVolumeConfigs.map(artVol => artVol.storageVolumeId?.toString() + "-" + artVol.unitOfMeasureId?.toString());
      if (kvp.some((elem, index) => index != kvp.lastIndexOf(elem))) {
        this.logError(this.i18n.tr('articlevolumeconfig.uomVolumeMustBeUnique'), null);
        isValid = false;
      }
      if (isValid) {
        this.articleLocationsView?.beforeSaveArticle();
      }
    }

    if (!this.isCreationMode && !this.entity.unitOfMeasures.some(x => x.usedForStockCount)) {
      this.logError(this.i18n.tr('articleunitofmeasuresize.baseUOMError'), null);
      isValid = false;
    }

    if (!this.isCreationMode && !this.entity.unitOfMeasures.some(x => x.defaultInputUOM)) {
      this.logError(this.i18n.tr('articleunitofmeasuresize.defaultInputUOMError'), null);
      isValid = false;
    }

    if (!this.isCreationMode && this.entity.unitOfMeasures.some(x => !x.usedForStockCount && x.quantityInStockCountUOM <= 1)) {
      this.logError(this.i18n.tr('articleunitofmeasuresize.notBaseUomQtyError'), null);
      isValid = false;
    }

    return isValid;
  }

  public async afterSave() {
    (this.articleLocationsView?.uiTableLocations as any)?.refresh();
  }

  public async attached() {
    this.entity.unitOfMeasures.forEach(unitOfMeasure => {
      this.observableUnitOfMeasure(unitOfMeasure);
    });

    this.supplierService.gridDataSource.customSelect2Predicates = () => new Predicate("active", FilterQueryOp.Equals, true);

    this.severalZonesExist = (await this.storageGroupService.getCount(new Predicate("storageGroupTypeId", FilterQueryOp.Equals, Constants.StorageGroupTypes.Zone))) > 1;
  }

  private observableUnitOfMeasure(uom: Zeus.Web.Model.ArticleUnitOfMeasureSize) {
    this.disposables.push(
      this.bindingEngine.propertyObserver(uom, "defaultInputUOM").subscribe((newValue, oldValue) => {
        if (newValue) {
          this.entity.unitOfMeasures.filter(x => x.id != uom.id).forEach(x => {
            x.defaultInputUOM = false;
          });
        }
      }),
      this.bindingEngine.propertyObserver(uom, "usedForStockCount").subscribe((newValue, oldValue) => {
        if (newValue) {
          this.entity.unitOfMeasures.filter(x => x.id != uom.id).forEach(x => {
            x.usedForStockCount = false;
          });
          uom.quantityInStockCountUOM = 1;
        }
      })
    );
  }

  //#region Unit Of Measure
  @computedFrom('entity.unitOfMeasures.length', 'entity.entityAspect.entityState')
  private get canRemove(): boolean {
    return this.entity.unitOfMeasures?.filter(uom => !uom.entityAspect.entityState.isAdded()).length > 1;
  }

  public async removeUnitOfMeasure(unitOfMeasureSize: Zeus.Web.Model.ArticleUnitOfMeasureSize) {
    // Cannot delete last unit of measure
    if (this.entity.unitOfMeasures?.length == 1) {
      return;
    }
    let volumeConfigsToRemove = this.entity.articleVolumeConfigs.filter(vc => vc.unitOfMeasureId == unitOfMeasureSize.unitOfMeasureId);
    let deleted = true;

    if (unitOfMeasureSize.entityAspect.entityState.isAdded()) {
      unitOfMeasureSize.entityAspect.setDetached();
    } else {
      deleted = await this.articleUnitOfMeasureSizeService.deleteEntities([unitOfMeasureSize], true);
    }

    if (false === deleted) {
      return;
    }
    volumeConfigsToRemove.forEach(vc => this.removeArticleVolumeConfig(vc, true));

    if (null == this.entity.unitOfMeasures.find(uom => uom.usedForStockCount)) {
      this.entity.unitOfMeasures[0].usedForStockCount = true;
    }
    if (null == this.entity.unitOfMeasures.find(uom => uom.defaultInputUOM)) {
      this.entity.unitOfMeasures[0].defaultInputUOM = true;
    }
  }

  public async addUnitOfMeasure() {
    let newUOM = await this.articleUnitOfMeasureSizeService.createEntity({ unitOfMeasureId: null });
    this.observableUnitOfMeasure(newUOM);
    if (this.entity.unitOfMeasures.length == 0) {
      newUOM.defaultInputUOM = true;
      newUOM.usedForStockCount = true;
      newUOM.quantityInStockCountUOM = 1;
    }
    newUOM.articleId = this.entity.id;
  }
  //#endregion

  //#region Volume Config
  public async addArticleVolumeConfig() {
    await this.articleVolumeConfigService.createEntity({ articleId: this.entity.id });
  }

  public async removeArticleVolumeConfig(volumeConfig: Zeus.Web.Model.ArticleVolumeConfig, silent: boolean = false) {
    if (volumeConfig.entityAspect.entityState.isAdded()) {
      volumeConfig.entityAspect.setDetached();
    } else {
      await this.articleVolumeConfigService.deleteEntities([volumeConfig], true, null, silent);
    }
  }

  public async setDefaultVolumeConfig(volumeConfig: Zeus.Web.Model.ArticleVolumeConfig) {
    this.entity.articleVolumeConfigs
      .filter(x => x.id != volumeConfig.id && x.unitOfMeasureId == volumeConfig.unitOfMeasureId)
      .forEach(x => x.default = false);
  }
  //#endregion

  //#region Storage Group
  public async createArticleStorageGroupEntity(applicationParameter: Zeus.Web.Model.ApplicationParameters) {
    this.articleStorageGroup = await this.articleStorageGroupService.createEntity();
    this.articleStorageGroup.default = true;

    this.articleStorageGroup.storageStrategyId = applicationParameter.defaultArticleHandlingId;

    let storageLocationTypeDefault = await this.storageLocationTypeService.getDefault();
    if (storageLocationTypeDefault != null) {
      this.articleStorageGroup.storageLocationType1Id = storageLocationTypeDefault.id;
    }
    this.articleStorageGroup.article = this.entity;
  }

  public async removeStorageGroup(articleStorageGroup: Zeus.Web.Model.ArticleStorageGroup) {
    if (articleStorageGroup.entityAspect.entityState.isAdded()) {
      articleStorageGroup.entityAspect.setDetached();
    } else {
      await this.articleStorageGroupService.deleteEntities([articleStorageGroup], true);
    }
  }

  public async addStorageGroup() {
    //TODO change when up dwf to 2.27.0
    await this.box.showEditDialog(ArticleStorageGroupModal, -100, this.i18n.tr("articlestoragegroup.storagegroup"),
      (vm: ArticleStorageGroupModal) => {
        vm.selectedLanguageCode = this.selectedLanguageCode;
        vm.entity.article = this.entity;
        vm.entity.articleRotationLevel = this.articleStorageGroup.articleRotationLevel;
        vm.entity.storageStrategy = this.articleStorageGroup.storageStrategy;
      }, { icon: 'digi-stock' });
  }

  public async editArticleStorageGroup(articleStorageGroup: Zeus.Web.Model.ArticleStorageGroup) {
    //TODO change when up dwf to 2.27.0
    await this.box.showEditDialog(ArticleStorageGroupModal, articleStorageGroup.id,
      this.i18n.tr("articlestoragegroup.storagegroup"),
      (vm: ArticleStorageGroupModal) => {
        vm.selectedLanguageCode = this.selectedLanguageCode;
      }, { icon: 'digi-stock' });
  }

  private articleStorageGroupFilter = (record: Zeus.Web.Model.ArticleStorageGroup) => false == record.default;
  //#endregion

  //#region Supplier Articles
  public async addSupplierArticle() {
    let newSupplierArticle = await this.supplierArticleService.createEntity({ articleId: this.entity.id }) as Zeus.Web.Model.SupplierArticle;
    // Auto set first to default
    if (this.entity.supplierArticles.length === 1) {
      newSupplierArticle.isDefault = true;
    }
  }

  public setDefaultSupplierArticle(supplierArticle: Zeus.Web.Model.SupplierArticle) {
    if (supplierArticle.isDefault) {
      // Set other defaults to false
      this.entity.supplierArticles.filter(x => x.id != supplierArticle.id).forEach(x => x.isDefault = false);
    } else {
      // Select at least one default (first in list except self)
      let first = this.entity.supplierArticles.find(x => x.id !== supplierArticle.id);
      if (first) {
        first.isDefault = true;
      } else {
        // Rollback
        supplierArticle.isDefault = true;
      }
    }
  }

  public async removeSupplierArticle(supplierArticle: Zeus.Web.Model.SupplierArticle) {
    if (supplierArticle.entityAspect.entityState.isAdded()) {
      supplierArticle.entityAspect.setDetached();
    } else {
      await this.supplierArticleService.deleteEntities([supplierArticle], true);
    }
  }
  //#endregion

  //#region Picture
  @computedFrom("entity.pictureUrl")
  public get currentLogo(): string {
    return (this.entity.pictureUrl ? this.entity.pictureUrl : Constants.Pictures.NoPictureContent);
  }

  public deletePicture() {
    this.entity.pictureUrl = null;
    this.entity.pictureCreationDate = null;
  }

  public async uploadPicture(file: FileFlow) {
    if (this.isCreationMode) {
      file.cancel();
      this.logger.Log(this.i18n.tr("article.createBeforeUploadPictureAlert"), this.entity, "pictureUrl", true);
    }
  }

  public async pictureUploaded(fileUploaded: FileFlow) {
    this.entity.pictureUrl = fileUploaded.url;
    this.entity.pictureCreationDate = new Date();
  }
  //#endregion

  public async openArticleFamiliesTreeView() {
    if (this.canAddOrUpdate) {
      this.dialogService.open({ viewModel: ArticleFamilyTreeView, model: this.entity, lock: true });
    }
  }

  public openActionHistory(): void {
    let filter = new ListViewModelActionHistoryFilter('article', [this.entity.id]);
    this.navigateTo('action-history/all/' + filter.encode());
  }

  public async printArticleLabels() {
    await this.dialogService.open({
      viewModel: PrintLabel, model: { labelTypeId: LPConstants.LabelTypeId.Article, articles: [this.entity] }
    });
  }
}
