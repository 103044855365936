import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ColDef } from "ag-grid-community";
import * as LPConstants from '../constants';
import { LabelPrinting } from '../generated';
import * as Constants from '../../../constants';
import { CellRenderer } from 'utils/cell-renderer';

@autoinject
export class PrinterSentMessage extends ListViewModelBase<LabelPrinting.Model.PrinterSentMessage> {
  public ressourceName: string = LPConstants.EntityTypeNames.PrinterSentMessage;

  constructor(
    router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<LabelPrinting.Model.PrinterSentMessage>(LPConstants.EntityTypeNames.PrinterSentMessage));
    this.service.gridDataSource.expands = ['labelType'];
  }

  public getDetailsUrl(self, entity: LabelPrinting.Model.PrinterSentMessage) {
    if (entity && entity.id) {
      return '/label-printing-sent-messages/' + entity.id;
    }
    return null;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("lpsentmessage.labelTypeId"),
        field: "labelType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: LPConstants.EnumerationTypes.LabelType
        }
      },
      {
        headerName: this.i18n.tr("lpsentmessage.content"),
        field: "content",
        type: FieldType.String
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser, {
        createdTimeColDef: {
          hide: false,
          sort: 'desc'
        },
        createdFullNameColDef: {
          hide: false,
        }
      }),
    ];
    return defs;
  }
} 
