import { CustomLogger, ServiceBase, ViewModelBase } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, viewResources, TaskQueue, BindingEngine, computedFrom, ICollectionObserverSplice } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import { traypicksToProcessList } from '../app-modules/free-picking/traypicks-to-process-list/traypicks-to-process-list';

@autoinject
@viewResources(
  './work-orders-to-process-list/work-order-to-process-list',
  './article-to-action-list/article-to-action-list',
  './inventory-to-process-list/inventory-to-process-list',
  traypicksToProcessList
)
export class WorkOrderToProcess extends ViewModelBase {
  get ressourceName(): string { return ''; }
  get ribbonHeaderText(): string { return ''; }

  public workOrderSelected: Array<Zeus.Web.Model.WorkOrder> = [];
  private woService: ServiceBase<Zeus.Web.Model.WorkOrder>;
  private workOrderId: number;
  // If comming from Dashboard, then a WO-type filter is forced into the left panel
  private workOrderTypeFilter: number | null;
  // WO type selected (null if no WO selected), makes other types non selectable AND manages display of right panel
  private workOrderType: number | null;
  private subType: number | null;

  // Split workorders in multiple lists in order to not make sub-list react needlessly
  public inputPickingWOSelected: Array<Zeus.Web.Model.WorkOrder> = [];
  public inventoryWOSelected: Array<Zeus.Web.Model.WorkOrder> = [];
  public trayPickingWOSelected: Array<Zeus.Web.Model.WorkOrder> = [];

  constructor(router: Router, logger: CustomLogger, private taskQueue: TaskQueue, private bindingEngine: BindingEngine) {
    super(router, logger);
    this.woService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);

    this.disposables.push(
      this.bindingEngine.collectionObserver(this.workOrderSelected).subscribe(() => {
        if (this.workOrderSelected.length == 0) {
          this.workOrderType = null;
        } else {
          this.workOrderType = this.workOrderSelected[0].workOrderTypeId
        }

        if ((this.isModeInput || this.isModePicking) && !this.isTraypicking) {
          this.inputPickingWOSelected.splice(0, this.inputPickingWOSelected.length, ...this.workOrderSelected);
        } else {
          this.inputPickingWOSelected.splice(0);
        }
        if (this.isModeInventory) {
          this.inventoryWOSelected.splice(0, this.inventoryWOSelected.length, ...this.workOrderSelected);
        } else {
          this.inventoryWOSelected.splice(0);
        }
        if (this.isModePicking && this.isTraypicking) {
          this.trayPickingWOSelected.splice(0, this.trayPickingWOSelected.length, ...this.workOrderSelected);
        } else {
          this.trayPickingWOSelected.splice(0);
        }
      }),
    )
  }

  public async activate(params: { param1?: number, workOrderTypeId?: number, subTypeId?: number }) {
    if (params != null) {
      if (params.param1 != null) {
        this.workOrderId = params.param1;
      }

      if (params.workOrderTypeId != null) {
        this.workOrderTypeFilter = params.workOrderTypeId;
      }

      if (params.subTypeId != null) {
        this.subType = params.subTypeId;
      }
    }
  }

  public async attached() {
    // Needed because currently selected view for empty route is stored with an empty key.
    // Because empty route changed, the view bugs because the wrong viewmodel is loaded.
    if (this.router.currentInstruction.config.route === "") {
      let nonEmptyRoute = this.router.routes.find(r => r.moduleId == this.router.currentInstruction.config.moduleId && r.route != '');
      if (nonEmptyRoute) {
        this.router.currentInstruction.config.route = nonEmptyRoute.route;
      }
    }

    if (this.workOrderId != null) {
      let wo = await this.woService.getEntityById(this.workOrderId);
      this.taskQueue.queueTask(() => {
        this.workOrderSelected.push(wo);
      });
    }
  }

  @computedFrom('workOrderType')
  get isModeInput(): boolean {
    return this.workOrderType == Constants.WorkOrderType.Input;
  }
  @computedFrom('workOrderType')
  get isModePicking(): boolean {
    return this.workOrderType == Constants.WorkOrderType.Picking;
  }
  @computedFrom('workOrderType')
  get isModeInventory(): boolean {
    return this.workOrderType == Constants.WorkOrderType.Inventory;
  }
  @computedFrom('workOrderSelected.length')
  get isTraypicking(): boolean {
    return this.workOrderSelected[0]?.orderSubtype?.id == Constants.OrderSubtype.TrayPick;
  }
} 
