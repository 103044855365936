import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject, bindable, BindingEngine, computedFrom, customElement } from "aurelia-framework";
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, ServiceBase, Various } from "digiwall-lib";
import { DropToLight } from "../generated";
import { Zeus } from "../../../generated";
import * as CSConstants from '../../check-scanning/constants';
import * as DTLConstants from "../constants";
import * as Constants from "../../../constants";
import { FilterQueryOp, Predicate } from 'breeze-client';
import { isIPAddress } from 'ip-address-validator';
import { Utils } from 'utils/utils';
import { CheckScanning } from 'app-modules/check-scanning/generated';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import { Conveyor } from 'app-modules/conveyor/generated';
import * as ConvConstants from '../../conveyor/constants';

@autoinject
@customElement('workstation-detail-view')
export class WorkstationDetailView extends EntityDetailViewModelBase<DropToLight.Model.Workstation> {
  public ressourceName: string = DTLConstants.EntityTypeNames.Workstation;
  public DTLConstants = DTLConstants;

  @bindable private workstationId: number;
  @bindable private onCancel = () => { };
  @bindable private onSaved: (args: { workstation: DropToLight.Model.Workstation }) => Promise<void>;

  private isUnavailable: boolean;

  private pickingGroupService: ServiceBase<DropToLight.Model.WorkstationBayGroup>;
  private inputGroupService: ServiceBase<DropToLight.Model.WorkstationBayGroup>;
  private dTLControllerService: ServiceBase<DropToLight.Model.DTLController>;
  private dTLWorkstationPositionService: ServiceBase<DropToLight.Model.DTLWorkstationPosition>;
  private dTLTagService: ServiceBase<DropToLight.Model.DTLTag>;
  private dTLTagTypeService: ServiceBase<DropToLight.Model.DTLTagType>;
  private workstationPositionService: ServiceBase<DropToLight.Model.WorkstationPosition>;
  private workOrderPriorityService: ServiceBase<Zeus.Web.Model.WorkOrderPriority>;

  //#region CheckScanning
  private checkScanningConfigurationService: ServiceBase<CheckScanning.Model.CheckScanningConfiguration>;
  //#endregion

  private colorModeService: EnumerationTypeService;
  private displayModeService: EnumerationTypeService;

  @computedFrom('editingMode', 'entity.name', '_langWatcher')
  get documentTitle(): string {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr('menu.addnew') + ' ' + this.i18n.tr("workstation.workstation");
    }
    if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.name;
    }
  }

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private httpClient: HttpClient, private appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<DropToLight.Model.Workstation>(DTLConstants.EntityTypeNames.Workstation));
    this.pickingGroupService = new ServiceBase<DropToLight.Model.WorkstationBayGroup>(DTLConstants.EntityTypeNames.WorkstationBayGroup);
    this.inputGroupService = new ServiceBase<DropToLight.Model.WorkstationBayGroup>(DTLConstants.EntityTypeNames.WorkstationBayGroup);
    this.dTLControllerService = new ServiceBase<DropToLight.Model.DTLController>(DTLConstants.EntityTypeNames.DTLController);
    this.dTLWorkstationPositionService = new ServiceBase<DropToLight.Model.DTLWorkstationPosition>(DTLConstants.EntityTypeNames.DTLWorkstationPosition);
    this.dTLTagService = new ServiceBase<DropToLight.Model.DTLTag>(DTLConstants.EntityTypeNames.DTLTag);
    this.dTLTagTypeService = new ServiceBase<DropToLight.Model.DTLTagType>(DTLConstants.EntityTypeNames.DTLTagType);
    this.workOrderPriorityService = new ServiceBase<Zeus.Web.Model.WorkOrderPriority>(Constants.EntityTypeNames.WorkOrderPriority);
    this.workstationPositionService = new ServiceBase<DropToLight.Model.WorkstationPosition>(DTLConstants.EntityTypeNames.WorkstationPosition);

    this.colorModeService = new EnumerationTypeService(DTLConstants.EnumerationTypes.ColorMode);
    this.displayModeService = new EnumerationTypeService(DTLConstants.EnumerationTypes.DTLTagDisplayMode);
  }

  public async attached() {
    if (this.workstationId == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ active: true, withDTL: true });
      await this.withDTLChanged(true);
    } else if (this.workstationId > 0) {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(this.workstationId, 'colorMode', 'dTLTagDisplayMode', 'zone', 'inputGroup',
        'pickingGroup', 'pickingGroup', 'dTLControllers', 'conveyorConfiguration', 'exitStation',
        'workstationPositions.workOrderMinPriority', 'workstationPositions.workOrderMaxPriority', 'workstationPositions.workstationPositionColor',
        'workstationPositions.dTLWorkstationPositionSide1.dTLTag', 'workstationPositions.dTLWorkstationPositionSide2.dTLTag');
      await this.manageDTLControllers();
      this.dTLWorkstationPositionService.gridDataSource.expands = ["dTLTag"];
      this.dTLWorkstationPositionService.gridDataSource.customSelect2Predicates = () => {
        return Predicate.and(
          new Predicate("dTLController.workstationId", FilterQueryOp.Equals, this.entity.id),
          ...this.entity.workstationPositions.map(x => {
            let predicates: Predicate[] = [
              x.dTLWorkstationPositionSide1Id ? new Predicate("id", FilterQueryOp.NotEquals, x.dTLWorkstationPositionSide1Id) : null,
              x.dTLWorkstationPositionSide2Id ? new Predicate("id", FilterQueryOp.NotEquals, x.dTLWorkstationPositionSide2Id) : null
            ].filter(x => x != null);
            return predicates.length == 0 ? null : Predicate.and(predicates);
          }).filter(x => x != null)
        );
      };
      this.withDTLChanged(this.entity.withDTL);
    } else {
      throw `Incorrect id ${this.workstationId}`;
    }
    this.isUnavailable = !this.entity.active;
    this.pickingGroupService.gridDataSource.customSelect2Predicates = () => {
      return (new Predicate("zoneId", FilterQueryOp.Equals, this.entity.zoneId)).and(new Predicate("bayGroupTypeId", FilterQueryOp.Equals, DTLConstants.BayGroupTypeId.Picking));
    };
    this.inputGroupService.gridDataSource.customSelect2Predicates = () => {
      return (new Predicate("zoneId", FilterQueryOp.Equals, this.entity.zoneId)).and(new Predicate("bayGroupTypeId", FilterQueryOp.Equals, DTLConstants.BayGroupTypeId.Input));
    };
    await this.canReleasePickingOnDTLWithCheckScanning();
    this.conveyorAttached();
    this.controller.addObject(this.entity);
  }

  @computedFrom("entity.isMobileWorkstation", "entity.inputGroupId", "entity.pickingGroupId")
  public get isDoubleSided(): boolean {
    if (this.entity) {
      return this.entity.isMobileWorkstation || this.entity.inputGroup?.isDoubleSided || this.entity.pickingGroup?.isDoubleSided;
    }
    return false;
  }

  @computedFrom("entity.colorModeId", "entity.inputGroupId", "entity.pickingGroupId")
  public get getAlertLabel(): string {
    if (this.entity?.colorModeId == DTLConstants.ColorModeId.DynamicStorageColor) {
      if (this.entity.inputGroup != null && this.entity.pickingGroup != null && !this.entity.inputGroup.hasAllWorkstationGroupColors && !this.entity.pickingGroup.hasAllWorkstationGroupColors) {
        return this.i18n.tr('workstation.allColorsIsNotFilledForTwo', { name1: this.entity.inputGroup.name, name2: this.entity.pickingGroup.name });
      } else if (this.entity.inputGroup != null && !this.entity.inputGroup?.hasAllWorkstationGroupColors) {
        return this.i18n.tr('workstation.allColorsIsNotFilledForOne', { name: this.entity.inputGroup.name });
      } else if (this.entity.pickingGroup != null && !this.entity.pickingGroup.hasAllWorkstationGroupColors) {
        return this.i18n.tr('workstation.allColorsIsNotFilledForOne', { name: this.entity.pickingGroup.name });
      }
    }
    return null;
  }

  @computedFrom('entity.withDTL', 'entity.conveyorConfigurationId')
  private get canHaveInputGroup(): boolean {
    return !this.entity.withDTL || this.entity.conveyorConfigurationId != null;
  }

  private releasePickingOnDtlChanged(checked: boolean) {
    if (checked) {
      this.entity.lightUpOnCopilotConfirm = false;
    }
  }

  private unavailableChanged(checked: boolean) {
    this.entity.active = !checked;
  }

  //#region conveyor
  private isConveyorActive: boolean = false;
  private conveyorService: ServiceBase<Conveyor.Model.ConveyorConfiguration>;
  private exitStationService: ServiceBase<Conveyor.Model.ExitStation>;

  private conveyorAttached() {
    this.isConveyorActive = this.appModuleService.isActive(AppModuleEnum.Conveyor);
    if (this.isConveyorActive) {
      this.entity.withBinScanning = true;
      this.conveyorService = new ServiceBase<Conveyor.Model.ConveyorConfiguration>(ConvConstants.EntityTypeNames.ConveyorConfiguration);
      this.conveyorService.gridDataSource.customSelect2Predicates = () => new Predicate("warehouseId", FilterQueryOp.Equals, this.entity.zone?.parentStorageGroup?.parentStorageGroupId);
      this.exitStationService = new ServiceBase<Conveyor.Model.ExitStation>(ConvConstants.EntityTypeNames.ExitStation);
      this.exitStationService.gridDataSource.customSelect2Predicates = () => new Predicate("conveyorConfigurationId", FilterQueryOp.Equals, this.entity.conveyorConfigurationId);

      this.disposables.push(
        this.bindingEngine.propertyObserver(this.entity, "conveyorConfigurationId").subscribe((newVal, oldVal) => {
          if (newVal != oldVal) {
            this.entity.exitStationId = null;
          }
        }),
      );
    }
  }
  //#endregion

  //#region CheckScanning
  @computedFrom('entity.id')
  private get isCheckScanningActive() {
    return this.appModuleService.isActive(AppModuleEnum.CheckScanning);
  }

  private canReleasePickingOnDTL: boolean = true;
  private async canReleasePickingOnDTLWithCheckScanning() {
    if (this.isCheckScanningActive) {
      this.checkScanningConfigurationService ??= new ServiceBase<CheckScanning.Model.CheckScanningConfiguration>(CSConstants.EntityTypeNames.CheckScanningConfiguration);
      // When the workstation is configured under a zone for which the "check scanning" configuration has any “Picking” value other than "No Scan" ("Pick" checkscan <> "No Scan")
      if ((await this.checkScanningConfigurationService.getCount(
        new Predicate("storageGroupId", FilterQueryOp.Equals, this.entity.zoneId)
          .and(new Predicate("workOrderTypeId", FilterQueryOp.Equals, Constants.WorkOrderType.Picking))
          .and(new Predicate("methodId", FilterQueryOp.NotEquals, CSConstants.CheckScanningMethod.NoScan)))) > 0) {
        this.canReleasePickingOnDTL = false;
        this.entity.lightUpOnCopilotConfirm = true;
        return;
      }
    }
    this.canReleasePickingOnDTL = true;
  }
  //#endregion

  //#region DTLController
  private async withDTLChanged(checked: boolean) {
    if (checked) {
      this.entity.isMobileWorkstation = false;
      if (this.entity.colorModeId == null) {
        this.entity.colorMode = await this.colorModeService.getDefault();
      }
      if (this.entity.dTLTagDisplayModeId == null) {
        this.entity.dTLTagDisplayMode = await this.displayModeService.getDefault();
      }
      if (this.entity?.dTLControllers?.length == 0) {
        this.entity.dTLControllers.push(await this.dTLControllerService.createEntity({ workstationId: this.entity.id }));
      }
    }
  }

  private async isMobileWorkstationChanged(checked: boolean) {
    if (checked) {
      this.entity.loginRequired = true;
      this.entity.withBinScanning = true;
      this.entity.withDTL = false;
      this.entity.conveyorConfigurationId = null;
      this.entity.exitStationId = null;
      this.entity.showBinOnTag = false;
    }
  }

  private async manageDTLControllers() {
    if (this.entity.dTLControllers.length > 1) {
      this.entity.dTLControllers.forEach(x => {
        (x as any).panelCollapsed = true;
        (x as any).isInitialized = false;
        this.attachedDTLController(x);
      });
    } else if (this.entity.dTLControllers.length == 1) {
      await this.getDTLWorkstationPositionByController(this.entity.dTLControllers[0]);
      this.entity.dTLControllers.forEach(x => {
        (x as any).panelCollapsed = false;
        this.attachedDTLController(x);
      });
    }
    this.setIsCorrectControllers();
  }

  private async getDTLWorkstationPositionByController(dTLController: DropToLight.Model.DTLController) {
    await this.dTLWorkstationPositionService.getEntities(new Predicate("dTLControllerId", FilterQueryOp.Equals, dTLController.id), ["dTLTag.dTLTagType"]);
    (dTLController as any).isInitialized = true;
  }

  private attachedDTLController(dTLController: DropToLight.Model.DTLController) {
    this.disposables.push(
      this.bindingEngine.propertyObserver(dTLController, "panelCollapsed").subscribe(async (newVal, oldVal) => {
        if (newVal != oldVal && !newVal && !(dTLController as any).isInitialized) {
          await this.getDTLWorkstationPositionByController(dTLController);
        }
      }),
      this.bindingEngine.propertyObserver(dTLController, "tcpAddress").subscribe((newVal, oldVal) => {
        if (newVal != oldVal) {
          this.setIsCorrectControllers();
        }
      }),
      this.bindingEngine.propertyObserver(dTLController, "portNumber").subscribe((newVal, oldVal) => {
        if (newVal != oldVal) {
          this.setIsCorrectControllers();
        }
      })
    );
  }

  private isCorrectControllers: boolean = true;
  private async addDTLController() {
    let newController: DropToLight.Model.DTLController = await this.dTLControllerService.createEntity({ workstationId: this.entity.id });
    if (this.entity.dTLControllers.length > 1) {
      this.entity.dTLControllers.forEach(x => {
        (x as any).panelCollapsed = true;
      });
    }
    (newController as any).panelCollapsed = false;
    this.isCorrectControllers = false;
    this.attachedDTLController(newController);
  }

  private setIsCorrectControllers() {
    this.isCorrectControllers = this.entity.dTLControllers.every(x => x.tcpAddress != null && x.tcpAddress.trim() != '' && x.portNumber != null && x.portNumber > 0);
  }

  private async deleteDTLController(dtlController: DropToLight.Model.DTLController) {
    if (this.entity.dTLControllers.length == 1) {
      this.logError(this.i18n.tr("dtlcontroller.atLeastOneController"), null);
      return;
    }

    if (dtlController.entityAspect.entityState.isAdded()) {
      dtlController.entityAspect.setDetached();
    } else {
      await this.dTLControllerService.deleteEntities([dtlController], true);
    }
    if (this.entity.dTLControllers.length > 1) {
      this.entity.dTLControllers.forEach(x => {
        (x as any).panelCollapsed = true;
      });
    } else {
      this.entity.dTLControllers.forEach(x => {
        (x as any).panelCollapsed = false;
      });
    }
  }
  //#endregion

  //#region DTLWorkstationPosition
  private async addDTLWorkstationPosition(dtlController: DropToLight.Model.DTLController) {
    let newDTLWorkstationPosition: DropToLight.Model.DTLWorkstationPosition = await this.dTLWorkstationPositionService.createEntity({ dTLControllerId: dtlController.id });
    newDTLWorkstationPosition.dTLTag = await this.dTLTagService.createEntity();
  }

  private async deleteDTLWorkstationPosition(dTLWorkstationPosition: DropToLight.Model.DTLWorkstationPosition) {
    if (dTLWorkstationPosition.entityAspect.entityState.isAdded()) {
      dTLWorkstationPosition.dTLTag.entityAspect.setDetached();
      dTLWorkstationPosition.entityAspect.setDetached();
    } else {
      await this.dTLWorkstationPositionService.deleteEntities([dTLWorkstationPosition], true);
    }
  }
  //#endregion

  //#region WorkstationPosition
  private async addWorkstationPosition() {
    await this.workstationPositionService.createEntity({ workstationId: this.entity.id, order: this.entity.workstationPositions.length + 1, active: true });
  }

  private async deleteWorkstationPosition(workstationPosition: DropToLight.Model.WorkstationPosition) {
    if (workstationPosition.entityAspect.entityState.isAdded()) {
      workstationPosition.entityAspect.setDetached();
    } else {
      await this.workstationPositionService.deleteEntities([workstationPosition], true);
    }
  }
  //#endregion

  //#region Deactivation
  public detached(): void {
    this.controller.removeObject(this.entity);
    this.entity = null;
    this.disposables.forEach(d => d.dispose());
  }

  protected doNavigateBack(): void {
    // Unselect tree
    this.onCancel();
  }
  //#endregion Deactivation

  //#region Saving
  public override async beforeSave(): Promise<boolean | void> {
    if (await this.validateDtlController() && await this.validateWorkstationPosition()) {
      if (!this.canHaveInputGroup) {
        this.entity.inputGroupId = null;
      }
      return true;
    }
    return false;
  }

  public async saveDTLTag(dTLWorkstationPosition: DropToLight.Model.DTLWorkstationPosition) {
    if (!this.entity.entityAspect.entityState.isAdded() && dTLWorkstationPosition != null && !dTLWorkstationPosition.entityAspect.entityState.isDeleted()) {
      if (dTLWorkstationPosition.dTLTag.dTLTagTypeId != null && dTLWorkstationPosition.dTLTag.logicalID != null && !dTLWorkstationPosition.dTLTag.entityAspect.entityState.isUnchanged() && !dTLWorkstationPosition.entityAspect.entityState.isDeleted()) {
        let result = await this.httpClient.fetch(DTLConstants.Application.VerifyUniqueLogicalId + "?logicalID=" + dTLWorkstationPosition.dTLTag.logicalID + "&workstationId=" + this.entity.id)
        if (result.ok) {
          if (await result.json()) {
            await this.dTLTagService.saveEntity(dTLWorkstationPosition.dTLTag);
            if (dTLWorkstationPosition.dTLController.entityAspect.entityState.isAdded()) {
              await this.dTLControllerService.saveEntity(dTLWorkstationPosition.dTLController, true)
            }
            if (dTLWorkstationPosition.entityAspect.entityState.isAdded()) {
              await this.dTLWorkstationPositionService.saveEntity(dTLWorkstationPosition, true)
            }
          } else {
            this.logError("serverExceptions.uniqueLogicalId", null, true);
          }
        }
      }
    }
  }

  public async save(silentSave?: boolean, byPassLocking?: boolean, navigateBack?: boolean): Promise<any> {
    await super.save(silentSave, byPassLocking, navigateBack);
    if (this.onSaved) {
      await this.onSaved({ workstation: this.entity });
    }
  }
  protected changeRouteAfterCreation(): void {
    // Do nothing
  }

  private async validateDtlController() {
    if (!this.entity.withDTL) {
      this.entity.dTLControllers?.forEach(dtlController => {
        Utils.detachOrDeleteEntity(dtlController.workstationPositions);
        Utils.detachOrDeleteEntity(dtlController);
      });

      return true;
    }

    if (this.entity.dTLControllers.some(x => x.tcpAddress == null || x.tcpAddress.trim() == "")) {
      this.logError(this.i18n.tr("dtlcontroller.tcpAddressRequired"), null);
      return false;
    }

    if (this.entity.dTLControllers.some(x => !isIPAddress(x.tcpAddress))) {
      this.logError(this.i18n.tr("dtlcontroller.tcpAddressInvalid"), null);
      return false;
    }

    if (this.entity.dTLControllers.some(x => x.portNumber < 0)) {
      this.logError(this.i18n.tr("dtlcontroller.portNumberIncorrect"), null);
      return false;
    }

    if (this.isCreationMode && this.entity.dTLControllers.some(c => c.workstationPositions.some(wp =>
      this.entity.dTLControllers.some(c2 => c2.workstationPositions.some(wp2 => wp.id != wp2.id && wp.dTLTag.logicalID == wp2.dTLTag.logicalID))
    ))) {
      this.logError("serverExceptions.uniqueLogicalId", null);
      return false;
    }

    return true;
  }

  private async validateWorkstationPosition(): Promise<boolean> {
    let isValidate: boolean = this.validateWorkstationPositionId();
    isValidate = await this.validateWorkstationPositionPriority() && isValidate;
    isValidate = this.validateWorkstationPositionFixedColor() && isValidate;
    isValidate = this.validateWorkstationPositionEanBarCode() && isValidate;
    isValidate = this.validateWorkstationPositionLevelSlot() && isValidate;
    return isValidate;
  }

  private validateWorkstationPositionId(): boolean {
    if (Utils.arrayHasDuplicates(this.entity.workstationPositions.map(x => x.positionID))) {
      this.logError(this.i18n.tr("workstationposition.positionIdMustBeUnique"), null);
      return false;
    }

    if (this.entity.workstationPositions.some(x => x.positionID <= 0)) {
      this.logError(this.i18n.tr("workstationposition.positionIdMustBePositive"), null);
      return false;
    }

    return true;
  }

  private validateWorkstationPositionFixedColor(): boolean {
    const hasDuplicateColor =
      !this.entity.withDTL
      && !this.entity.isMobileWorkstation
      && this.entity.pickingGroupId != null
      && this.entity.workstationPositions
        .filter(x => x.workstationPositionColorId != null)
        .some(x => this.entity.workstationPositions
          .some(y => x.id != y.id && y.workstationPositionColorId == x.workstationPositionColorId));
    if (hasDuplicateColor) {
      this.logError(this.i18n.tr("workstationposition.positionColorMustBeUnique"), null);
      return false;
    }
    return true;
  }

  private validateWorkstationPositionEanBarCode(): boolean {
    const hasDuplicateColor = !this.entity.withDTL && this.entity.workstationPositions
      .filter(x => x.eANBarcode != null)
      .some(x => this.entity.workstationPositions
        .some(y => x.id != y.id && y.eANBarcode == x.eANBarcode));
    if (hasDuplicateColor) {
      this.logError(this.i18n.tr("workstationposition.eANBarcodeMustBeUnique"), null);
      return false;
    }
    return true;
  }

  private async validateWorkstationPositionPriority(): Promise<boolean> {
    if (this.entity.workstationPositions == null) {
      return true;
    }

    if (this.entity.workstationPositions.some(position => position.workOrderMaxPriorityId == null || position.workOrderMinPriorityId == null)) {
      this.logError(this.i18n.tr("workstationposition.workOrderMinMaxPriorityRequired"), null);
      return false;
    }

    if (this.entity.workstationPositions.some(position => position.workOrderMinPriority.priority > position.workOrderMaxPriority.priority)) {
      this.logError(this.i18n.tr("workstationposition.workOrderPriorityMinLtEqMax"), null);
      return false;
    }

    return true;
  }

  @computedFrom("entity.isMobileWorkstation")
  protected get softwareModuleMobileScreensEnabledAndMobileWorkstationIsChecked() {
    return this.entity?.isMobileWorkstation && this.appModuleService.isActive(AppModuleEnum.MobileScreens);
  }

  private validateWorkstationPositionLevelSlot(): boolean {
    if (!this.softwareModuleMobileScreensEnabledAndMobileWorkstationIsChecked ||
      !Array.isArray(this.entity?.workstationPositions)) {
      return true;
    }

    let valid = true;

    // The level and slot must be populated for every position
    if (this.entity.workstationPositions.some(x => x.level == null) ||
      this.entity.workstationPositions.some(x => x.slot == null)) {
      this.logError(this.i18n.tr("workstationposition.levelSlotAreRequired"), null);
      valid = false;
    }

    // The same slot number can not be used within the level
    let hasDuplicatedSlots = false;
    for (let level of Array.from(this.entity.workstationPositions.groupBy("level").entries()).map(e => e[1])) {
      if ((hasDuplicatedSlots = Utils.arrayHasDuplicates(level.map((x: DropToLight.Model.WorkstationPosition) => x.slot)))) {
        this.logError(this.i18n.tr("workstationposition.slotMustBeUniqueByLevel"), null);
      }
      valid = !hasDuplicatedSlots && valid;
      if (hasDuplicatedSlots) break;
    }

    return valid;
  }
  //#endregion 
}
