import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import { Predicate, FilterQueryOp } from 'breeze-client';

@autoinject
export class OrderTrackingDetail extends EntityDetailViewModelBase<Zeus.Web.Model.SentMessage> {

  public ressourceName: string = Constants.EntityTypeNames.SentMessage;

  private receivedService: ServiceBase<Zeus.Web.Model.ReceivedMessage>;
  private received: Zeus.Web.Model.ReceivedMessage;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.SentMessage>(Constants.EntityTypeNames.SentMessage));
    this.receivedService = new ServiceBase<Zeus.Web.Model.ReceivedMessage>(Constants.EntityTypeNames.ReceivedMessage);
  }

  public async activate(params) {
    const id = params.param1;
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id, ...['batchProcess.executionStatus', 'batchProcess.resultStatus', 'originalWorkOrder.workOrderType', 'originalWorkOrder.workOrderPriority', 'locationForAction.location', 'locationForAction.article', 'locationForAction.unitOfMeasure', 'orderStatus', 'workstationUser']);
    let received = await this.receivedService.getEntities(
      new Predicate('messageId', FilterQueryOp.Equals, this.entity.messageId),
      null, null, { propertyPaths: 'createdTime', isDescending: false }
    );
    if (received?.length > 0) {
      this.received = received[0];
    }
    this.controller.addObject(this.entity);
  }

  @computedFrom('documentTitle', '_langWatcher')
  get ribbonHeaderText(): string {
    return this.i18n.tr('ordertracking.orderTrackingDetail');
  }

  get documentTitle(): string {
    return '';
  }

  public stringifyObj(obj: any) {
    return JSON.stringify(JSON.parse(obj), null, 4);
  }

  @computedFrom('entity.originalWorkOrder.workOrderTypeId')
  get isInputType(): boolean {
    return this.entity.originalWorkOrder.workOrderTypeId == Constants.WorkOrderType.Input;
  }
}
