import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper, BooleanFilter, Actions, DialogBoxViewModel } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, camelCase, computedFrom } from 'aurelia-framework';
import { Conveyor } from "../generated";
import * as ConvConstants from "../constants";
import * as Constants from "../../../constants";
import { ZeusHubClient } from '../../../external-src/zeus-hub-client';
import { Application } from '../constants';
import { HttpClient } from 'aurelia-fetch-client';

@autoinject
export class BinOverview extends ListViewModelBase<Conveyor.Model.ConveyorBin> {
  public ressourceName: string = ConvConstants.Permissions.BinOverview;

  constructor(router: Router, logger: CustomLogger, private zeusHub: ZeusHubClient, private httpClient: HttpClient) {
    super(router, logger, new ServiceBase<Conveyor.Model.ConveyorBin>(ConvConstants.EntityTypeNames.ConveyorBin));
    this.service.gridDataSource.expands = ["conveyor", "workOrder.workOrderPriority", "workOrder.workOrderType", "workOrder.orderSubtype", "workstation", "workstationPosition"];
  }

  public async attached() {
    this.disposables.push(
      // Disable until we have a complete implementation  
      //this.zeusHub.onRefreshBinOverview(() => this.gridOptions?.api?.onFilterChanged())
    );
  }

  public async cancel() {
    if (this.selectedEntities.length > 1) {
      let result = await (await this.box.showQuestion(
        this.i18n.tr('conveyorbin.multipleBinsSelected'),
        this.i18n.tr('menu.del-filter-set-title'),
        [
          { label: this.i18n.tr('general.no'), theme: 'primary', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(false) },
          { label: this.i18n.tr('general.yes'), theme: 'warning', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(true) }
        ]
      )).closeResult;
      if (!result.output) {
        return;
      }
    }
    let result = await this.httpClient.post(Application.CancelBin, JSON.stringify(this.selectedEntities.map(x => x.batchNumber)));
    if (result.ok) {
      await this.box.showNotification(
        this.i18n.tr('conveyorbin.cancelAttempting'),
        this.i18n.tr('general.notificationTitle')
      );
    }
  }

  public async resendToPlc() {
    let result = await this.httpClient.post(Application.ResendToPlc, JSON.stringify(this.selectedEntities.map(x => x.binNumber)));
    if (result.ok) {
      await this.box.showNotification(
        this.i18n.tr('conveyorbin.resendInitiated'),
        this.i18n.tr('general.notificationTitle')
      );
    }
  }

  public async refreshData() {
    this.gridOptions?.api?.onFilterChanged();
  }

  async onGridReady(event?: any): Promise<void> {
    await super.onGridReady(event);
    let instance = this.gridOptions?.api?.getFilterInstance('archived') as BooleanFilter;
    if (instance == null) {
      return;
    }
    instance.setModel({ filterType: 'boolean', filter: 'false' });
  }

  public getDataGridColumns() {
    return [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("conveyorconfiguration.conveyorconfiguration"),
        field: "conveyor.name",
        type: FieldType.String,
        hide: true,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("conveyorbin.binNumber"),
        field: "binNumber",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("conveyorbin.action"),
        field: "action",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("conveyorbin.firstScanOnConveyor"),
        field: "firstScanOnConveyor",
        type: FieldType.DateTime,
        floatingFilterComponentParams: {
          dateFormat: 'DD/MM/YYYY, HH:mm:ss'
        },
      },
      {
        headerName: this.i18n.tr("conveyorbin.lastUpdateDate"),
        field: "lastUpdateDate",
        type: FieldType.DateTime,
        floatingFilterComponentParams: {
          dateFormat: 'DD/MM/YYYY, HH:mm:ss'
        },
      },
      {
        headerName: this.i18n.tr("conveyorbin.exitStation"),
        field: "exitStation",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("workorder.workOrderName"),
        field: "workOrder.workOrderName",
        type: FieldType.String,
        // Remove default onCellClickedBehaviour
        onCellClicked: () => { },
        valueGetter: (params: { data: Conveyor.Model.ConveyorBin }) => params.data?.workOrder?.workOrderName ?? params.data?.workOrder?.id,
        cellRenderer: (params: { data: Conveyor.Model.ConveyorBin }) => {
          if (params.data?.workOrder == null) {
            return '';
          }
          const name = params.data?.workOrder?.workOrderName ?? params.data?.workOrder?.id;
          return `<a href="/work-orders/${params.data?.workOrder.id}" title="${name}" class="ui-link">${name}</a>`
        }
      },
      {
        headerName: this.i18n.tr("workorder.workOrderPriorityId"),
        field: "workOrder.workOrderPriority.name._translation",
        cellRenderer: 'enumerationRenderer',
        type: FieldType.OneToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          serviceName: Constants.EntityTypeNames.WorkOrderPriority,
        }
      },
      {
        headerName: this.i18n.tr("workorder.workOrderTypeId"),
        field: "workOrder.workOrderType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.WorkOrderType,
        },
      },
      {
        headerName: this.i18n.tr("workorder.orderSubtypeId"),
        field: "workOrder.orderSubtype.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.OrderSubtype,
        },
      },
      {
        headerName: this.i18n.tr("conveyorbin.workstationId"),
        field: "workstation.name",
        type: FieldType.String,
        valueGetter: (params: { data: Conveyor.Model.ConveyorBin }) => params.data?.exitStation > 0 ? params.data.workstation?.name : null,
      },
      {
        headerName: this.i18n.tr("conveyorbin.workstationPositionId"),
        field: "workstationPosition.positionID",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("conveyorbin.archived"),
        field: "archived",
        type: FieldType.Boolean,
        hide: true,
      },
      {
        headerName: this.i18n.tr("conveyorbin.isPrepicked"),
        field: "isPrepicked",
        type: FieldType.Boolean,
        hide: true,
      },
    ];
  }
} 
