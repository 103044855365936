export enum StorageTypes {
  Static = 31
}

export enum EnumerationTypes {
  BinValidationType = 45,
  StaticStoragePickValidationType = 46,
  StaticStorageInventoryValidationType = 48,
}

export enum EnumBinValidationType {
  TapBinOnMobileScreen = 451,
  ScanBinNumber = 452
}

export enum EnumStaticStoragePickValidationType {
  ScanLocation = 461,
  ScanLocationOrArticle = 462,
  ScanArticle = 463
}

export enum EnumStaticStorageInventoryValidationType {
  ScanArticle = 481,
  ConfirmationButton = 482,
}

export class EntityTypeNames {
  public static StaticRackLocation = "StaticRackLocation";
  public static MobileParameters = "MobileParameters";
  public static InventoryWorkOrderStaticRows = "InventoryWorkOrderStaticRows";
  public static InventoryWorkOrderStaticBays = "InventoryWorkOrderStaticBays";
  public static ReplenishmentNeed = "ReplenishmentNeed";

  public static ResourceNames = {
    StaticRackLocation: "StaticRackLocations",
    MobileParameters: "MobileParameters",
    InventoryWorkOrderStaticRows: "InventoryWorkOrderStaticRows",
    InventoryWorkOrderStaticBays: "InventoryWorkOrderStaticBays",
    ReplenishmentNeed: "ReplenishmentNeeds",
  }
}

export enum EnumLocationLevelLevels {
  StaticStorageName = 1,
  Row = 2,
  Bay = 3,
  Level = 4,
  Position = 5,
  Compartment = 6,
}

export class PermissionContexts {
  public static ReplenishmentNeeds = 'replenishmentNeeds';
  public static Replenish = 'replenish';
}

export enum SortingLocation {
  PrioritiseArticleBulkVolume = 159
}

export enum InventoryTarget {
  StaticRows = 215,
  StaticBays
}

export enum WorkOrderType {
  Replenishment = 114,
}