import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { Endpoints } from '../endpoints';
import { MobileScreens } from '../generated';
import { Zeus } from 'generated';

@autoinject()
export class ReplenishmentApiService {
  constructor(private httpClient: HttpClient) { }

  public async createReplenishmentWorkOrders(body: Array<MobileScreens.Model.WorkOrderReplenishmentInputDTO>): Promise<Array<Zeus.Web.Model.WorkOrder> | null> {
    try {
      let response = await this.httpClient.post(Endpoints.Replenishment.CreateWorkorders, JSON.stringify(body))
      if (response?.ok) {
        return await response.json();
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
