export class InventoryStaticUtils {
  public static rangeToList(range: string): string[] {
    const [start, end] = range.split("-");
    if (!start || !end) {
      throw new Error("Invalid range format. Use 'X-Y'.");
    }
    if (!isNaN(start) && !isNaN(end)) {
      // Numeric range
      const min = parseInt(start, 10);
      const max = parseInt(end, 10);
      if (min > max) throw new Error("Start of range must be less than or equal to the end.");
      return Array.from({ length: max - min + 1 }, (_, i) => (min + i).toString());
    } else if (start.length === 1 && end.length === 1 && /^[a-zA-Z]$/.test(start) && /^[a-zA-Z]$/.test(end)) {
      // Alphabetic range
      const min = start.charCodeAt(0);
      const max = end.charCodeAt(0);
      if (min > max) throw new Error("Start of range must be less than or equal to the end.");
      return Array.from({ length: max - min + 1 }, (_, i) => String.fromCharCode(min + i));
    } else {
      throw new Error("Range must be either numeric or alphabetic single characters.");
    }
  }
}
