import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, EnumerationTypeService, Various, validateEnumerationType, DialogBoxViewModel, EnumerationTypeColor, EnumerationTypeColorRessourceName } from 'digiwall-lib';
import { Zeus } from "../generated";
import { BindingEngine, autoinject, computedFrom, observable } from 'aurelia-framework';
import * as Constants from '../constants';
import { Endpoints } from 'endpoints';
import { ZeusUtilsMlFieldHelper } from 'utils/utils-ml-field-helper';
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';

@autoinject
export class StorageVolumeDetail extends EntityDetailViewModelBase<Zeus.Web.Model.StorageVolume> {
  public ressourceName: string = Constants.EntityTypeNames.EnumerationType;

  private storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  private overflowStorageVolumeService: ServiceBase<Zeus.Web.Model.OverflowStorageVolume>;
  private volumeTypeService: EnumerationTypeService;
  private enumerationTypeColorService: ServiceBase<EnumerationTypeColor>;
  private selectedLanguageCode: string;

  @observable
  private enumerationTypeColor: DataFormat;

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private bindingEngine: BindingEngine, private appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume));

    this.overflowStorageVolumeService = new ServiceBase<Zeus.Web.Model.OverflowStorageVolume>(Constants.EntityTypeNames.OverflowStorageVolume);
    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ['volumeType'];

    this.volumeTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageVolumeType);
    this.enumerationTypeColorService = new ServiceBase<EnumerationTypeColor>(EnumerationTypeColorRessourceName);
  }

  @computedFrom('entity.isEditable')
  protected get isClosedEntry(): boolean {
    return !this.entity?.volumeType?.isEditable;
  }

  @computedFrom('entity.id')
  private get isMobileScreensActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.MobileScreens);
  }

  public async activate(params) {
    let id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.volumeType = await this.volumeTypeService.createEntity({ isEditable: true });
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'volumeType', 'overflowStorageVolumes.storageVolumeForOverflow.volumeType');
    }
    this.controller.addObject(this.entity);
    this.controller.addObject(this.entity.volumeType);
    this.storageVolumeService.gridDataSource.customSelect2Predicates = () => {
      const predicate = new Predicate('id', FilterQueryOp.NotEquals, this.entity.id);
      if (this.entity.overflowStorageVolumes?.filter(x => x.storageVolumeForOverflowId != null)?.length > 0) {
        return Predicate.and(predicate, ...this.entity.overflowStorageVolumes
          .filter(x => x.storageVolumeForOverflowId != null)
          .map(x => new Predicate('id', FilterQueryOp.NotEquals, x.storageVolumeForOverflowId)));
      }
      return predicate;
    };

    validateEnumerationType(this.entity.volumeType, true);
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, 'enumerationTypeColor').subscribe(async newValue => {
        if (newValue == null || isNaN(newValue) || this.entity?.volumeType == null) {
          return;
        }
        let enumerationTypeColor = await this.enumerationTypeColorService.firstEntity(new Predicate("id", FilterQueryOp.Equals, newValue));
        this.entity.volumeType.textColor = enumerationTypeColor.textColor;
        this.entity.volumeType.backgroundColor = enumerationTypeColor.backgroundColor;
      })
    )
  }

  public get tagLabel(): string {
    if (this.selectedLanguageCode) {
      let language = this.config.globalConfig.configuredLanguages?.find(l => l.code == this.selectedLanguageCode);
      if (language) {
        return this.entity.volumeType.denomination['lang' + language.columnIndex];
      }
    }
    return '';
  }

  @computedFrom('editingMode', 'entity.volumeType.denomination', '_langWatcher')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("storagevolume.addStorageVolume");
    }
    else {
      if (this.entity) {
        return this.entity?.volumeType?.denomination?._translation;
      }
    }
  }

  private updateLocationDimensions = false;
  protected override async beforeSave() {
    let isValid: boolean = await ZeusUtilsMlFieldHelper.BeforeSave(this.entity, [
      { prop: this.entity.volumeType.denomination, label: "enumerationtype.denomination", isRequired: true }
    ]);

    if (this.entity.overflowStorageVolumes.some(x => x.multiplier < 1)) {
      this.logError(this.i18n.tr('storagevolume.error.multiplierTooLow'), null);
      isValid = false;
    }

    if (this.hasDuplicates(this.entity.overflowStorageVolumes.map(x => x.storageVolumeForOverflowId))) {
      this.logError(this.i18n.tr('storagevolume.error.overflowVolumesDuplicate'), null);
      isValid = false;
    }

    if (!isValid) {
      return false;
    }

    let changedEntityValues = (this.entity?.entityAspect?.originalValues as Zeus.Web.Model.StorageVolume);
    if (changedEntityValues.depth != null || changedEntityValues.width != null || changedEntityValues.height != null) {
      // Size has changed
      this.updateLocationDimensions = await this.box.showQuestion(this.i18n.tr('storagevolume.sizeChanged.message'),
        this.i18n.tr('storagevolume.sizeChanged.title'),
        [
          { label: this.i18n.tr('general.no'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(false) },
          { label: this.i18n.tr('general.yes'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(true) }
        ]
      ).whenClosed(result => result.output);
    }

    return true;
  }

  protected hasDuplicates(array: any[]): boolean {
    return new Set(array).size !== array.length;
  }

  protected override async afterSave() {
    if (this.updateLocationDimensions) {
      this.updateLocationDimensions = false;
      await this.httpClient.post(Endpoints.Location.UpdateLocationDimensions + '?storageVolumeId=' + this.entity.id);
    }
  }

  //#region Overflow Storage Volume
  public async addOverflowStorageVolume() {
    this.entity.overflowStorageVolumes.push(await this.overflowStorageVolumeService.createEntity({ storageVolumeId: this.entity.id }));
  }

  public async removeOverflowStorageVolume(overflowStorageVolume: Zeus.Web.Model.OverflowStorageVolume) {
    await this.overflowStorageVolumeService.deleteEntities([overflowStorageVolume], true);
  }
  //#endregion
}
