import { customElement } from "aurelia-templating";
import { Container, TaskQueue } from "aurelia-framework";
import { ServiceBase } from "digiwall-lib";
import * as MSConstants from "../../constants";
import { InventoryBaseActions } from '../../../../work-orders/article-to-actions/inventory/inventory-base-actions';
import { InventoryBaseFloatingBox } from "work-orders/floating-box/inventory/inventory-base-floating-box";
import { MobileScreens } from "app-modules/mobile-screens/generated";
import { InventoryStaticRowsFloatingBox } from "../floating-box/inventory-static-rows-floating-box";

@customElement('inventory-static-rows')
export class InventoryStaticRows extends InventoryBaseActions<MobileScreens.Model.InventoryWorkOrderStaticRows> {
  constructor() {
    super(new ServiceBase<MobileScreens.Model.InventoryWorkOrderStaticRows>(MSConstants.EntityTypeNames.InventoryWorkOrderStaticRows));
    let taskQueue = Container.instance.get(TaskQueue);
    this.disposables.push(
      this.bindingEngine.expressionObserver(this, 'workOrder.limitedToStorageGroupId').subscribe(() => {
        // Refresh calculation of article links location counts
        if (this.workOrder.inventoryLinks?.length > 0) {
          this.workOrder.inventoryLinks.forEach(x => this.setNumberOfLocations(x as MobileScreens.Model.InventoryWorkOrderStaticRows));
        }
      })
    );
  }

  protected async editLink(entity: MobileScreens.Model.InventoryWorkOrderStaticRows) {
    if (this.workOrder.id <= 0) {
      let saveOk = await this.saveWorkOrder();
      if (saveOk == false) {
        return;
      }
    }
    this.box.showFloatingBox(new InventoryStaticRowsFloatingBox(this.workOrder, entity)).whenClosed(async response => {
      if (false == response.wasCancelled && response.output != null) {
        const entity = await this.service.getEntityById(response.output.id, ...this.getExpands(true));
        this.setNumberOfLocations(entity);
      }
    });
  }

  protected getExpands(withLocations: boolean = false): string[] {
    let expands = ['staticStorage.locationFormat.locationLevels'];
    if (withLocations) {
      expands.push('staticStorage.locations');
    }
    return expands;
  }

  protected setNumberOfLocations(entity: MobileScreens.Model.InventoryWorkOrderStaticRows): MobileScreens.Model.InventoryWorkOrderStaticRows {
    if (entity.staticStorage?.locations?.length > 0) {
      (entity as any).nbLocationSelected = entity.staticStorage.locations
        .filter((x: MobileScreens.Model.StaticRackLocation) => x.row >= entity.firstRow)
        .filter((x: MobileScreens.Model.StaticRackLocation) => x.row <= entity.lastRow)
        .length;
    }
    return entity;
  }
  protected getFloatingBoxInstance(): InventoryBaseFloatingBox<MobileScreens.Model.InventoryWorkOrderStaticRows> {
    return new InventoryStaticRowsFloatingBox(this.workOrder);
  }
}
