import { WorkOrderLinesToProcess } from '../../work-order-lines-to-process/work-order-lines-to-process';
import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, UIInternal, Various, DialogBoxViewModel, GridHelper, GlobalLoaderService } from 'digiwall-lib';
import { Router, activationStrategy } from 'aurelia-router';
import { autoinject, bindable, BindingEngine, computedFrom, Container } from 'aurelia-framework';
import { Zeus } from "../../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { DialogService } from 'aurelia-dialog';
import { ApiService } from 'external-src/api.service';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { WorkOrderUtils } from 'utils/work-order-utils';
import { UnloadHelper } from 'utils/unload-helper';
import { LocationForActionService } from 'external-src/utils/location-for-action-service';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import * as ConvConstants from 'app-modules/conveyor/constants';
import { LaunchInBinFloatingBox } from 'app-modules/conveyor/launch-in-bin/launch-in-bin-floating-box';
import { HttpClient } from 'aurelia-fetch-client';

@autoinject
export class ArticleToActionList extends ListViewModelBase<Zeus.Web.Model.ArticleToAction> {
  public ressourceName: string = Constants.EntityTypeNames.ArticleToAction;

  @bindable public workOrderSelected: Array<Zeus.Web.Model.WorkOrder>;
  @bindable public workOrderType: number | null;

  private isOfTypeInput: boolean = true;
  private useAutoFindLocations: boolean = false;

  private allSelected: boolean = true;

  public paginationChange: boolean = false;
  public randomNumbersNode: Array<number> = [];

  public workOrderService: ServiceBase<Zeus.Web.Model.WorkOrder>;
  public lfipService: ServiceBase<Zeus.Web.Model.LocationForInputPicking>;
  public trayContainerService: ServiceBase<Zeus.Web.Model.TrayContainer>;
  public isFindingLocations = false;
  public isReleasingLocations = false;
  public isLaunchingMessage = false;

  private getLocationForInputPickingFromListInProgress: Map<number, boolean> = new Map();
  private selectedToBatch: number[];

  @computedFrom('isOfTypeInput')
  public get title(): string {
    return this.i18n.tr('workordertoprocess.articleTo' + (this.isOfTypeInput ? 'Input' : 'Pick') + 's');
  }

  @computedFrom(...['isFindingLocations', 'isReleasingLocations', 'isLaunchingMessage', 'autoFindLocationsBusyCounter'])
  public get isBusy() {
    return this.isFindingLocations || this.isReleasingLocations || this.isLaunchingMessage || this.autoFindLocationsBusyCounter > 0;
  }

  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private bindingEngine: BindingEngine,
    private dialogService: DialogService, private apiService: ApiService, private globalLoaderService: GlobalLoaderService,
    private appModuleService: AppModuleService, private httpClient: HttpClient) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.ArticleToAction>(Constants.EntityTypeNames.ArticleToAction));

    this.service.gridDataSource.expands = [
      'article.articleVolumeConfigs',
      'limitedToStorageGroup',
      'actionWorkOrder.workOrderPriority',
      'locationForInputPickings.location.trayContainer',
      'locationForInputPickings.location.storage',
      'articleStorageGroupForParameter.inputAlgorithm',
      'unitOfMeasure'
    ];

    this.workOrderService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.lfipService = new ServiceBase<Zeus.Web.Model.LocationForInputPicking>(Constants.EntityTypeNames.LocationForInputPicking);
    this.trayContainerService = new ServiceBase<Zeus.Web.Model.TrayContainer>(Constants.EntityTypeNames.TrayContainer);
  }

  public determineActivationStrategy() {
    return activationStrategy.replace; //replace the viewmodel with a new instance
  }

  public afterResetFilters() {
    this.agGridSortingHelper.applyDefaultSortModel();
  }

  private applyIsSelected(isChanged: boolean = false) {
    let doAutoFindLocations = false;
    this.gridOptions?.api?.forEachNode(x => {
      if (x.data != null && (isChanged || !x.data.isSelectedApplied || x.data.locationForInputPickings?.length == 0)) {
        x.data.isSelectedApplied = true;
        x.data.isSelected = this.allSelected;
        doAutoFindLocations ||= this.allSelected;
      }
    });
    // Auto find locations if we set selection to TRUE for at least one element
    if (doAutoFindLocations) {
      this.autoFindLocations();
    }
  }

  public initializeGridOptions(fixedFilters: boolean = false, hasHyperlinkColumn: boolean | null = null) {
    super.initializeGridOptions(fixedFilters, hasHyperlinkColumn);
    this.gridOptions.onModelUpdated = () => {
      this.applyIsSelected();
    };
  }

  public async attached() {
    this.selectAll = true;
    // Business Rule :: Because locationsForInputPickings must not be validated at this time, we must to delete them when we leave this page.
    this.disposables.push(Container.instance.get(UnloadHelper).subscribe(() => this.deleteLocationForInputPickings()));

    let appParamsService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters);
    this.useAutoFindLocations = (await appParamsService.firstEntity()).useAutoFindLocations;

    UIInternal.subscribe(Constants.WorkOrderProcessEvents.ReloadArticleToActions, () => {
      this.workOrderSelectedChanged();
    });

    this.disposables.push(
      this.bindingEngine.collectionObserver(this.workOrderSelected).subscribe(() => this.workOrderSelectedChanged())
    );
    this.workOrderSelectedChanged();
  }

  public async articleToActionSelected(): Promise<Array<Zeus.Web.Model.ArticleToAction>> {
    if (this.allSelected) {
      if (this.service.gridDataSource.queryParameters?.workOrderIds?.length > 0) {
        let ignoreIds: number[] = [];
        this.gridOptions?.api?.forEachNode(x => {
          if (x.data != null && !x.data.isSelected) {
            ignoreIds.push(x.data.id);
          }
        });
        const predicates = ignoreIds.map(x => new Predicate("id", FilterQueryOp.NotEquals, x));
        let predicate = Predicate.and(this.service.gridDataSource.predicates ?? Predicate.and(), ...predicates);
        return await this.service.getEntities(predicate, null, this.service.gridDataSource.queryParameters);
      }
      // race condition: entities are fetched before the selection updated the gridDataSource queryParams with the correct WOIds
      return [];
    }

    let atas = [];
    this.gridOptions?.api?.forEachNode(x => {
      if (x.data != null && x.data.isSelected) {
        atas.push(x.data);
      }
    });
    return atas;
  }

  selectAllChanged(newValue: boolean, oldValue: boolean) {
    // Ignore base implementation
  }

  protected selectAllOnChange(params: { checked: boolean }) {
    this.allSelected = params.checked;
    this.applyIsSelected(true);
  }

  //#region cancel ATA
  @computedFrom('articleToActionSelected')
  public get articleslinesNotCancellable() {
    return (this.articleToActionSelected()).then(x => x.some(ata => ata.hasBeenCancelled || ata.requestedActionQuantity == ata.reallyActionQuantity));
  }

  public async cancelArticleToAction() {
    if (!(await this.cancelArticleToActionConfirmed())) {
      return;
    }

    let articleToActions = await this.articleToActionSelected();
    for (const ata of articleToActions) {
      ata.hasBeenCancelled = true;
    }
    await this.service.saveEntities(articleToActions);

    const workOrderIdsToKeep = [];
    this.gridOptions.api.forEachNode(x => {
      if (x.data != null && !articleToActions.some(ata => ata.id == x.data.id)) {
        workOrderIdsToKeep.push(x.data.actionWorkOrderId)
      }
    });

    debugger;
    UIInternal.broadcast(Constants.WorkOrderProcessEvents.BatchProcessSent, { workOrderIdsToKeep });
  }

  private async cancelArticleToActionConfirmed(): Promise<boolean> {
    const atas = await this.articleToActionSelected();
    if (false == await Container.instance.get(LocationForActionService).canDeleteOrCancelArticleToAction(atas)) {
      return false;
    }
    let concernsManuallyBlockedLoc = atas.some(ata => ata.locationForInputPickings.some(lfp => lfp.manuallyBlocked));

    return await this.box.showQuestion(
      this.i18n.tr("articletoinput." + (concernsManuallyBlockedLoc ? "atasManuallyBlockedLines" : "confirmCancellationAtas")), this.i18n.tr("menu.del-filter-set-title"),
      [
        { label: this.i18n.tr('general.no'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.cancel() },
        { label: this.i18n.tr('general.yes'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok() }
      ]
    ).whenClosed(result => !result.wasCancelled);
  }
  //#endregion

  private workOrderSelectedChanged() {
    // On workorder selection change, set the type of the service
    this.isOfTypeInput = this.workOrderType == Constants.WorkOrderType.Input ||
      (this.workOrderSelected?.length > 0 && this.workOrderSelected[0].workOrderTypeId == Constants.WorkOrderType.Input);

    this.service.gridDataSource.queryParameters = { workOrderIds: this.workOrderSelected.map(x => x.id), byWorkOrderPriority: true, forWOToProcess: true };
    this.gridOptions.api?.setDatasource(this.service.gridDataSource);
    this.refreshColor();
  }

  public override async detached() {
    // Business Rule :: Because locationsForInputs must not be validated at this time, we must to delete them when we leave this page.
    await this.deleteLocationForInputPickings();

    this.disposables?.forEach((disposable) => disposable.dispose());
    super.detached();
  }

  private async deleteLocationForInputPickings(): Promise<void> {
    let articleToActionToKeep = this.isLaunchingMessage ? this.selectedToBatch : null;

    let woIds = this.workOrderSelected.length == 0
      ? this.service.gridDataSource.queryParameters.workOrderIds
      : this.workOrderSelected.map(x => x.id);

    await this.apiService.unloadWorkOrders(woIds, articleToActionToKeep);

    let articleToActionsToReset: Zeus.Web.Model.ArticleToAction[] =
      [].concat(...this.workOrderSelected.map(x => x.articleToActions))
        .filter(ata => null == (articleToActionToKeep || []).find(id => id == ata.id));

    this.apiService.resetArticleToActionsLocalPropertiesHelper(articleToActionsToReset);
  }

  public refreshColor() {
    this.gridOptions.getRowClass = this.rowClass;
    // Avoid multiple draws
    setTimeout(() => this.gridOptions.api?.redrawRows(), 0);
  }

  public async launchMessage() {
    if (this.isBusy) return;
    const atas = await this.articleToActionSelected();

    //Release line not selected
    const atasToRelease = this.workOrderSelected.flatMap(x => x.articleToActions.filter(y => !atas.some(ata => ata.id == y.id)));
    await this.releaseArticleToActions(atasToRelease);

    this.isLaunchingMessage = true;
    await WorkOrderUtils.launchArticleToActions(atas.map(x => x.id), null, () => this.afterLaunchMessage());
    this.isLaunchingMessage = false;
  }

  private async afterLaunchMessage() {
    const atas = await this.articleToActionSelected();
    // Notify BatchProcessSent with WO ids to keep (if any) depending on not processed lines
    UIInternal.broadcast(Constants.WorkOrderProcessEvents.BatchProcessSent, {
      workOrderIdsToKeep:
        atas
          .filter(ati => !this.selectedToBatch?.includes(ati.id))
          .map(ati => ati.actionWorkOrderId)
    });
  }

  private autoFindLocationsBusyCounter = 0;
  private async autoFindLocations(atas: Zeus.Web.Model.ArticleToAction[] = null) {
    if (this.useAutoFindLocations && !this.isBusy) {
      this.autoFindLocationsBusyCounter++;
      this.globalLoaderService.show(this.i18n.tr('workordertoprocess.locationLoading'));
      try {
        atas ??= await this.articleToActionSelected();
        let list = atas.filter(x => WorkOrderUtils.requestedQuantityIsFilled(x));
        if (list.length > 0) {
          await this.getLocationForInputPickingFromList(list);
        }
      } finally {
        this.autoFindLocationsBusyCounter--;
        this.globalLoaderService.hide();
      }
    }
  }

  public async findLocations() {
    if (this.isBusy) return;
    this.globalLoaderService.show(this.i18n.tr('workordertoprocess.locationLoading'));
    this.isFindingLocations = true;
    await this.getLocationForInputPickingFromList(await this.articleToActionSelected());
    this.isFindingLocations = false;
    this.globalLoaderService.hide();
  }

  private async getLocationForInputPickingFromList(articleToActions: Zeus.Web.Model.ArticleToAction[]) {
    let result = await WorkOrderUtils.getLocationForInputPickingFromList(articleToActions, this.apiService, this.workOrderService, this.getLocationForInputPickingFromListInProgress);
    if (result?.length > 0) {
      this.gridOptions?.api?.forEachNode((node) => {
        if (node.data?.article?.articleVolumeConfigs?.length == 0) {
          node.data.tooltip = this.i18n.tr("workordertoprocess.errorMessage.noVolumeConfig");
        } else {
          let resultArticleToAction = result.find(x => x.ArticleToActionId == node?.data?.id);
          if (resultArticleToAction != null) {
            node.data.tooltip = this.i18n.tr(resultArticleToAction.ErrorMessage, { articleReference: (node.data?.article as Zeus.Web.Model.Article).reference });
          }
        }
      });
    }
    this.refreshColor();
  }

  private rowClass = (params: { data: Zeus.Web.Model.ArticleToAction; }) => {
    if (params.data == null) {
      return null;
    }

    let articleToAction = params.data;
    if (articleToAction != null) {
      let qtyInStockCount = articleToAction.article.unitOfMeasures.find(x => x.unitOfMeasureId == articleToAction.unitOfMeasureId).quantityInStockCountUOM;
      let totalQuantity = articleToAction.locationForInputPickings
        .filter(lfip =>
          !lfip.msgHasBeenSent
          && lfip.requestedActionQuantity != null
          && !(lfip.location?.storage?.isUnavailable || lfip.location?.trayContainer?.isUnavailable)
        ).reduce((total, lfip) => {
          let lfipQtyInStockCount = lfip.article.unitOfMeasures.find(x => x.unitOfMeasureId == lfip.unitOfMeasureId).quantityInStockCountUOM;
          total += (lfip.requestedActionQuantity * (lfipQtyInStockCount / qtyInStockCount));
          return total;
        }, articleToAction.reallyActionQuantity || 0);

      articleToAction.qtyNotInlocation = articleToAction.requestedActionQuantity - totalQuantity;
      articleToAction.locatedAll = articleToAction.qtyNotInlocation == 0;

      if (articleToAction.locatedAll || articleToAction.allreadySearchForLocations || articleToAction.locationForInputPickings.filter(lfi => !lfi.msgHasBeenSent && lfi.manuallyBlocked).length > 0) {
        if (totalQuantity == 0) {
          return "wo-line wo-line-red";
        }
        if (articleToAction.qtyNotInlocation > 0) {
          return "wo-line wo-line-orange";
        }
        return "wo-line wo-line-green";
      }
    }
    return "wo-line wo-line-white";
  }

  public async release() {
    if (this.isBusy) return;
    this.releaseArticleToActions(await this.articleToActionSelected());
  }

  public async releaseArticleToActions(atas: Zeus.Web.Model.ArticleToAction[]) {
    this.isReleasingLocations = true;

    if (atas?.length) {
      await this.apiService.deleteArticleToActionsLocationForInputPickings(atas, false);
      await this.service.getEntities(Predicate.or(atas.map(ata => new Predicate('id', FilterQueryOp.Equals, ata.id))), ["locationForInputPickings.location"]);
      await this.apiService.resetArticleToActionsLocalProperties(atas);
    }

    this.refreshColor();
    this.isReleasingLocations = false;
  }

  public setArticleToInputSelected(event) {
    this.refreshColor();
    this.autoFindLocations([event.data]);
  }

  public async getWorkOrderLine(entity: Zeus.Web.Model.ArticleToAction) {
    await this.dialogService.open({
      viewModel: WorkOrderLinesToProcess,
      model: { articleToActionId: entity.id }, lock: true, keyboard: true
    }).whenClosed(async () => {
      let traysToFetchPredicate = entity.locationForInputPickings?.filter(lfip => lfip.location.trayContainer == null)
        .map(lfip => new Predicate('id', FilterQueryOp.Equals, lfip.location.trayContainerId))
        || [];
      await this.trayContainerService.getEntities(Predicate.or(traysToFetchPredicate));
      this.refreshColor();
      this.gridOptions.api.redrawRows();
    });
  }

  public onCellClicked(entity: Zeus.Web.Model.ArticleToAction): boolean {
    this.getWorkOrderLine(entity);
    return false;
  }

  public getDataGridColumns() {
    let tooltipValueGetter = params => params?.data?.tooltip ?? '';
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this, {
        onCellClicked: (event) => { this.setArticleToInputSelected(event) },
      }),
      {
        headerName: this.i18n.tr("workorder.orders"),
        field: "actionWorkOrderId",
        sortable: false,
        hide: true,
        type: FieldType.Number,
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("workorder.workOrderName"),
        field: "actionWorkOrder.workOrderName",
        sortable: false,
        type: FieldType.String,
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("articletoinput.lineNumber"),
        field: "lineNumber",
        sortable: false,
        type: FieldType.Number,
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("articletoinput.articleId"),
        field: "article.reference",
        sortable: false,
        type: FieldType.String,
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("article.description"),
        field: "article.description._translation",
        sortable: false,
        type: FieldType.String,
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("articletoinput.lotNumber"),
        field: "lotNumber",
        hide: true,
        sortable: false,
        type: FieldType.String,
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("articletoinput.requestedInputQuantity"),
        field: "requestedActionQuantity",
        sortable: false,
        type: FieldType.Number,
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("articletoinput.reallyInputedPickedQuantity"),
        field: "reallyActionQuantity",
        sortable: false,
        type: FieldType.Number,
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("articletoinput.unitOfMeasureId"),
        field: "unitOfMeasure.denomination._translation",
        sortable: false,
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.UnitOfMeasure,
        },
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("articletoinput.nbrLocations"),
        valueGetter: (params) => {
          if (params?.data?.locationForInputPickings?.length) {
            let unsentLfip = (<Zeus.Web.Model.ArticleToAction>params.data).locationForInputPickings.filter(lfi => !lfi.msgHasBeenSent);
            let unavailable = unsentLfip.filter(x => x.location.storage?.isUnavailable || x.location.trayContainer?.isUnavailable);
            if (unavailable.length > 0) {
              return `${unsentLfip.length - unavailable.length} (${unavailable.length} ${this.i18n.tr("locationforinput.unavailable")})`;
            } else {
              return unsentLfip.length;
            }
          }
          return 0;
        },
        field: "locationForInputPickings.length",
        sortable: true,
        type: FieldType.Number,
        tooltipValueGetter: tooltipValueGetter,
      },
      {
        headerName: this.i18n.tr("articletoinput.limitedToStorageGroupId"),
        field: "limitedToStorageGroup.name",
        sortable: false,
        hide: true,
        type: FieldType.String,
        tooltipValueGetter: tooltipValueGetter,
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser, {
        baseColDef: {
          sortable: false,
          tooltipValueGetter: tooltipValueGetter,
        }
      }),
    ];
    if (this.isOfTypeInput) {
      let spliceIndex = defs.findIndex(def => def.field == 'lotNumber')
      defs.splice(spliceIndex + 1, 0, {
        headerName: this.i18n.tr("articletoinput.expirationDate"),
        field: "expirationDate",
        sortable: false,
        valueFormatter: (data) => { return this.cellRenderer.dateRenderer(data, Various.DateFormat); },
        type: FieldType.DateTime,
        tooltipValueGetter: tooltipValueGetter,
      });
    }
    return defs;
  }

  //#region Conveyor
  @computedFrom('appModuleService._activeModules', 'authService.currentUser.id', 'isOfTypeInput')
  private get showLaunchPickBin(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.Conveyor)
      && this.authService.checkAccess(Constants.EntityTypeNames.WorkOrder, ConvConstants.Permissions.LaunchPickBin.replace(/-/g, ''))
      && false == this.isOfTypeInput;
  }

  @computedFrom('showLaunchPickBin', 'workOrderSelected.length', 'isBusy')
  private get canLaunchPickBin(): boolean {
    return this.showLaunchPickBin
      && false == this.isBusy
      && this.workOrderSelected.length == 1;
  }

  private async launchInBin() {
    if (false == this.canLaunchPickBin) return;
    const atas = await this.articleToActionSelected();
    if (atas.length > 0) {
      await this.box.showFloatingBox(new LaunchInBinFloatingBox(atas), true).whenClosed(async result => {
        if (false == result.wasCancelled) {
          await this.afterLaunchMessage();
        }
      });
    }
  }
  //#endregion
} 
