import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';

@autoinject
export class MessageAnswerDetail extends EntityDetailViewModelBase<Zeus.Web.Model.ReceivedMessage> {
  public ressourceName: string = Constants.EntityTypeNames.ReceivedMessage;

  constructor(
    router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.ReceivedMessage>(Constants.EntityTypeNames.ReceivedMessage));
  }

  @computedFrom('entity.messageId')
  public get documentTitle() {
    return this.entity.sentMessageId;
  }

  @computedFrom('_langWatcher')
  public get ribbonHeaderText() {
    return this.i18n.tr("message.responseReceived");
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.ReceivedMessage) {
    if (entity && entity.id) {
      let url = '/testreceivemessages/' + entity.id;
      return url;
    }
    return null;
  }

  public stringifyObj(obj) {
    let json = JSON.parse(obj);
    return JSON.stringify(json, null, 4);
  }

  public async activate(params) {
    const id = params.param1;
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id);
    this.controller.addObject(this.entity);
  }

} 
