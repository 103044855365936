import { FilterQueryOp, Predicate } from "breeze-client";
import { ServiceBase } from "digiwall-lib";
import { Zeus } from 'generated';
import * as Constants from "../../../constants";
import { InventoryBaseFloatingBox } from './inventory-base-floating-box';
import { Utils } from "utils/utils";
import * as MSConstants from "../../../app-modules/mobile-screens/constants";

export class InventoryStorageFloatingBox extends InventoryBaseFloatingBox<Zeus.Web.Model.InventoryWorkOrderStorage> {

  private storageService: ServiceBase<Zeus.Web.Model.Storage>;

  constructor(workOrder: Zeus.Web.Model.WorkOrder) {
    super(workOrder, 'storage', new ServiceBase<Zeus.Web.Model.InventoryWorkOrderStorage>(Constants.EntityTypeNames.InventoryWorkOrderStorage));
    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
    this.storageService.gridDataSource.customSelect2Predicates = () => {
      return Predicate.and(
        this.applyUniquenessPredicate(this.workOrder.inventoryLinks as Zeus.Web.Model.InventoryWorkOrderStorage[], Utils.storagePredicateLimitedToStorageGroup(this.workOrder.limitedToStorageGroup)),
        new Predicate("storageTypeId", FilterQueryOp.NotEquals, MSConstants.StorageTypes.Static)
      );
    };
  }

  private applyUniquenessPredicate(inventoryEntities: Zeus.Web.Model.InventoryWorkOrderStorage[], predicate: Predicate = null) {
    return inventoryEntities.reduce((predicate: Predicate, link: any) => {
      if (link.storageId != null) {
        let notEqual = new Predicate('id', FilterQueryOp.NotEquals, link.storageId);
        return predicate ? predicate.and(notEqual) : notEqual;
      }
      return predicate;
    }, predicate);
  }
}
