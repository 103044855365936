import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum, TranslatedPropertyRules, UITreePanelConfig, UITreePanelConfigAction, CsvExporter, FieldType, Various, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom, TaskQueue } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { ColDef } from 'ag-grid-community';
import { CellRenderer } from 'utils/cell-renderer';
import { ZeusUtilsMlFieldHelper } from 'utils/utils-ml-field-helper';

@autoinject
export class ArticleFamilyDetail extends EntityDetailViewModelBase<Zeus.Web.Model.ArticleFamily> {
  public ressourceName: string = Constants.EntityTypeNames.ArticleFamily;

  public articleFamilyList: Zeus.Web.Model.ArticleFamily[] = [];
  public treeConfig: UITreePanelConfig<Zeus.Web.Model.ArticleFamily>;
  public selectedArticleFamilies = [];

  constructor(router: Router, logger: CustomLogger, private taskQueue: TaskQueue, private cellRenderer: CellRenderer) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.ArticleFamily>(Constants.EntityTypeNames.ArticleFamily));

    this.service.gridDataSource.expands = ['articleFamilyParent'];

    // Parent cannot be self
    this.service.gridDataSource.customSelect2Predicates = () => this.entity ? new Predicate('id', FilterQueryOp.NotEquals, this.entity.id) : null;

    let _this = this;
    this.treeConfig = new UITreePanelConfig<Zeus.Web.Model.ArticleFamily>({
      parentId: 'articleFamilyParentId',
      label: (entry: Zeus.Web.Model.ArticleFamily) => entry.name._translation,
      withLines: true,
      autoExpand: false,
    });
    // Disable actions if a NEW is open
    this.treeConfig.actions.push(new UITreePanelConfigAction<Zeus.Web.Model.ArticleFamily>({
      label: 'common:general.addnew',
      icon: 'digi-plus',
      color: 'primary',
      asDropdown: true,
      fn: async entity => {
        await _this.articleFamilySelected(Various.NewId);
        this.entity.articleFamilyParentId = entity.id;
      },
      display: this.canAdd,
    }));

    this.treeConfig.actions.push(new UITreePanelConfigAction<Zeus.Web.Model.ArticleFamily>({
      label: 'common:general.remove',
      icon: 'digi-trash',
      color: 'danger',
      asDropdown: true,
      fn: async entry => {
        if (await _this.service.deleteEntities([entry], true)) {
          _this.articleFamilyList.splice(_this.articleFamilyList.findIndex(c => c.id == entry.id), 1);
          // Handle when we delete the selected element
          if (entry.id == this.entity?.id) {
            _this.doNavigateBack();
          }
        }
      },
      // Allow delete only if this is not a parent
      display: (entry, node) => node.leaf && _this.canDelete,
    }));
  }

  public async activate(params) {
    this.articleFamilyList.splice(0, this.articleFamilyList.length, ... await this.service.getEntities());

    let id: number = typeof params.param1 === 'string' ? parseInt(params.param1) : params.param1;
    if (isNaN(id)) {
      id = null;
    }
    await this.load(id);
  }

  private async load(id: number | null) {
    this.entity = null;

    if (id == Various.NewId || id == null) {
      this.selectedArticleFamilies.splice(0, this.selectedArticleFamilies.length);
      this.editingMode = EditingModeEnum.Create;
      if (id == Various.NewId) {
        this.entity = await this.service.createEntity();
        this.activateValidation();
      }
    } else {
      this.selectedArticleFamilies.splice(0, this.selectedArticleFamilies.length, id);
      this.editingMode = EditingModeEnum.Update;
      this.entity = this.articleFamilyList.find(x => x.id == id) || await this.service.getEntityById(id, 'articleFamilyParent');
      this.controller.addObject(this.entity);
      this.activateValidation();
    }
  }

  public activateValidation() {
    TranslatedPropertyRules.anyRequired('name')
      .withMessage('articlefamily.validationName')
      .on(this.entity);
  }

  @computedFrom('editingMode', 'entity.name', '_langWatcher')
  get ribbonHeaderText(): string {
    return this.documentTitle;
  }

  @computedFrom('editingMode', 'entity.name', '_langWatcher')
  get documentTitle(): string {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("menu.addnew") + ' ' + this.i18n.tr("articlefamily.articlefamily");
    } else if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.name._translation;
    }
  }

  public async articleFamilySelected(id?: number, firstCall: boolean = true): Promise<boolean> {
    // On navidation, select NULL first, then select real requested ID (in order to solve binding issues)
    if (firstCall) {
      await this.articleFamilySelected(null, false);
    }

    if (this.entity) {
      if (false == await this.canDeactivate()) {
        // Stop here, reset selection in tree
        if (this.entity.entityAspect.entityState.isAdded()) {
          this.selectedArticleFamilies.splice(0, this.selectedArticleFamilies.length);
        } else {
          this.selectedArticleFamilies.splice(0, this.selectedArticleFamilies.length, this.entity.id);
        }
        return Promise.resolve(false);
      }
    }
    return new Promise<boolean>(resolve => {
      this.taskQueue.queueMicroTask(async () => {
        this.router.navigate('/article-families/' + (id || 'all'), { replace: true, trigger: false });
        await this.load(id);
        resolve(true);
      });
    });
  }

  protected doNavigateBack(): void {
    this.articleFamilySelected();
  }

  protected changeRouteAfterCreation(): void {
    this.articleFamilySelected(this.entity.id);
  }

  public async beforeSave() {
    return await ZeusUtilsMlFieldHelper.BeforeSave(this.entity, [
      { prop: this.entity.name, label: "articlefamily.name", isRequired: true }, // This one is required but has a readonly.bind, must be careful with this, especially for the existing ones that aren't completed
      { prop: this.entity.description, label: "articlefamily.description", isRequired: false }
    ]);
  }

  protected afterSave(): Promise<void> {
    return new Promise((resolve) => {
      // Update tree
      let index = this.articleFamilyList.findIndex(c => c.id == this.entity.id);
      this.articleFamilyList.splice(index, index > -1 ? 1 : 0, this.entity);
      if (index < 0) {
        this.taskQueue.queueMicroTask(() => this.articleFamilySelected(this.entity.id));
      }
      resolve();
    });
  }

  public exportToCsv() {
    new CsvExporter<Zeus.Web.Model.ArticleFamily>(<any[]>this.csvExportColumns).export(this.articleFamilyList);
  }

  protected get csvExportColumns() {
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("articlefamily.name"),
        field: "name._translation",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("articlefamily.parent"),
        field: "articleFamilyParent.name._translation",
        type: FieldType.String
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
} 
