import { ZeusHubClient } from './external-src/zeus-hub-client';
import { UnloadHelper } from 'utils/unload-helper';
import { BindingSignaler } from 'aurelia-templating-resources';
import { HttpClient } from 'aurelia-fetch-client';
import { SiteManagementSelectionHelper } from './external-src/site-management/site-management-select-view';
import { RouterConfiguration, Router } from 'aurelia-router';
import { Config, Datacontext, EntityManager, BaseApp, AuthService, BreezeModel, UIInternal, CustomLogger, changeRoutesNav, ServiceBase, Actions, SecurityRouteGroup, IMenuItem, UIMenuGroupConfig } from 'digiwall-lib';
import 'jquery';
import { autoinject, computedFrom, Container, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import * as Constants from './constants';
import { DialogService } from 'aurelia-dialog';
import { InformationMessage } from 'information-messages/information-message';
import * as environment from './environment';
import { Zeus } from 'generated';
import { WorkOrderCreateShortcutsModal } from 'work-orders/create-shortcuts/work-order-create-shortcuts-modal';
import { AppModuleService } from 'app-modules/app-module-service';
import moment from 'moment';
import { Endpoints } from 'endpoints';
import { WorkOrderUtils } from 'utils/work-order-utils';
import { CustomPropertiesExtensionService } from 'extension-hooks/custom-properties/custom-properties-extension-service';

@autoinject
export class App extends BaseApp {
  private currentYear: string;
  private selectedSiteManagement: Zeus.Web.Model.StorageGroup;
  private version: String = "";

  private applicationParametersService: ServiceBase<Zeus.Web.Model.ApplicationParameters>;
  private delayBeforeLogout: number;
  private logoutTimeout;
  private uiViewport: HTMLElement;
  private restartListenerExists: boolean = false;
  private logoutBC: BroadcastChannel = null;

  private profileMenuItems: IMenuItem[] = [];

  public groupIcons: Map<string, UIMenuGroupConfig> = new Map<string, UIMenuGroupConfig>([
    [Constants.MenuType.HomePage, { icon: "digi-home" }],
    [Constants.MenuType.ApplicationParameters, { icon: "digi-options" }],
    [Constants.MenuType.Locations, { icon: "insert_drive_file" }],
    [Constants.MenuType.Articles, { icon: "digi-items" }],
    [Constants.MenuType.Tests, { icon: "digi-labo" }],
    [Constants.MenuType.Storages, { icon: "digi-stock" }],
    [Constants.MenuType.WorkOrders, { icon: "digi-reception" }],
    [SecurityRouteGroup, { icon: "digi-users" }],
    [Constants.MenuType.Reports, { icon: "digi-files-ticketing", alwaysGroup: true }],
  ]);

  private disposables: Disposable[] = [];

  @computedFrom('authService.currentUser.id')
  private get showCreateWorkOrder(): boolean {
    return WorkOrderUtils.hasActionAccessOnAnyType(this.authService, Actions.Create);
  }

  private get isMultiZone() {
    return SiteManagementSelectionHelper.isMultiZone;
  }

  constructor(context: Datacontext, config: Config, eventAggregator: EventAggregator, breezeModel: BreezeModel, authService: AuthService,
    entityManager: EntityManager, dialogService: DialogService, httpClient: HttpClient, private logger: CustomLogger, signaler: BindingSignaler,
    private zeusHubClient: ZeusHubClient, private appModuleService: AppModuleService) {
    super(context, config, eventAggregator, breezeModel, authService, entityManager, httpClient, dialogService, signaler);
  }

  public async initialize() {
    await this.appModuleService.initialize();
    await super.initialize();

    this.router.ensureConfigured().then(async () => {
      let applicationParameters: Zeus.Web.Model.ApplicationParameters = await this.getAppParameters();
      changeRoutesNav(this.router, [
        { name: 'projects', nav: applicationParameters.useProject },
        { name: 'cost-centers', nav: applicationParameters.useCostCenter }
      ]);
    });

    await Container.instance.get(CustomPropertiesExtensionService).initialize();

    this.disposables.push(...[
      this.zeusHubClient.onSiteSelectionChanged(async storageGroupId => {
        await this.selectSiteManagement(false, true);
        let route: string = this.router.currentInstruction.fragment;
        if (this.router.currentInstruction.queryString) {
          route += '?' + this.router.currentInstruction.queryString;
        }
        this.router.navigate(route, { replace: true, trigger: true });
      }),
      this.zeusHubClient.onImportFinished(() => this.addNotification())
    ]);
  }

  public async attached() {
    this.version = environment.version;
    this.currentYear = moment().format('YYYY');
    await this.attachedAutoLogout();

    // On language change, refresh the menu. NextTick() is used to refresh the menu AFTER the dropdown has closed
    UIInternal.subscribe(UIInternal.EVT_CHANGE_LANGUAGE, () =>
      UIInternal.nextTick(() => this.initProfileMenu())
    );
    this.initProfileMenu();
  }

  private initProfileMenu() {
    // Force new reference to refresh menu
    this.profileMenuItems = [];
    this.profileMenuItems.push(...[
      {
        label: this.i18n.tr('general.french'),
        icon: 'digi-www',
        handler: () => this.switchLanguage('fr')
      },
      {
        label: this.i18n.tr('general.dutch'),
        icon: 'digi-www',
        handler: () => this.switchLanguage('nl')
      },
      {
        label: this.i18n.tr('general.english'),
        icon: 'digi-www',
        handler: () => this.switchLanguage('en')
      },
      {
        label: this.i18n.tr('sitemanagement.selectASite'),
        icon: 'digi-stock',
        hidden: () => this.isMultiZone == false,
        handler: () => this.selectSiteManagement(true, false)
      },
      {
        label: this.i18n.tr('menu.logOut'),
        icon: 'digi-log-out',
        handler: () => this.disconnect()
      },
    ]);
  }

  public detached() {
    this.disposables?.forEach(x => x.dispose());
  }

  public async configureRouter(config: RouterConfiguration, router: Router) {
    await this.appModuleService.initialize();
    await super.configureRouter(config, router);
  }

  protected async disconnect() {
    this.logoutBC?.close();

    // Remove selected site management on logout
    await Container.instance.get(UnloadHelper).trigger();
    super.disconnect();
  }

  protected isValidLanguage(language: string): boolean {
    return (language == 'fr' || language == 'nl' || language == 'en');
  }

  async loadProfile() {
    await super.loadProfile();
    if (this.authService?.currentUser != null) {
      this.disposables.push(
        UIInternal.subscribe(Constants.Various.SessionSelectedSiteManagementEvent, () =>
          this.selectedSiteManagement = SiteManagementSelectionHelper.selectedSite
        )
      );
      await this.selectSiteManagement(false, false);
    }

    this.httpClient.fetch(Endpoints.Utils.GetUsersNotification + "?userId=" + this.authService.currentUser.id).then(async (result) => {
      if (result.ok) {
        this.authService.currentUser.userData.nbMessagesUnread = await result.json();
      }
    });
  }

  private async getAppParameters(): Promise<Zeus.Web.Model.ApplicationParameters> {
    if (this.applicationParametersService == null) {
      this.applicationParametersService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters);
    }
    return await this.applicationParametersService.firstEntity();
  }

  async selectSiteManagement(forceOpenSelect: boolean, fromHub: boolean) {
    await SiteManagementSelectionHelper.openSelectSite(forceOpenSelect, fromHub);
  }

  private openCreateWorkOrder() {
    this.dialogService.open({ viewModel: WorkOrderCreateShortcutsModal });
  }

  //#region notification
  public async openNotification() {
    this.dialogService.open({ viewModel: InformationMessage });
  }

  public addNotification() {
    if (this.authService?.currentUser?.userData != null) {
      if (Number.isNaN(this.authService.currentUser.userData.nbMessagesUnread) || this.authService.currentUser.userData.nbMessagesUnread == undefined) {
        this.authService.currentUser.userData.nbMessagesUnread = 0;
      }
      this.authService.currentUser.userData.nbMessagesUnread++;
      this.logger.Log(this.i18n.tr("informationmessage.newMessage"), null, null, true);
    }
  }
  //#endregion

  //#region Auto-Logout
  private async attachedAutoLogout() {
    if (this.delayBeforeLogout == null) {
      let applicationParameters: Zeus.Web.Model.ApplicationParameters = await this.getAppParameters();
      this.delayBeforeLogout = applicationParameters.delayBeforeLogout ?? 0;
    }
    if (this.delayBeforeLogout > 0) {
      if (!this.restartListenerExists) {
        this.uiViewport.addEventListener("mouseup", this.mouseClickPressed);
        this.restartListenerExists = true;
      }
      this.restartLogoutTimeout();
    }
    this.createLogoutBroadcastChannel();

    UIInternal.subscribe(Constants.Various.DelayBeforeLogoutChangedEvent, async (payload: { oldValue: number | null, newValue: number | null }) => {
      this.handleLogoutDelayChanged(payload);
      this.logoutBC.postMessage(payload);
    });
  }

  private async disconnectEachTabs() {
    this.logoutBC.postMessage(Constants.BroadcastChannel.AutoLogoutDisconnect);
    await this.disconnect();
  }

  private mouseClickPressed = () => {
    this.logoutBC.postMessage(Constants.BroadcastChannel.AutoLogoutRestart);
    this.restartLogoutTimeout();
  }

  private restartLogoutTimeout() {
    if (this.delayBeforeLogout > 0) {
      if (this.logoutTimeout != null) {
        clearTimeout(this.logoutTimeout);
      }
      this.logoutTimeout = setTimeout(async () => await this.disconnectEachTabs(), this.delayBeforeLogout * 60000);
    }
  }

  private handleLogoutDelayChanged(payload: { oldValue: number | null, newValue: number | null }) {
    if (payload.oldValue == null || payload.oldValue == 0) {
      if (!this.restartListenerExists) {
        this.uiViewport.addEventListener("mouseup", this.mouseClickPressed);
        this.restartListenerExists = true;
      }
      this.delayBeforeLogout = payload.newValue ?? 0;
      this.restartLogoutTimeout();
    }
    else if (payload.newValue == null || payload.newValue == 0) {
      if (this.restartListenerExists) {
        this.uiViewport.removeEventListener("mouseup", this.mouseClickPressed);
        this.restartListenerExists = false;
      }
      if (this.logoutTimeout != null) {
        clearTimeout(this.logoutTimeout);
      }
    }
    else {
      this.delayBeforeLogout = payload.newValue ?? 0;
      this.restartLogoutTimeout();
    }
  }

  private createLogoutBroadcastChannel() {
    // Connection to a broadcast channel
    this.logoutBC = new BroadcastChannel(Constants.BroadcastChannel.AutoLogout);

    // Handle logout in different tabs/windows
    this.logoutBC.onmessage = async (event) => {
      switch (event.data) {
        case Constants.BroadcastChannel.AutoLogoutDisconnect:
          await this.disconnect();
          break;

        case Constants.BroadcastChannel.AutoLogoutRestart:
          this.restartLogoutTimeout();
          break;

        default:
          let payload: { oldValue: number | null, newValue: number | null } = event.data;
          this.handleLogoutDelayChanged(payload);
      }
    };

    // A handler that logs the potential errors:
    this.logoutBC.onmessageerror = (event) => {
      console.error("[ERROR] BroadcastChannel - AutoLogout :", event);
    };
  }
  //#endregion
}
