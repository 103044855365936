import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from "aurelia-framework";
import { DialogService } from "aurelia-dialog";
import { Router } from "aurelia-router";
import { CustomLogger, FilePreviewer } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import * as Constants from '../constants';

@autoinject
export class FileElement {
  public autorizedFileExtensions: string;
  public autorizedPictureExtensions: string;
  public remoteFileServiceName: string;

  constructor(private router: Router, private logger: CustomLogger, private i18n: I18N, private dialogService: DialogService, private httpClient: HttpClient) {
    this.autorizedFileExtensions = Constants.Extensions.All;
    this.autorizedPictureExtensions = Constants.Extensions.Image;
    this.remoteFileServiceName = Constants.Application.RemoteServiceName + Constants.Application.RemoteFileServiceName;
  }

  public previewFile(fileEntity: { url: string }) {
    let fileExtension: string = FileElement.getFileExtension(fileEntity.url);
    if (fileExtension) {
      this.dialogService.open({ viewModel: FilePreviewer, model: { fileExtension: fileExtension, fileUrl: fileEntity.url }, lock: false });
    } else {
      console.warn("L'extension ." + fileExtension + " n'est pas une extension valide.");
    }
  }

  public static getFileExtension(fileUrl: string): string {
    let fileExtension: string;
    if (fileUrl != null && fileUrl.length > 0) {
      fileExtension = fileUrl.split('.').pop().toLowerCase();
      switch (fileExtension) {
        case "jpeg": {
          fileExtension = "jpg";
          break;
        }
        case "xls": {
          fileExtension = "xlsx";
          break;
        }
        case "pptx": {
          fileExtension = "ppt";
          break;
        }
      }
    }
    return fileExtension;
  }

  public static getFileType(fileUrl: string): string {
    return "document-type-" + FileElement.getFileExtension(fileUrl);
  }

  public async downloadReport(url: string, extension: string, download: boolean = true) {
    let response: Response = await this.httpClient.fetch(url);
    if (response.ok) {
      let fileName = "rapport." + extension;
      let disposition = response.headers.get('content-disposition');
      if (disposition) {
        let match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
        if (match[1])
          fileName = decodeURI(match[1]);
      }
      let url: string = URL.createObjectURL(await response.blob());
      if (download) {
        let anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    } else {
      this.logger.LogError(this.i18n.tr('general.downloadFilesError'), null, "", true);
    }
  }

  public downloadFile(fileEntity: { url: string }) {
    window.open(fileEntity.url, '_blank');
    //this.router.navigate(fileEntity.url, { replace: false });
  }
}
