import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import moment from 'moment';

@autoinject
export class ActionHistoryDetail extends EntityDetailViewModelBase<Zeus.Web.Model.ActionHistory> {
  public ressourceName: string = Constants.EntityTypeNames.ActionHistory;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.ActionHistory>(Constants.EntityTypeNames.ActionHistory));
  }

  public async activate(params) {
    const id = params.param1;
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id, 'actionType', 'workOrder.inventoryTarget', 'article', 'location', 'workstationUser', 'workstationPosition');
    this.controller.addObject(this.entity);
  }

  @computedFrom('documentTitle', '_langWatcher')
  get ribbonHeaderText(): string {
    return this.i18n.tr('actionhistory.actionHistory') + ': ' + this.documentTitle;
  }

  @computedFrom('entity.actionDateTime', '_langWatcher')
  get documentTitle(): string {
    return moment(this.entity.actionDateTime).format('DD/MM/YYYY, HH:mm:ss');
  }

  @computedFrom('entity.actionTypeId')
  private get isReception(): boolean {
    return this.entity.actionTypeId == Constants.ActionHistoryTypeId.Reception;
  }

  @computedFrom('entity.actionTypeId')
  private get isInventory(): boolean {
    return this.entity.actionTypeId == Constants.ActionHistoryTypeId.Inventory;
  }
}
