import { TrayPreview } from './tray-preview';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, EnumerationTypeService, ServiceBase, ViewModelBase } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom, TaskQueue } from 'aurelia-framework';
import * as toastr from 'toastr';
import { DialogController } from 'aurelia-dialog';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { Utils } from 'utils/utils';
import { TrayTemplateDetail } from 'tray-templates/tray-template-detail';
import { UnifiedStorageEntity } from 'storages/storage-tree-panel';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';

@autoinject
export class LocationDetailFromTemplate extends ViewModelBase {
  public ressourceName: string = "";

  private locationService: ServiceBase<Zeus.Web.Model.Location>;
  private trayTemplateService: ServiceBase<Zeus.Web.Model.TrayTemplate>;
  private templatePositionService: ServiceBase<Zeus.Web.Model.TrayTemplatePosition>;
  private trayContainerService: ServiceBase<Zeus.Web.Model.TrayContainer>;

  private articleRotationLevelTypeService: EnumerationTypeService;
  private storageLocationTypeService: EnumerationTypeService;

  // Location data
  public storage: Zeus.Web.Model.Storage;
  public storageId: number;
  private trayContainer: DataFormat;
  private trayTemplate: DataFormat;
  private dedicatedToRotationLevel: DataFormat;
  private storageLocationType: DataFormat;

  private trayLocations: Array<Zeus.Web.Model.Location>;
  private templatePositions: Array<Zeus.Web.Model.TrayTemplatePosition>;
  private locationsForPreview: Array<Zeus.Web.Model.TrayTemplatePosition>;

  private trayPreview: TrayPreview;

  private Constants: any = Constants;

  private creationBusy: boolean = false;
  private storageEditable: boolean = false;
  private isCreationMode: boolean = true;
  private isRestrictedTraysActive: boolean = false;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController, private httpClient: HttpClient, private taskQueue: TaskQueue, private appModuleService: AppModuleService) {
    super(router, logger);

    this.locationService = new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location);
    this.locationService.gridDataSource.expands = ['defaultStorageVolume.volumeType'];
    this.templatePositionService = new ServiceBase<Zeus.Web.Model.TrayTemplatePosition>(Constants.EntityTypeNames.TrayTemplatePosition);
    this.templatePositionService.gridDataSource.expands = ['defaultStorageVolume.volumeType'];
    this.trayTemplateService = new ServiceBase<Zeus.Web.Model.TrayTemplate>(Constants.EntityTypeNames.TrayTemplate);
    this.trayContainerService = new ServiceBase<Zeus.Web.Model.TrayContainer>(Constants.EntityTypeNames.TrayContainer);
    this.trayContainerService.gridDataSource.queryParameters = { notLinkedToLocation: true };

    this.articleRotationLevelTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ArticleRotationLevel);
    this.storageLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageLocationType);
  }

  @computedFrom('storage.name', '_langWatcher')
  public get ribbonHeaderText() {
    if (this.storage != null) {
      return this.i18n.tr("location.locationFor") + this.storage.name;
    }
    return this.i18n.tr("location.location");
  }

  public async activate(params) {
    this.isRestrictedTraysActive = this.appModuleService.isActive(AppModuleEnum.RestrictedTrays);

    if (params) {
      this.storage = params.storage;
      this.storageId = params.storage.id;
    }

    this.trayContainerService.gridDataSource.customSelect2Predicates = () => {
      let trayPredicate = new Predicate("storageId", FilterQueryOp.Equals, this.storage.id).and(new Predicate("isUnavailable", FilterQueryOp.Equals, false));
      if (this.isRestrictedTraysActive) {
        trayPredicate = trayPredicate.and(new Predicate("isRestricted", FilterQueryOp.Equals, false));
      }
      return trayPredicate;
    }
    this.trayTemplateService.gridDataSource.customSelect2Predicates = () => Predicate.and(new Predicate("trayWidthCm", FilterQueryOp.Equals, this.storage.trayWidthCm), new Predicate("trayDepthCm", FilterQueryOp.Equals, this.storage.trayDepthCm));

    if (this.storage != null) {
      await this.setStorageLocationType();
    } else {
      this.storageEditable = true;
    }
  }

  private isNodeSelectable(entity: UnifiedStorageEntity) {
    return this.isCreationMode ? !!entity.storage : entity.storage?.id == this.storageId;
  }

  private onStorageSelected(storage: Zeus.Web.Model.Storage) {
    this.storage = storage;
    this.storageId = storage.id;
  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, "trayContainer").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.trayLocations = await this.locationService.getEntities(new Predicate("trayContainerId", FilterQueryOp.Equals, Utils.extractIdFromDataFormat(this.trayContainer)), this.locationService.gridDataSource.expands);
          this.buildPreviewLocationsArray(true);
        }
      }),
      this.bindingEngine.propertyObserver(this, "trayTemplate").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.templatePositions = await this.templatePositionService.getEntities(new Predicate("trayTemplateId", FilterQueryOp.Equals, Utils.extractIdFromDataFormat(this.trayTemplate)), this.templatePositionService.gridDataSource.expands);
          this.templatePositions.forEach(pos => (pos as any).fade = true);
          this.buildPreviewLocationsArray(false);
        }
      }),
      this.bindingEngine.propertyObserver(this, "storage").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.trayContainer = null;
          this.trayTemplate = null;
          if (this.locationsForPreview == null) {
            this.locationsForPreview = [];
          }
          else {
            this.locationsForPreview.splice(0, this.locationsForPreview.length);
          }
          await this.setStorageLocationType();
        } else if (newValue == null) {
          this.storageLocationType = null;
        }
      })
    );
  }

  private buildPreviewLocationsArray(trayHasChanged: boolean) {
    if (this.locationsForPreview == null) {
      this.locationsForPreview = [];
      this.locationsForPreview.push(...this.trayLocations);
      this.locationsForPreview.push(...this.templatePositions);
    } else {
      if (trayHasChanged) {
        this.locationsForPreview = this.locationsForPreview.filter(x => (x as any).fade);
        this.locationsForPreview.push(...this.trayLocations);
      } else {
        this.locationsForPreview = this.locationsForPreview.filter(x => !(x as any).fade);
        this.locationsForPreview.push(...this.templatePositions);
      }
    }

    this.trayPreview?.redraw();
  }

  private async setStorageLocationType() {
    this.storageLocationType = null;
    this.taskQueue.queueTask(async () => {
      if (this.storage.storageLocationTypeId != null) {
        if (this.storage.storageLocationType == null) {
          this.storage.storageLocationType = await this.storageLocationTypeService.getEntityById(this.storage.storageLocationTypeId);
        }
        this.storageLocationType = { id: this.storage.storageLocationTypeId, text: this.storage.storageLocationType.denomination._translation };
      }
    });
  }

  private async createNewTemplate() {
    await this.box.showEditDialog(TrayTemplateDetail, -100, this.i18n.tr('traytemplate.addTrayTemplate'), (vm: TrayTemplateDetail) => {
      vm.selectedStorage = {
        id: this.storage.id,
        text: this.storage.name
      } as DataFormat;
      vm.isEditDialog = true;
    }).whenClosed(async (result) => {
      if (result.output != null) {
        // TO DO : Fix box.showEditDialog => not pass in whenClosed when box is cancelled (instead of saved) => because trayTemplate screen isn't closed when the user save, he has to manually close and the system thinks that the user cancels the screen
        let newEntity: Zeus.Web.Model.TrayTemplate = result.output;
        this.trayTemplate = {
          id: newEntity.id,
          text: newEntity.name
        };
      }
    });
  }

  private isValid() {
    if (this.storage == null) {
      toastr.error(this.i18n.tr("location.storageMandatory"));
      return false;
    }

    if (this.trayContainer == null) {
      toastr.error(this.i18n.tr("location.trayContainerMandatory"));
      return false;
    }

    if (this.trayTemplate == null) {
      toastr.error(this.i18n.tr("location.trayTemplateMandatory"));
      return false;
    }

    return true;
  }

  public async createLocations() {
    if (this.isValid() && !this.creationBusy) {
      this.creationBusy = true;
      try {
        let params: Zeus.Web.Model.LocationFromTemplate = {
          storageId: this.storage.id,
          dynamicStorageId: this.storage.dynamicStorageId,
          trayTemplateId: Utils.extractIdFromDataFormat(this.trayTemplate),
          trayContainerId: Utils.extractIdFromDataFormat(this.trayContainer),
          trayId: (await this.trayContainerService.getEntityByIdFromLocalCache(Utils.extractIdFromDataFormat(this.trayContainer)))?.trayId,
          dedicatedToRotationLevelId: Utils.extractIdFromDataFormat(this.dedicatedToRotationLevel),
          storageLocationTypeId: Utils.extractIdFromDataFormat(this.storageLocationType),
        };

        let response = await this.httpClient.post("/api/location/createLocationsFromTemplate", JSON.stringify(params));
        if (response.ok) {
          this.dialogController.close(true);
        }
        this.creationBusy = false;

        // Stay on screen after createLocations if necessary ?
        this.dialogController.ok();
      }
      catch (error) {
        this.creationBusy = false;
        throw error;
      }
    }
  }

  public close() {
    this.dialogController.cancel();
  }
}
