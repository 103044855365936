import { HttpClient } from 'aurelia-fetch-client';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { Router } from 'aurelia-router';
import { autoinject, bindable, BindingEngine, computedFrom, customElement } from "aurelia-framework";
import { CamelCase, CustomLogger, DialogBoxViewModel, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, ServiceBase, Various } from "digiwall-lib";
import { DropToLight } from "../generated";
import * as DTLConstants from "../constants";
import * as Constants from "../../../constants";
import { Zeus } from "../../../generated";
import { Utils } from 'utils/utils';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import * as MsConstants from 'app-modules/mobile-screens/constants';

@autoinject
@customElement('workstation-bay-group-detail-view')
export class WorkstationBayGroupDetailView extends EntityDetailViewModelBase<DropToLight.Model.WorkstationBayGroup> {
  public ressourceName: string = DTLConstants.EntityTypeNames.Workstation;
  public DTLConstants = DTLConstants;

  @bindable
  private workstationBayGroupId: number;
  @bindable
  private onCancel = () => { };
  @bindable
  private onSaved: (args: { workstationBayGroup: DropToLight.Model.WorkstationBayGroup }) => Promise<void>;

  public bayService: ServiceBase<Zeus.Web.Model.Bay>;
  public bayOfWorkstationGroupService: ServiceBase<DropToLight.Model.BayOfWorkstationBayGroup>;
  public dTLColorService: EnumerationTypeService;

  private bayOfWorkstationGroupSideOne: Array<DropToLight.Model.BayOfWorkstationBayGroup>;
  private bayOfWorkstationGroupSideTwo: Array<DropToLight.Model.BayOfWorkstationBayGroup>;
  private bayOfWorkstationGroupArrays: Array<Array<DropToLight.Model.BayOfWorkstationBayGroup>> = [];

  private backToOldBayGroupType: boolean = false;

  @computedFrom('editingMode', 'entity.name', '_langWatcher')
  get documentTitle(): string {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr('menu.addnew') + ' ' + this.i18n.tr("workstationbaygroup.workstationbaygroup");
    }
    if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.name;
    }
  }

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private httpClient: HttpClient, private appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<DropToLight.Model.WorkstationBayGroup>(DTLConstants.EntityTypeNames.WorkstationBayGroup));
    this.bayService = new ServiceBase<Zeus.Web.Model.Bay>(Constants.EntityTypeNames.Bay);
    this.bayOfWorkstationGroupService = new ServiceBase<DropToLight.Model.BayOfWorkstationBayGroup>(DTLConstants.EntityTypeNames.BayOfWorkstationBayGroup);
    this.dTLColorService = new EnumerationTypeService(DTLConstants.EnumerationTypes.DTLColor);
  }

  public async attached() {
    if (this.workstationBayGroupId == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.bayOfWorkstationGroupSideOne = [];
      this.bayOfWorkstationGroupSideTwo = [];
    } else if (this.workstationBayGroupId > 0) {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(this.workstationBayGroupId, 'zone', 'bayGroupType', 'bayOfWorkstationGroups.bay.color');

      this.bayService.gridDataSource.customSelect2Predicates = () => {
        let predicate = new Predicate('storage.storageGroupId', FilterQueryOp.Equals, this.entity.zoneId);
        let selectedPredicates = this.entity.bayOfWorkstationGroups.filter(x => x.bayId != null).map(x => new Predicate('id', FilterQueryOp.NotEquals, x.bayId));
        if (selectedPredicates.length > 0) {
          predicate = predicate.and(...selectedPredicates);
        }

        // only allow dynamic or static based on bays already provided
        if (this.entity?.bayOfWorkstationGroups?.length > 0) {
          const storageTypeId = this.entity.bayOfWorkstationGroups
            .map(group => group.bay?.storage?.storageTypeId)
            .find(id => id != null);

          if (storageTypeId != null) {
            predicate = predicate.and(
              new Predicate(
                'storage.storageTypeId',
                storageTypeId === MsConstants.StorageTypes.Static ? FilterQueryOp.Equals : FilterQueryOp.NotEquals,
                MsConstants.StorageTypes.Static
              )
            );
          }
        }

        if (this.appModuleService.isActive(AppModuleEnum.MultipleBay)) {
          predicate = predicate.and(
            new Predicate('bayMessageTypes', FilterQueryOp.Any, 'messageType.workOrderTypeId', FilterQueryOp.Equals,
              this.entity.bayGroupTypeId == DTLConstants.BayGroupTypeId.Input ? Constants.WorkOrderType.Input : Constants.WorkOrderType.Picking)
          );
        }

        return predicate;
      };
      this.bayService.gridDataSource.customSelect2QueryParameters = () => {
        return { workstationBayGroupId: this.entity.id, workstationBayGroupTypeId: this.entity.bayGroupTypeId };
      };
      this.dTLColorService.gridDataSource.customSelect2Predicates = () => {
        return Predicate.and(...this.entity.bayOfWorkstationGroups
          .filter(x => x.bay?.colorId != null)
          .map(x => new Predicate('id', FilterQueryOp.NotEquals, x.bay.colorId))
        );
      };
      await this.verifyBayOfWorkstationGroupColor();
    } else {
      throw `Incorrect id ${this.workstationBayGroupId}`;
    }
    this.controller.addObject(this.entity);

    this.setBayOfWorkstationGroupArrays();
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, "isDoubleSided").subscribe((newVal, oldVal) => {
        if (newVal != oldVal) {
          if (!newVal) {
            this.bayOfWorkstationGroupSideTwo.forEach(x => x.workstationPositionSideId = DTLConstants.WorkstationPositionSideId.One);
          }
          this.setBayOfWorkstationGroupArrays();
        }
      }),
      this.bindingEngine.propertyObserver(this.entity, "bayGroupType").subscribe(async (newVal, oldVal) => {
        if (newVal != oldVal) {
          if (!this.backToOldBayGroupType) {
            let bayIdsAllowed = (await this.bayService.getEntities(null, null, { workstationBayGroupId: this.entity.id, workstationBayGroupTypeId: newVal.id })).map(s => s.id);
            if (this.entity.bayOfWorkstationGroups.some(sowg => !bayIdsAllowed.includes(sowg.bayId))) {
              await this.box.showQuestion(this.i18n.tr('workstationbaygroup.removeInvalidBays'),
                this.i18n.tr('menu.del-filter-set-title'),
                [
                  { label: this.i18n.tr('general.no'), theme: 'primary', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(false) },
                  { label: this.i18n.tr('general.yes'), theme: 'warning', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(true) }
                ]
              ).whenClosed(async result => {
                if (result.output) {
                  await this.bayOfWorkstationGroupService.deleteEntities(this.entity.bayOfWorkstationGroups.filter(sowg => !bayIdsAllowed.includes(sowg.bayId)), true, null, true);
                  let filteredbayOfWorkstationGroups = this.entity.bayOfWorkstationGroups.filter(sowg => bayIdsAllowed.includes(sowg.bayId));
                  this.entity.bayOfWorkstationGroups.splice(0);
                  this.entity.bayOfWorkstationGroups.push(...filteredbayOfWorkstationGroups);
                  this.setBayOfWorkstationGroupArrays();
                }
                else {
                  this.backToOldBayGroupType = true;
                  this.entity.bayGroupType = oldVal;
                }
              });
            }
          }
          else {
            this.backToOldBayGroupType = false;
          }
        }
      })
    );
  }

  private setBayOfWorkstationGroupArrays() {
    this.bayOfWorkstationGroupSideOne = this.entity.bayOfWorkstationGroups.filter(x => x.workstationPositionSideId == DTLConstants.WorkstationPositionSideId.One);
    this.bayOfWorkstationGroupSideTwo = this.entity.bayOfWorkstationGroups.filter(x => x.workstationPositionSideId == DTLConstants.WorkstationPositionSideId.Two);
    this.bayOfWorkstationGroupArrays.splice(0);
    if (this.entity.isDoubleSided) {
      this.bayOfWorkstationGroupArrays.push(this.bayOfWorkstationGroupSideOne, this.bayOfWorkstationGroupSideTwo);
    } else {
      this.bayOfWorkstationGroupArrays.push(this.bayOfWorkstationGroupSideOne);
    }
  }

  //#region bayOfWorkstationGroups
  private async addBayOfWorkstationGroup(workstationPositionSideId: number) {
    let isFromSide1: boolean = workstationPositionSideId == 0;
    let newBayOfWorkstationGroup: DropToLight.Model.BayOfWorkstationBayGroup = await this.bayOfWorkstationGroupService.createEntity({
      workstationBayGroupId: this.entity.id,
      workstationPositionSideId: isFromSide1 ? DTLConstants.WorkstationPositionSideId.One : DTLConstants.WorkstationPositionSideId.Two
    });
    this.getSideGroup(isFromSide1).push(newBayOfWorkstationGroup);
  }

  private async removeBayOfWorkstationGroup(bayOfWorkstationGroup: DropToLight.Model.BayOfWorkstationBayGroup) {
    Utils.detachOrDeleteEntity(bayOfWorkstationGroup);
    let sideGroup = this.getSideGroup(bayOfWorkstationGroup.workstationPositionSideId == DTLConstants.WorkstationPositionSideId.One);
    sideGroup.splice(sideGroup.findIndex(x => x.id == bayOfWorkstationGroup.id), 1);
  }

  private getLabelPannel(workstationPositionSideId: number) {
    return this.i18n.tr('bayofworkstationgroup.' + (workstationPositionSideId == 0 ? 'workstationPositionSideOne' : 'workstationPositionSideTwo'));
  }

  private getSideGroup(isFromSide1: boolean): Array<DropToLight.Model.BayOfWorkstationBayGroup> {
    if (isFromSide1) {
      return this.bayOfWorkstationGroupSideOne;
    }
    return this.bayOfWorkstationGroupSideTwo;

  }

  private async verifyBayOfWorkstationGroupColorByBay(bayOfWorkstationGroup: DropToLight.Model.BayOfWorkstationBayGroup) {
    let result = await this.httpClient.fetch(DTLConstants.Application.VerifyBayOfGroupColorByBay + "?bayId=" + bayOfWorkstationGroup.bayId);
    if (result.ok) {
      this.setMessagePanel(await result.json());
    }
  }

  private async verifyBayOfWorkstationGroupColor() {
    let result = await this.httpClient.fetch(DTLConstants.Application.VerifyBayOfGroupColorByWorkstationBayGroup + "?workstationBayGroupId=" + this.entity.id);
    if (result.ok) {
      var t = JSON.parse(await result.text(), (key: string, value: any) => { return CamelCase.convertKeysToCamelCase(key, value) })
      this.setMessagePanel(t);
    }
  }

  private messageAlert: string = null;
  private setMessagePanel(bayOfGroupColors: Array<BayOfGroupColorDTO>) {
    if (bayOfGroupColors?.length == 0) {
      this.messageAlert = null;
    } else {
      this.messageAlert = "";
      bayOfGroupColors.forEach(bayOfGroupColor => {
        let message = this.i18n.tr('workstationbaygroup.duplicateColorMessage', {
          colors: bayOfGroupColor.colorNames.join(', ')
        });
        message = message.replace("{workstationbaygroupToReplace}", "<strong>" + bayOfGroupColor.workstationBayGroupName + "</strong>");
        let bayName = bayOfGroupColor.storageName + " - " + this.i18n.tr('bay.bay') + ': ' + bayOfGroupColor.bayNumber
        message = message.replace("{storageToReplace}", "<strong>" + bayName + "</strong>");
        this.messageAlert += '<p>' + message + '</p>';
      })
    }
  };
  //#endregion

  //#region Deactivation
  public detached(): void {
    this.controller.removeObject(this.entity);
    this.entity = null;
    this.disposables.forEach(d => d.dispose());
  }

  protected doNavigateBack(): void {
    // Unselect tree
    this.onCancel();
  }

  public async cancel(silentCancel?: boolean, onlyCurrentEntity?: boolean): Promise<any> {
    let result = await super.cancel(silentCancel, onlyCurrentEntity);
    if (result) {
      this.setBayOfWorkstationGroupArrays();
      return true;
    }
    return result
  }
  //#endregion Deactivation

  //#region Saving
  public async save(silentSave?: boolean, byPassLocking?: boolean, navigateBack?: boolean): Promise<any> {
    await super.save(silentSave, byPassLocking, navigateBack);
    if (this.onSaved) {
      await this.onSaved({ workstationBayGroup: this.entity });
    }
  }
  protected async afterSave() {
    await this.verifyBayOfWorkstationGroupColor();
  }
  protected changeRouteAfterCreation(): void {
    // Do nothing
  }
  //#endregion Saving
}

export interface BayOfGroupColorDTO {
  workstationBayGroupName: string;
  storageName: string;
  bayNumber: number;
  colorNames: string[];
}
