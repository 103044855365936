import { BindingSignaler } from 'aurelia-templating-resources';
import { UnloadHelper } from './../utils/unload-helper';
import { GlobalValidationConfiguration, PropertyAccessorParser, ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { Actions, CustomLogger, DialogBoxViewModel, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, GlobalLoaderService, ServiceBase, UIInternal, Various } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom, Container, viewResources } from 'aurelia-framework';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import { ApiService } from 'external-src/api.service';
import { ListViewModelActionHistoryFilter } from 'action-histories/action-history-list';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { SiteManagementSelectionHelper } from 'external-src/site-management/site-management-select-view';
import { WorkOrderUtils } from 'utils/work-order-utils';
import * as BConstants from '../app-modules/borrowing/constants';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import { Conveyor } from 'app-modules/conveyor/generated';
import * as FPConstants from '../app-modules/free-picking/constants';
import { InputPickActions } from './article-to-actions/input-pick-actions';
import { InventoryActions } from './article-to-actions/inventory/inventory-actions';
import { TrayPickWorkOrder } from 'app-modules/free-picking/tray-pick-work-order/tray-pick-work-order';
import * as MSConstants from '../app-modules/mobile-screens/constants';

@autoinject
@viewResources(InputPickActions, InventoryActions, TrayPickWorkOrder)
export class WorkOrderDetail extends EntityDetailViewModelBase<Zeus.Web.Model.WorkOrder> {
  public ressourceName: string = Constants.EntityTypeNames.WorkOrder;
  public Constants: any = Constants;

  private workOrderStatusService: EnumerationTypeService;
  private workOrderTypeService: EnumerationTypeService;
  private orderSubtypeService: EnumerationTypeService;
  private inventoryTargetService: EnumerationTypeService;
  private articleToActionService: ServiceBase<Zeus.Web.Model.ArticleToAction>;
  private workordePriorityService: ServiceBase<Zeus.Web.Model.WorkOrderPriority>;
  private articleService: ServiceBase<Zeus.Web.Model.Article>;
  private limitedToStorageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;
  private projectService: ServiceBase<Zeus.Web.Model.Project>;
  private costCenterService: ServiceBase<Zeus.Web.Model.CostCenter>;
  private applicationParameterService: ServiceBase<Zeus.Web.Model.ApplicationParameters>;
  private inventoryWOArticleService: ServiceBase<Zeus.Web.Model.InventoryWorkOrderArticle>;
  private erpBinDestinationService: ServiceBase<Conveyor.Model.ErpBinDestination>;

  private lockLocations = false;
  private isProjectMandatory: boolean;
  private isCostCenterMandatory: boolean;
  private useCostCenter: boolean;
  private useProject: boolean;

  private typesNotInitialized = true;
  private actionsLoaded = false;

  private isEditingOrder: boolean = false;
  private isNavigating: boolean = false;
  private isLaunchingMessage: boolean = false;

  private get isMultiZone() {
    return SiteManagementSelectionHelper.isMultiZone;
  }

  @computedFrom('isEditingOrder', 'isNavigating', 'isLaunchingMessage')
  public get isBusy() {
    return this.isEditingOrder || this.isNavigating || this.isLaunchingMessage;
  }

  @computedFrom('entity.id', 'entity.workOrderTypeId', 'entity.orderSubtypeId', 'entity.inventoryTargetId', '_langWatcher')
  public get documentTitle(): any {
    if (this.entity?.workOrderTypeId) {
      let parts = [this.entity.workOrderType.denomination._translation, this.i18n.tr('workorder.workorder')];
      switch (this.entity.workOrderTypeId) {
        case Constants.WorkOrderType.Input:
        case Constants.WorkOrderType.Picking:
          if (this.entity.orderSubtype != null) {
            parts.splice(0, 1, this.entity.orderSubtype.denomination._translation);
          }
          break;
        case Constants.WorkOrderType.Inventory:
          if (this.entity.inventoryTarget != null) {
            parts.splice(0, 0, this.entity.inventoryTarget.denomination._translation);
          }
          break;
      }
      return parts.map((value, index) =>
        index == 0 ? value : value.toLowerCase()
      ).join(' ');
    } else if (this.isCreationMode) {
      return this.i18n.tr('workorder.addWorkOrder');
    } else if (this.entity) {
      // Should not happen
      return this.entity.id;
    }
  }

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private httpClient: HttpClient, private apiService: ApiService, private appModuleService: AppModuleService, private signaler: BindingSignaler, private globalLoaderService: GlobalLoaderService) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder));

    this.workOrderStatusService = new EnumerationTypeService(Constants.EnumerationTypes.WorkOrderStatus);
    this.workOrderTypeService = new EnumerationTypeService(Constants.EnumerationTypes.WorkOrderType);
    this.orderSubtypeService = new EnumerationTypeService(Constants.EnumerationTypes.OrderSubtype);
    this.inventoryTargetService = new EnumerationTypeService(Constants.EnumerationTypes.InventoryTarget);
    this.limitedToStorageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.articleToActionService = new ServiceBase<Zeus.Web.Model.ArticleToAction>(Constants.EntityTypeNames.ArticleToAction);
    this.workordePriorityService = new ServiceBase<Zeus.Web.Model.WorkOrderPriority>(Constants.EntityTypeNames.WorkOrderPriority);
    this.articleService = new ServiceBase<Zeus.Web.Model.Article>(Constants.EntityTypeNames.Article);
    this.articleService.gridDataSource.expands = ['unitOfMeasures.unitOfMeasure'];
    this.projectService = new ServiceBase<Zeus.Web.Model.Project>(Constants.EntityTypeNames.Project);
    this.projectService.gridDataSource.customSelect2Predicates = () => new Predicate('active', FilterQueryOp.Equals, true);
    this.costCenterService = new ServiceBase<Zeus.Web.Model.CostCenter>(Constants.EntityTypeNames.CostCenter);
    this.costCenterService.gridDataSource.customSelect2Predicates = () => new Predicate('active', FilterQueryOp.Equals, true);
    this.applicationParameterService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters);
    this.inventoryWOArticleService = new ServiceBase<Zeus.Web.Model.InventoryWorkOrderArticle>(Constants.EntityTypeNames.InventoryWorkOrderArticle);
    this.erpBinDestinationService = new ServiceBase<Conveyor.Model.ErpBinDestination>(Constants.EntityTypeNames.ErpBinDestination);

    this.controller = new ValidationController(
      Container.instance.get(Validator),
      Container.instance.get(PropertyAccessorParser),
      Container.instance.get(GlobalValidationConfiguration),
    );

    UIInternal.subscribe(UIInternal.EVT_CHANGE_LANGUAGE, () => {
      signaler.signal('EVT_CHANGE_LANGUAGE');
    })
  }

  public async activate(params: { param1: number, callback?: any, workOrderTypeId?: number, subTypeId?: number }) {
    this.actionsLoaded = false;
    console.debug("WorkOrderDetail - activate - actionsLoaded ", this.actionsLoaded);
    const id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ includeInAutoLaunch: true });

      if (params.workOrderTypeId) {
        this.entity.workOrderType = await this.workOrderTypeService.getEntityById(params.workOrderTypeId);
        if (params.subTypeId) {
          this.entity.orderSubtype = await this.orderSubtypeService.getEntityById(params.subTypeId);
        } else if (this.isOfTypeInput) {
          this.entity.orderSubtype = await this.orderSubtypeService.getDefault();
        }
        // else if or change to switch if we add other subtypes later
        this.typesNotInitialized = false;
      }

      if (params.callback != null) params.callback(this.entity);
      this.entity.workOrderStatusId = (await this.workOrderStatusService.getDefault())?.id;

      let workorderPriorityDefault = (await this.workordePriorityService.getEntities(
        new Predicate('default', FilterQueryOp.Equals, true).and(new Predicate('active', FilterQueryOp.Equals, true))
      ));
      if (workorderPriorityDefault.length >= 1) {
        this.entity.workOrderPriorityId = workorderPriorityDefault[0].id;
      }

      if (this.authService.currentUser?.userData?.defaultSessionSiteManagementId != null) {
        this.entity.limitedToStorageGroup = await this.limitedToStorageGroupService.getEntityById(this.authService.currentUser.userData.defaultSessionSiteManagementId);
      }
      await this.createEntityWithBorrowing();
      await this.createEntityWithFreePicking();
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.service.manager.clear(); // Reset cache
      this.entity = await this.service.getEntityById(id, 'limitedToStorageGroup', 'workOrderStatus', 'workOrderType', 'orderSubtype', 'inventoryTarget', 'workOrderPriority', 'project', 'costCenter', 'cycleCountParameter.storage', 'conveyor.erpBinDestinations');
      this.controller.addObject(this.entity);

      this.articleService.gridDataSource.queryParameters = { workOrderId: this.entity.id };
    }

    if (this.conveyorModuleIsActive && !this.entity.isFromERP && !this.entity.conveyor && (this.isCreationMode || this.isInConstruction)) {
      let conveyorInfoService = new ServiceBase<Conveyor.Model.ConveyorInfo>(Constants.EntityTypeNames.ConveyorInfo);
      this.entity.conveyor = await conveyorInfoService.createEntity();
    }

    const applicationParameter = await this.applicationParameterService.firstEntity();
    this.useProject = applicationParameter.useProject;
    this.isProjectMandatory = applicationParameter.isProjectMandatory;
    this.useCostCenter = applicationParameter.useCostCenter;
    this.isCostCenterMandatory = applicationParameter.isCostCenterMandatory;

    ValidationRules
      .ensure('workOrderTypeId').required()
      .ensure('workOrderPriorityId').required()
      .ensure('orderSubtypeId').required()
      .when((workOrder: Zeus.Web.Model.WorkOrder) => workOrder.workOrderTypeId == Constants.WorkOrderType.Input)
      .ensure('inventoryTargetId').required()
      .when((workOrder: Zeus.Web.Model.WorkOrder) => workOrder.workOrderTypeId == Constants.WorkOrderType.Inventory)
      .ensure('articleToActions').satisfies((articleToActions: Zeus.Web.Model.ArticleToAction[]) => {
        return articleToActions.length > 0;
      }).when(() => !this.isCreationMode && this.isOfTypeInput).withMessage(this.i18n.tr('workorder.articleToInputPicksMandatory'))
      .ensure('articleToActions').satisfies((articleToActions: Zeus.Web.Model.ArticleToAction[]) => {
        return !articleToActions.some(articleToInput => articleToInput.articleId == null || articleToInput.requestedActionQuantity == null);
      }).when(() => !this.isCreationMode && this.isOfTypeInput).withMessage(this.i18n.tr('workorder.articleToInputPicksInformationsError'))
      .ensure('projectId').required()
      .when(() => this.isProjectMandatory && this.canDisplayCostCenterAndProject).withMessage(this.i18n.tr('workorder.projectMandatory'))
      .ensure('costCenterId').required()
      .when(() => this.isCostCenterMandatory && this.canDisplayCostCenterAndProject).withMessage(this.i18n.tr('workorder.costCenterMandatory'))
      .ensure('workOrderName').satisfies(async (value: string, entity: Zeus.Web.Model.WorkOrder) => {
        if (value != null) {
          entity.workOrderName = value.trim();
          if (entity.workOrderName != "") {
            let workOrdersCount = await this.service.getCount(null, { checkWorkOrderName: true, workOrderName: entity.workOrderName, workOrderId: entity.id, workOrderTypeId: entity.workOrderTypeId, limitedToStorageGroupId: entity.limitedToStorageGroupId });
            if (workOrdersCount > 0) {
              return false;
            }
          }
        }
        return true;
      }).withMessage(this.i18n.tr('workorder.errorWorkorderInProcessExistsWithSameName'))
      .on(this.entity);
  }

  private onActionsLoaded() {
    this.actionsLoaded = true;
    console.debug("WorkOrderDetail - onActionsLoaded - actionsLoaded ", this.actionsLoaded);
  }

  public async attached() {
    // Business Rule :: Because locationsForInputs must not be validated at this time, we must to delete them when we leave this page.
    this.lockLocations = false;
    this.disposables.push(
      Container.instance.get(UnloadHelper).subscribe(() => this.deleteLocationForInputPickings()),
      this.bindingEngine.propertyObserver(this.entity, "workOrderTypeId").subscribe(async (newVal, oldVal) => {
        if (newVal != Constants.WorkOrderType.Input) {
          this.entity.orderSubtypeId = null;
        }
        else {
          this.entity.orderSubtypeId = (await this.orderSubtypeService.getDefault())?.id;
        }
        if (newVal != Constants.WorkOrderType.Inventory) {
          this.entity.inventoryTargetId = null;
        }
      }),
      this.bindingEngine.propertyObserver(this.entity, "inventoryTargetId").subscribe(async (newVal, oldVal) => {
        if (newVal != oldVal && (oldVal == Constants.InventoryTarget.Article || oldVal == Constants.InventoryTarget.ArticleLocations)) {
          await this.inventoryWOArticleService.deleteEntities(this.entity.inventoryLinks as any, true, null, true);
          await this.save();
        }
      }),
    );
  }

  async detached() {
    // Business Rule :: Because locationsForInputs must not be validated at this time, we must to delete them when we leave this page.
    if (!this.lockLocations) {
      await this.deleteLocationForInputPickings();
    }
    super.detached();
  }

  //#region isOfType
  @computedFrom('entity.workOrderTypeId')
  public get isOfTypeInput(): boolean {
    return this.entity.workOrderTypeId == Constants.WorkOrderType.Input;
  }

  @computedFrom('entity.workOrderTypeId')
  public get isOfTypePicking(): boolean {
    return this.entity.workOrderTypeId == Constants.WorkOrderType.Picking;
  }

  @computedFrom('entity.workOrderTypeId')
  public get isOfTypeInventory(): boolean {
    return this.entity.workOrderTypeId == Constants.WorkOrderType.Inventory;
  }

  @computedFrom('entity.orderSubtypeId')
  public get isOfTypeInputReturn(): boolean {
    return this.entity.orderSubtypeId == Constants.OrderSubtype.PickingReturn;
  }

  @computedFrom('entity.workOrderTypeId')
  public get isOfTypeReplenishment(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.MobileScreens) && this.entity.workOrderTypeId == MSConstants.WorkOrderType.Replenishment;
  }
  //#endregion

  //#region InventoryTarget
  @computedFrom('isOfTypeInventory', 'entity.inventoryTargetId')
  public get isInventoryTargetArticleLocation(): boolean {
    return this.isOfTypeInventory && this.entity.inventoryTargetId == Constants.InventoryTarget.ArticleLocations;
  }

  @computedFrom('isOfTypeInventory', 'entity.inventoryTargetId')
  public get isInventoryTargetArticle(): boolean {
    return this.isOfTypeInventory && this.entity.inventoryTargetId == Constants.InventoryTarget.Article;
  }

  @computedFrom('isOfTypeInventory', 'entity.inventoryTargetId')
  public get isInventoryTargetStorage(): boolean {
    return this.isOfTypeInventory && this.entity.inventoryTargetId == Constants.InventoryTarget.Storage;
  }

  @computedFrom('isOfTypeInventory', 'entity.inventoryTargetId')
  public get isInventoryTargetTrayContainer(): boolean {
    return this.isOfTypeInventory && this.entity.inventoryTargetId == Constants.InventoryTarget.TrayContainer;
  }

  @computedFrom('isOfTypeInventory', 'entity.inventoryTargetId')
  public get isInventoryTargetCycleCount(): boolean {
    return this.isOfTypeInventory && this.entity.inventoryTargetId == Constants.InventoryTarget.CycleCount;
  }

  @computedFrom('entity.inventoryTargetId')
  public get getTargetType(): string {
    switch (this.entity.inventoryTargetId) {
      case Constants.InventoryTarget.Article: return "article";
      case Constants.InventoryTarget.Storage: return "storage";
      case Constants.InventoryTarget.TrayContainer: return "trayContainer";
    }
    return null;
  }
  @computedFrom('entity.inventoryTargetId')
  public get getTargetIcon(): string {
    switch (this.entity.inventoryTargetId) {
      case Constants.InventoryTarget.Article: return "ze-inputed-article";
      case Constants.InventoryTarget.Storage: return "ze-article-group-storage";
      case Constants.InventoryTarget.TrayContainer: return "ze-box-open";
    }
    return null;
  }
  //#endregion

  @computedFrom('isOfTypePicking', 'isOfTypeInput', 'isOfTypeBorrowing', 'isOfTypeInputReturn')
  public get canDisplayCostCenterAndProject(): boolean {
    return (this.isOfTypePicking && !this.isOfTypeBorrowing) || (this.isOfTypeInput && this.isOfTypeInputReturn);
  }

  private async deleteLocationForInputPickings(isUnloading: boolean = false): Promise<void> {
    if (this.entity.workOrderStatusId === Constants.WorkOrderStatus.UnderProcess
      || this.entity.workOrderStatusId === Constants.WorkOrderStatus.Finalised) {
      return;
    }
    await this.apiService.unloadWorkOrders([this.entity.id]);
    this.apiService.resetArticleToActionsLocalPropertiesHelper(this.entity.articleToActions);
  }

  private isNavigatingBack = false;
  public async navigateBack() {
    this.isNavigatingBack = true;
    await super.navigateBack();
    this.isNavigatingBack = false;
  }

  protected async afterSave() {
    if (this.isNavigatingBack) {
      // If this is the save after a navige-back, ignore this navigation
      return;
    }
    // Reload workorder so that linked elements (inventory, articleToAction, ... are reloaded)
    this.router.navigate('/work-orders/' + this.entity.id, { replace: true, trigger: false });
    await this.activate({ param1: this.entity.id });
  }

  //#region State
  @computedFrom('entity.workOrderStatusId')
  public get isInConstruction(): boolean {
    return this.entity.workOrderStatusId == Constants.WorkOrderStatus.InConstruction;
  }

  @computedFrom('entity.workOrderStatusId')
  public get isReadyToBeProcessed(): boolean {
    return this.entity.workOrderStatusId == Constants.WorkOrderStatus.ReadyToBeProcessed;
  }

  @computedFrom('entity.workOrderStatusId')
  public get isPartiallyProcessed(): boolean {
    return this.entity.workOrderStatusId == Constants.WorkOrderStatus.PartiallyProcessed;
  }

  @computedFrom('entity.workOrderStatusId')
  public get isUnderProcess(): boolean {
    return this.entity.workOrderStatusId == Constants.WorkOrderStatus.UnderProcess;
  }

  public async changeState(workOrderStatus: number) {
    this.isEditingOrder = true;
    switch (this.entity.workOrderTypeId) {
      case Constants.WorkOrderType.Input:
      case Constants.WorkOrderType.Picking:
        if (this.isOfTypeTrayPick == false && this.entity.articleToActions.length == 0) {
          this.logError(this.i18n.tr('workorder.articleToInputPicksMandatory'), null, true);
          return;
        }
        if (this.isOfTypeTrayPick && this.entity.trayPicks.length == 0) {
          this.logError(this.i18n.tr('workorder.trayPicksMandatory'), null, true);
          return;
        }
        break;
      case Constants.WorkOrderType.Inventory:
        if (this.isInventoryTargetCycleCount == false && this.entity.inventoryLinks.length == 0) {
          this.logError(this.i18n.tr('workorder.inventoryArticlesMandatory'), null, true);
          return;
        }
        break;
    }
    this.entity.workOrderStatus = await this.workOrderStatusService.getEntityById(workOrderStatus);
    this.actionsLoaded = false;
    console.debug("WorkOrderDetail - changeState - actionsLoaded ", this.actionsLoaded);
    await this.save(true);
    this.isEditingOrder = false;
  }

  public async goToOrderToProcess(): Promise<void> {
    this.lockLocations = true; // Don't delete locations for inputs
    this.router.navigate('work-orders-to-process/all' + (
      (this.isGoToOrderVisible)
        ? '/' + this.entity.id
        : ""));
  }

  private getLocationForInputPickingFromListInProgress: Map<number, boolean> = new Map();
  public async launchMessage() {
    if (this.isBusy) return;

    if (this.entity.project != null && !this.entity.project.active) {
      this.logError(this.i18n.tr('workordertoprocess.inactiveProject'), null, true);
      return;
    }

    if (this.entity.costCenter != null && !this.entity.costCenter.active) {
      this.logError(this.i18n.tr('workordertoprocess.inactiveCostCenter'), null, true);
      return;
    }

    let actionToDo: Constants.ActionInvWOError = Constants.ActionInvWOError.launch;
    if (this.isInventoryTargetArticleLocation) {
      let result = await WorkOrderUtils.verifyLocationsForInventory(this.entity.inventoryLinks as Zeus.Web.Model.InventoryWorkOrderArticle[]);
      if (result?.filter(x => x.HasError).length > 0) {
        actionToDo = await this.continueLaunchWithError();
      }
    }
    if (actionToDo == Constants.ActionInvWOError.launch) {
      this.globalLoaderService.show();
      this.isLaunchingMessage = true;

      await WorkOrderUtils.launchWorkOrder(this.entity,
        null,
        async () => {
          this.isLaunchingMessage = false;
          this.globalLoaderService.hide();
          this.router.navigate('work-orders/all');
        });
      this.isLaunchingMessage = false;
      this.globalLoaderService.hide();
    } else if (actionToDo == Constants.ActionInvWOError.edit) {
      return;
    } else if (actionToDo == Constants.ActionInvWOError.cancel) {
      this.changeState(Constants.WorkOrderStatus.Cancelled);
    }
  }

  private async cancelOpenQty() {
    let isCancelled = await WorkOrderUtils.cancelArticleToAction(this.articleToActionService, this.entity.articleToActions);
    if (isCancelled) {
      this.entity.workOrderStatusId = Constants.WorkOrderStatus.FinalisedIncomplete;
      this.service.save();
    }
  }

  @computedFrom('entity.articleToActions.length', 'entity.inventoryLinks.length', 'isInventoryTargetCycleCount', 'entity.trayPicks.length')
  public get isNotEmpty(): boolean {
    return this.entity.articleToActions?.length > 0 || this.entity.inventoryLinks?.length > 0 || this.isInventoryTargetCycleCount || this.entity.trayPicks?.length > 0;
  }

  @computedFrom('entity.workOrderStatusId', 'entity.workOrderTypeId', 'isNotEmpty')
  public get isValidateOrderVisible(): boolean {
    return this.isInConstruction
      && this.entity.workOrderTypeId != null
      && this.isNotEmpty;
  }

  @computedFrom('entity.workOrderStatusId', 'entity.id', 'isNotEmpty',
    'getResourceForType', 'isOfTypeBorrowing', 'isOfTypeBorrowingReturn')
  public get isCancelVisible(): boolean {
    return (this.isInConstruction ||
      this.isReadyToBeProcessed)
      && this.entity.id > 0
      && this.isNotEmpty
      && this.authService.checkPermissonAccess({ resource: this.getResourceForType, action: Actions.Delete })
      && this.hasBorrowingAccess(Actions.Delete);
  }

  @computedFrom('entity.workOrderStatusId', 'entity.id', 'entity.articleToActions.length', 'entity.inventoryLinks.length',
    'getResourceForType', 'isOfTypeBorrowing', 'isOfTypeBorrowingReturn', 'isInventoryTargetCycleCount')
  public get isDeleteVisible(): boolean {
    return this.isInConstruction
      && this.entity.id > 0 && false == (this.entity.articleToActions?.length > 0 || (this.entity.inventoryLinks?.length > 0 && this.isInventoryTargetCycleCount))
      && this.authService.checkPermissonAccess({ resource: this.getResourceForType, action: Actions.Delete })
      && this.hasBorrowingAccess(Actions.Delete);
  }

  @computedFrom('entity.workOrderStatusId')
  public get isUnvalidateOrderVisible(): boolean {
    return this.isReadyToBeProcessed;
  }

  @computedFrom('entity.workOrderStatusId', 'getResourceForType', 'isOfTypeBorrowing', 'isOfTypeBorrowingReturn')
  public get isLaunchVisible(): boolean {
    return this.authService.checkAccess(this.getResourceForType, Constants.Permissions.LaunchBatchProcess)
      && this.hasBorrowingAccess(Actions.Read)
      && (
        this.isReadyToBeProcessed
        || this.isPartiallyProcessed
        || (this.isUnderProcess && this.entity.hasOpenQuantity)
      );
  }

  @computedFrom('entity.workOrderStatusId', 'getResourceForType', 'isOfTypeBorrowing', 'isOfTypeBorrowingReturn')
  public get isGoToOrderVisible(): boolean {
    return (this.isReadyToBeProcessed || this.isPartiallyProcessed)
      && this.authService.checkAccess(this.getResourceForType, Constants.Permissions.LaunchBatchProcess)
      && this.hasBorrowingAccess(Actions.Read); //Should be launch?
  }

  @computedFrom('entity.workOrderStatusId', 'entity.articleToActions.length')
  public get isCancelOpenQtyVisible(): boolean {
    return this.isPartiallyProcessed && this.entity.articleToActions.some(ata => !ata.hasBeenCancelled);
  }

  @computedFrom('entity.workOrderStatusId')
  public get isUnCancelVisible(): boolean {
    return this.entity.workOrderStatusId == Constants.WorkOrderStatus.Cancelled;
  }

  @computedFrom('workOrderTypeId', 'entity.workOrderStatusId', 'canUpdate')
  public get isModifiable(): boolean {
    if (this.entity.workOrderTypeId == null || !this.isInConstruction) {
      return false;
    }
    return this.canUpdate;
  }

  @computedFrom('isCreationMode', 'getResourceForType', 'isOfTypeBorrowing', 'isOfTypeBorrowingReturn')
  public get canUpdate(): boolean {
    let operation = this.isCreationMode ? Actions.Create : Actions.Update;
    return this.authService.checkAccess(this.getResourceForType, operation) && this.hasBorrowingAccess(operation);
  }

  @computedFrom('entity.articleToActions.length', 'entity.inventoryLinks.length')
  public get woLinesAdded(): boolean {
    return (this.entity.articleToActions != null && this.entity.articleToActions.length > 0) ||
      (this.entity.inventoryLinks != null && this.entity.inventoryLinks.length > 0);
  }

  @computedFrom('entity.workOrderTypeId')
  public get getResourceForType(): string {
    return WorkOrderUtils.getResourceForType(this.entity);
  }
  //#endregion

  public openActionHistory(): void {
    this.isNavigating = true;
    let filter = new ListViewModelActionHistoryFilter('workOrder', [this.entity.id]);
    this.navigateTo('action-history/all/' + filter.encode());
    this.isNavigating = false;
  }

  private async continueLaunchWithError(): Promise<Constants.ActionInvWOError> {
    let result = await (await this.box.showQuestion(
      this.i18n.tr("workorder.errorArticleLocationsQuestion"),
      this.i18n.tr("workorder.errorArticleLocationsTitle"),
      [
        {
          label: "workordertoprocess.errorInvArticleLocLine.cancel",
          theme: "danger",
          type: "tool",
          fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(Constants.ActionInvWOError.cancel),
        },
        {
          label: "workordertoprocess.errorInvArticleLocLine.edit",
          theme: "primary",
          type: "tool",
          fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(Constants.ActionInvWOError.edit),
        },
        {
          label: "workordertoprocess.errorInvArticleLocLine.launch",
          theme: "primary",
          type: "solid",
          fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(Constants.ActionInvWOError.launch),
        },
      ]
    )).closeResult;
    if (!result.wasCancelled) {
      return result.output;
    }
    return null;
  }

  private async setOrderNameBySequenceNumber(url: string) {
    let result = await this.httpClient.post(url);
    if (result.ok) {
      this.entity.workOrderName = (this.isOfTypeTrayPick ? "TrayPick_" : "") + this.authService.currentUser.id + "_" + (await result.text()).padStart(6, '0');
    }
  }

  //#region Borrowing
  @computedFrom('entity.orderSubtypeId')
  public get isOfTypeBorrowing(): boolean {
    return this.entity.orderSubtypeId == BConstants.OrderSubtype.Borrowing;
  }
  @computedFrom('entity.orderSubtypeId')
  public get isOfTypeBorrowingReturn(): boolean {
    return this.entity.orderSubtypeId == BConstants.OrderSubtype.BorrowingReturn;
  }

  private async createEntityWithBorrowing() {
    if (this.appModuleService.isActive(AppModuleEnum.Borrowing) && (this.isOfTypeBorrowing || this.isOfTypeBorrowingReturn)) {
      if (this.isOfTypeBorrowing) {
        await this.setOrderNameBySequenceNumber(BConstants.Application.UpdateBorrowingNumber);
      } else if (this.isOfTypeBorrowingReturn) {
        await this.setOrderNameBySequenceNumber(BConstants.Application.UpdateBorrowingReturnNumber);
      }
    }
  }

  private hasBorrowingAccess(operation: string) {
    if (this.appModuleService.isActive(AppModuleEnum.Borrowing) && (this.isOfTypeBorrowing || this.isOfTypeBorrowingReturn)) {
      return this.authService.checkAccess(BConstants.Permissions.WorkOrderBorrowing_AsResource, operation);
    }
    return true;
  }
  //#endregion

  //#region Tray pick
  @computedFrom('entity.orderSubtypeId')
  public get isOfTypeTrayPick(): boolean {
    return this.entity.orderSubtypeId == FPConstants.OrderSubtype.TrayPick;
  }

  private async createEntityWithFreePicking() {
    if (this.appModuleService.isActive(AppModuleEnum.FreePicking) && this.isOfTypeTrayPick) {
      await this.setOrderNameBySequenceNumber(FPConstants.Application.UpdateTrayPickNumber);
    }
  }
  //#endregion

  //#region Conveyor
  public get hasBinDestinations(): boolean {
    return (this.entity.conveyor?.erpBinDestinations?.length ?? -1) > 0;
  }
  public get conveyorModuleIsActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.Conveyor);
  }
  //#endregion

  //#region AutoLaunch
  public get autoLaunchModuleIsActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.AutoLaunch);
  }
  //#endregion
}
