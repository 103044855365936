import { Router } from 'aurelia-router';
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, ServiceBase, Various } from 'digiwall-lib';
import { Zeus } from "../generated";
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants'
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { ZeusUtilsMlFieldHelper } from 'utils/utils-ml-field-helper';

@autoinject
export class ArticleStorageGroupModal extends EntityDetailViewModelBase<Zeus.Web.Model.ArticleStorageGroup> {
  public ressourceName: string = Constants.EntityTypeNames.ArticleStorageGroup;

  public selectedLanguageCode = 'en';

  private storageGroupQueryParameters: any = { forModal: true };
  private articleStorageGroupService: ServiceBase<Zeus.Web.Model.ArticleStorageGroup>;
  private inputAlgorithmService: ServiceBase<Zeus.Web.Model.InputAlgorithm>;

  private articleRotationLevelTypeService: EnumerationTypeService;
  private storageLocationTypeService: EnumerationTypeService;
  private sortingLocationTypeService: EnumerationTypeService;
  public storageStrategyService: EnumerationTypeService;
  private storageGroupTypeEnumService: EnumerationTypeService;

  private storageGroup: Zeus.Web.Model.StorageGroup;
  private articleStorageGroup: Zeus.Web.Model.ArticleStorageGroup;

  private storageGroupType: DataFormat;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.ArticleStorageGroup>(Constants.EntityTypeNames.ArticleStorageGroup));

    this.storageGroupTypeEnumService = new EnumerationTypeService(Constants.EnumerationTypes.StorageGroupType);
    this.articleStorageGroupService = new ServiceBase<Zeus.Web.Model.ArticleStorageGroup>(Constants.EntityTypeNames.ArticleStorageGroup);
    this.articleRotationLevelTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ArticleRotationLevel);
    this.storageLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageLocationType);
    this.sortingLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.SortingLocationType);
    this.inputAlgorithmService = new ServiceBase<Zeus.Web.Model.InputAlgorithm>(Constants.EntityTypeNames.InputAlgorithm);
    this.storageStrategyService = new EnumerationTypeService(Constants.EnumerationTypes.StorageStrategy);
  }

  @computedFrom('editingMode', '_langWatcher')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("article.article");
    }
    if (this.editingMode === EditingModeEnum.Update) {
    }
  }

  public async activate(params) {
    await super.activate(params);
    const id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ articleId: null });
      this.entity.sortingLocation = await this.sortingLocationTypeService.getDefault();

      let defaultInputAlgo = await this.inputAlgorithmService.firstEntity(new Predicate('isDefault', FilterQueryOp.Equals, true));
      if (defaultInputAlgo != null) {
        this.entity.inputAlgorithmId = defaultInputAlgo.id;
      }

    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'storageStrategy', 'storageGroup.storageGroupType', 'storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroup',
        'articleRotationLevel', 'storageLocationType1', 'storageLocationType2', 'storageLocationType3', 'inputAlgorithm');
      this.controller.addObject(this.entity);
      this.storageGroupQueryParameters = { storageGroupTypeId: this.entity.storageGroup.storageGroupTypeId, forModal: true, articleId: this.entity.articleId }

      this.storageGroupType = {
        id: this.entity.storageGroup.storageGroupTypeId,
        text: (await this.storageGroupTypeEnumService.getEntityById(this.entity.storageGroup.storageGroupTypeId)).denomination._translation
      } as DataFormat;
    }
    if (params?.selectedLanguageCode == null) {
      this.selectedLanguageCode = this.i18n.getLocale();
    } else {
      this.selectedLanguageCode = params.selectedLanguageCode;
    }

    //TODO change when up dwf to 2.27.0
    if (params.callback != null) params.callback(this);
  }

  public async beforeSave() {
    let areTranslationsFilled = await ZeusUtilsMlFieldHelper.BeforeSave(this.entity, [
      { prop: this.entity.articleStorageConstraintCommentMultiline, label: "articlestoragegroup.articleStorageConstraintCommentMultiline", isRequired: false, isTextarea: true }
    ]);

    if (!areTranslationsFilled) {
      return false;
    }

    if (this.entity.storageGroup.storageGroupTypeId == null) {
      this.logError(this.i18n.tr('articlestoragegroup.errorGroupTypeMandatory'), null);
      return false;
    }
    if (this.entity.storageGroupId == null) {
      this.logError(this.i18n.tr('articlestoragegroup.errorParentStorageGroupRequired'), null);
      return false;
    }
    return true;
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, "storageGroupType").subscribe((newValue, oldValue) => {
        this.entity.storageGroupId = null;
        this.storageGroupQueryParameters = { storageGroupTypeId: this.storageGroupType, forModal: true, articleId: this.entity.articleId };
      })
    );
  }
}
