import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, ServiceBase } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { Zeus } from 'generated';
import { DropToLight } from '../app-modules/drop-to-light/generated';
import * as DTLConstants from '../app-modules/drop-to-light/constants';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { BindingEngine, computedFrom } from 'aurelia-binding';

@autoinject
export class DocumentPrintConfigurationDetail extends EntityDetailViewModelBase<Zeus.Web.Model.DocumentPrintConfiguration> {
  public ressourceName: string = Constants.EntityTypeNames.DocumentPrintConfiguration;
  public Constants = Constants;

  private workstationService: ServiceBase<DropToLight.Model.Workstation>;
  private configs: Zeus.Web.Model.DocumentPrintConfiguration[] = [];

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.DocumentPrintConfiguration>(Constants.EntityTypeNames.DocumentPrintConfiguration));
    this.workstationService = new ServiceBase<DropToLight.Model.Workstation>(DTLConstants.EntityTypeNames.Workstation);
    this.workstationService.gridDataSource.customSelect2Predicates = () => {
      let predicates = this.configs
        .filter(x => x.workstationId != null)
        .map(x => new Predicate("id", FilterQueryOp.NotEquals, x.workstationId));
      return Predicate.and(...predicates, new Predicate("active", FilterQueryOp.Equals, true));
    }
  }

  @computedFrom('_langWatcher')
  public get documentTitle() {
    return this.i18n.tr("documentprintconfiguration.documentprintconfiguration");
  }

  public async activate() {
    this.configs.push(... await this.service.getEntities(null, ['workstation', 'printer']));
    this.configs.forEach(x => this.addObservable(x));
  }

  private async addConfiguration() {
    const config = await this.service.createEntity();
    this.configs.push(config);
    this.addObservable(config);
  }

  private addObservable(configuration: Zeus.Web.Model.DocumentPrintConfiguration) {
    this.bindingEngine.propertyObserver(configuration, "printNewBin").subscribe((newValue) => {
      if (newValue) {
        configuration.printOnFullBin = true;
      }
    });
  }

  private async removeConfiguration(configuration: Zeus.Web.Model.DocumentPrintConfiguration) {
    await this.service.deleteEntities([configuration], true);
    this.configs.remove(configuration);
  }
}
