import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';

@autoinject
export class TrayTemplateList extends ListViewModelBase<Zeus.Web.Model.TrayTemplate> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.TrayTemplate>(Constants.EntityTypeNames.TrayTemplate));
  }

  public ressourceName: string = Constants.EntityTypeNames.TrayTemplate;

  public getDetailsUrl(self, entity: Zeus.Web.Model.TrayTemplate) {
    if (entity && entity.id) {
      let url = '/tray-templates/' + entity.id;
      return url;
    }
    return null;
  }

  public isSelectable(entity: Zeus.Web.Model.TrayTemplate) {
    return entity?.standardTemplate == false;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("traytemplate.name"),
        field: "name",
        type: FieldType.String,
        sort: 'asc'
      },
      /* {
        headerName: this.i18n.tr("traytemplate.mask"),
        field: "mask",
        type: FieldType.String
      }, */
      {
        headerName: this.i18n.tr("storage.trayWidthCm"),
        field: "trayWidthCm",
        type: FieldType.Number,
        cellStyle: { "text-align": "left" }
      },
      {
        headerName: this.i18n.tr("storage.trayDepthCm"),
        field: "trayDepthCm",
        type: FieldType.Number,
        cellStyle: { "text-align": "left" }
      },
      {
        headerName: this.i18n.tr("traytemplate.nbPositions"),
        field: "nbLocations",
        type: FieldType.Number,
        cellStyle: { "text-align": "left" }
      },
      {
        headerName: this.i18n.tr("traytemplate.standardTemplate"),
        field: "standardTemplate",
        type: FieldType.Boolean,
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
} 
