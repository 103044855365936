import { Container } from 'aurelia-dependency-injection';
import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { Actions, PermissionsConfig } from "digiwall-lib";
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";
import translationJson from './locales/index';
import { MenuType, Permissions, RoleNames, Roles, RouteName } from "../../constants";
import * as ReceptionConstants from "../../reception/constants";
import { ReceptionActivator } from "reception/reception-activator";
import * as MSConstants from "./constants";

export class MobileScreensRoot extends AppModuleRoot {
  public get name() {
    return AppModule.MobileScreens;
  }

  public get translations(): { [key: string]: object; } {
    return this.mergeTranslations([
      Container.instance.get(ReceptionActivator).translations,
      translationJson
    ]);
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return MSConstants.EnumerationTypes;
  }

  protected get pathName(): string {
    return 'mobile-screens';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [
      {
        after: RouteName.AppParam,
        route: 'static-location-parameters/all',
        moduleId: 'static-location-parameters/static-location-parameters',
        title: "mobileparameters.staticLocationParameters",
        nav: true,
        settings: { group: MenuType.ApplicationParameters, resource: Roles.Helpdesk, action: Actions.All }
      },
      {
        after: RouteName.Article,
        route: 'replenishment-needs/all',
        moduleId: 'replenishment-needs/replenishment-needs',
        title: "replenishment.replenishmentNeeds",
        nav: true,
        settings: { group: MenuType.Articles, resource: MSConstants.PermissionContexts.ReplenishmentNeeds, action: Actions.Read }
      }
    ];
  }

  protected get resourceNames(): {} {
    return MSConstants.EntityTypeNames.ResourceNames;
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {
      perContext: [
        { context: ReceptionConstants.Permissions.Reception, qualifiedName: [RoleNames.Mobile] },
        { context: ReceptionConstants.Permissions.StorageParameter, qualifiedName: [RoleNames.Mobile, 'permission.storageParameter'] },
        { context: MSConstants.PermissionContexts.ReplenishmentNeeds, qualifiedName: [MenuType.Articles, 'replenishment.replenishmentNeeds'] },
        { context: MSConstants.PermissionContexts.Replenish, qualifiedName: [RoleNames.Mobile, 'replenishment.replenish'] },
      ],
      permissionNames: {
        [ReceptionConstants.Permissions.Reception]: {
          [Permissions.Access]: ReceptionConstants.RoleNames.Reception
        }
      }
    };
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
    metadataStore.registerEntityTypeCtor(MSConstants.EntityTypeNames.InventoryWorkOrderStaticBays, function () {
      this.nbLocationSelected = 0;
    }, null);
    metadataStore.registerEntityTypeCtor(MSConstants.EntityTypeNames.InventoryWorkOrderStaticRows, function () {
      this.nbLocationSelected = 0;
    }, null);
  }
}
