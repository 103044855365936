import { bindable, customAttribute, noView } from 'aurelia-templating';
import { autoinject } from 'aurelia-framework';

const allowedKeyCodes = [
  'Backspace', 'Tab', 'Delete', 'ArrowLeft', 'ArrowRight'
]

@customAttribute('integer-input')
@noView
@autoinject
export class IntegerInputCustomAttribute {
  @bindable({ primaryProperty: true }) active: boolean | null | "" = true;

  constructor(private element: Element) { }

  public async attached() {
    if (this.active !== false) {
      // Only numbers from 0 to 9 are allowed (Characters such as . or - are avoided)
      // We must still allow Backspace, Tab, CtrlKey, ShiftKey, Delete, Left, Right ^^
      (this.element as HTMLElement).onkeydown = (event) =>
        event.ctrlKey || event.shiftKey || event.metaKey
        || (event.key >= "0" && event.key <= "9")
        || allowedKeyCodes.some(x => x == event.code)
        ;
    }
  }
}
