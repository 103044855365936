export default {
  debug: false,
  testing: false,
  name: 'stage',
  remoteApiUrl: 'https://zeus-staging.digiwall.be',
  auth: {
    clientId: 'TdNqPFzERFZuk533nQtdMPaHhXmW0sRV',
    domain: 'zeus-staging.eu.auth0.com',
    audience: 'http://localhost:4500',
    callback_uri: 'https://zeus-staging.digiwall.be/callback',
    logout_uri: 'https://zeus-staging.digiwall.be'
  }
};

export const version: String = "1.3.2-rc.3";